import React, { FC } from 'react'

import { SkeletonSize } from '@consts/enumTypes'

import {
  SkeletonDatetime,
  SkeletonIcon,
  SkeletonLabel,
  SkeletonUserAction,
  SkeletonUsername
} from '@common/skeletons/components'

const SkeletonHistoryItem: FC = () => (
  <div className='d-flex mb-4'>
    <SkeletonIcon classes='mr-2' />
    <div>
      <SkeletonUsername />
      <SkeletonUserAction />
      <SkeletonDatetime size={SkeletonSize.XS} />
    </div>
  </div>
)

export const SkeletonDocumentHistory: FC = () => (
  <div className='mt-4 mx-4'>
    <SkeletonLabel classes='mb-4' />
    <SkeletonHistoryItem />
    <SkeletonHistoryItem />
  </div>
)
