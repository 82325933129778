import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as UnknownStateIconSVG } from './unknown-state-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconStateUnknown: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <UnknownStateIconSVG />
  </Icon>
)
