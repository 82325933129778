import cn from 'classnames'
import React, { FC } from 'react'

import { ColorVar } from '@consts/common'

import { Button } from '@common/Button'
import { Content } from '@common/Content'
import { RippleEffect } from '@common/RippleEffect'
import { OrganizationLink } from '@containers/navigation'
import { ReactComponent as ErrorSVG } from '../error.svg'

import { TPage404Props as IProps } from './types'

import styles from '../Errors.module.css'

const ERROR_REASONS_404 = [
  'Элемент, который вы ищете, удален',
  'У вас нет доступа к данному разделу',
  'Вы перешли по несуществующей или неработающей ссылке'
]

// ToDo изображения по src не грузятся. заменить на svg и исправить размеры
export const Page404: FC<IProps> = ({ mainNavItemRoute, onResetError404 }) => (
  <Content classes='d-flex flex-column justify-content-between mx-6'>
    <div className='d-flex flex-column align-items-center m-4'>
      <span className={styles.error}>
        <ErrorSVG />
      </span>

      <p className={cn('fw-700 text-brand', styles.error_code)}>404</p>
      <p className='text-center font-md fw-600 mt-4'>{'Извините, но данная страница недоступна'}</p>

      <div className='text-muted font-sm mt-4'>
        <p className='fw-500'>{'Возможные причины:'}</p>
        <ul className='my-0 pl-4 mb-2'>
          {ERROR_REASONS_404.map((reason: string, index: number) => (
            <li key={index}>{reason}</li>
          ))}
        </ul>
        <p>
          {'Если вы считаете, что должны иметь доступ к этой странице, обратитесь к администратору Вашей организации'}
        </p>
      </div>
    </div>
    <OrganizationLink to={mainNavItemRoute} className='mb-4'>
      <Button theme='brand' classes='relative width-full fw-500' onClick={onResetError404}>
        {'На главную'}
        <RippleEffect color={ColorVar.LIGHT} />
      </Button>
    </OrganizationLink>
  </Content>
)

export default Page404
