import { AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import { needResetParams } from '@consts/common'
import urls from '@consts/urls'

import { IUserCertificate } from '@store/modules/user/types'

class DscService extends AbstractHttpService {
  async getUserCertificate (userOguid: string): Promise<AxiosResponse<IUserCertificate>> {
    const url = urls.dsc.getCertificate.replace('{{ oguid }}', userOguid)

    const response: AxiosResponse<IUserCertificate> = await this._http.get(url)

    return response
  }

  async signFileHash (
    userOguid: string,
    docuForceCertificateOguid: string,
    hash: string
  ): Promise<AxiosResponse<string>> {
    const url = urls.dsc.signFileHash
      .replace('{{ oguid }}', userOguid)
      .replace('{{ certificateOguid }}', docuForceCertificateOguid)

    const body = {
      hash
    }

    const response: AxiosResponse<string> = await this._http.post(url, body, { params: { ...needResetParams } })

    return response
  }
}

export default new DscService()
