import { AxiosResponse } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IAttachSignature, IFileHash } from './types'

class SigningService extends AbstractHttpService {
  async attachSignature (documentOguid: string, data: IAttachSignature): Promise<AxiosResponse<any>> {
    const url = urls.signing.attach.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<any> = await this._http.post(url, data)

    return response
  }

  async getHashFile (data: IFileHash): Promise<AxiosResponse<any>> {
    const url = urls.signing.hash

    const response: AxiosResponse<any> = await this._http.post(url, data)

    return response
  }
}

export default new SigningService()
