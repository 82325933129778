import React, { FC } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Timeout } from '@consts/common'

import { getOrganizationInfo, createPath } from '@utils/utils'

import { IOrganizationLinkProps as IProps } from './types'
import { DocumentsDirections } from '@consts/translations'
import { useTranslation } from 'react-i18next'

const OrganizationLink: FC<IProps> = ({
  alias,
  documentData,
  isAuthenticated,
  oguid,
  onSetActiveNavItem,
  onSetDocumentId,
  timeout = Timeout.RIPPLE_EFFECT_DEFAULT,
  to,
  withoutRipple = false,
  ...rest
}) => {
  if (!isAuthenticated) return <Link to={to} {...rest} />

  const { t } = useTranslation()
  const history = useHistory()

  const organization = getOrganizationInfo(alias, oguid)
  const newTo = createPath(organization, to)

  const handleClick = (): void => {
    if (documentData) {
      const title = documentData.direction
        ? `${t(DocumentsDirections[documentData.direction])} ${t('document:document.lowercase')}`
        : t('document:document.capitalize')

      onSetActiveNavItem({
        route: String(to),
        title
      })

      onSetDocumentId(documentData.documentId)
    }

    setTimeout(() => history.push(newTo), timeout)
  }

  return withoutRipple ? <Link to={newTo} {...rest} /> : <a onClick={handleClick} {...rest} />
}

export default OrganizationLink
