import cn from 'classnames'
import React, { FC } from 'react'

import { IIconProps as IProps } from './types'

import './Icon.scss'

export const Icon: FC<IProps> = ({
  children,
  color = 'muted-500',
  classes,
  onClick,
  size = 'base',
  style,
  type = 'regular'
}) => {
  const cssColor = `var(--${color})`
  const colorStyle = {
    borderColor: cssColor,
    fill: type === 'regular' ? cssColor : undefined
  }

  const styleClass = style ? `icon--${style}` : ''
  const typeStyle = type === 'solid' && {
    backgroundColor: cssColor,
    fill: 'var(--light)'
  }

  const iconClasses = cn('d-block icon', `icon--${size}`, styleClass, classes)

  return (
    <span className={iconClasses} onClick={onClick} style={{ ...colorStyle, ...typeStyle }}>
      {children}
    </span>
  )
}
