import React, { FC } from 'react'

import Authentication from './components/Authentication'

import { IRouteMainParams as IProps } from '@containers/routing/RouteWithSubRoutes/types'

const PrivateRoute: FC<IProps> = ({ component, path, ...rest }) => (
  <Authentication {...rest} path={path} component={component} />
)

export default PrivateRoute
