import { AxiosResponse, AxiosRequestConfig } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IApproveContent, ITask } from '@store/modules/tasks/types'

class TasksService extends AbstractHttpService {
  async getTasks (config: AxiosRequestConfig): Promise<AxiosResponse<ITask[]>> {
    const url = urls.tasks.list

    const response: AxiosResponse<ITask[]> = await this._http.get(url, config)

    return response
  }

  async completeTask (taskOguid: string, data: IApproveContent): Promise<AxiosResponse<any>> {
    const url = urls.tasks.complete.replace('{{ taskOguid }}', taskOguid)

    const response: AxiosResponse<any> = await this._http.post(url, data)

    return response
  }
}

export default new TasksService()
