import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'

import { isNotMobileDevice } from '@consts/common'

import { openPushNotification } from '@utils/pushNotifications'
import { modifyRoutes } from '@utils/utils'

import { Layout } from '@common/Layout'
import { SkeletonLoader } from '@common/loaders'
import { RenderRoutes } from '@containers/routing'

import { TLayoutWrapperProps as IProps } from './types'

// ToDo чтобы переделать с connect на useSelector и useDispatch необходимо решить проблему между анимациями и хуками
const LayoutWrapper: FC<IProps> = ({
  isExchangeParticipant,
  isFlowFunctionalityEnabled,
  isLoading,
  isReadonly,
  role
}) => {
  const user = {
    isExchangeParticipant,
    isFlowFunctionalityEnabled,
    isReadonly,
    role
  }

  if (!isNotMobileDevice) openPushNotification(useHistory())

  return (
    <Layout>
      <RenderRoutes routes={modifyRoutes(user)} />
      <SkeletonLoader loading={isLoading} />
    </Layout>
  )
}

export default LayoutWrapper
