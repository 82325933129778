import cn from 'classnames'
import React, { FC } from 'react'

import { isPlatformIOS } from '@utils/device'

import { ILayoutProps as IProps } from './types'

import './Layout.scss'

const getClasses = (classes?: string): string =>
  cn('fixed d-flex flex-column width-full layout', isPlatformIOS() ? 'layout--ios' : 'layout--all', classes)

export const Layout: FC<IProps> = ({ children, classes }) => <div className={getClasses(classes)}>{children}</div>
