import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as ReplyIconSVG } from './reply-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconReply: FC<IProps> = ({ color = 'brand-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ReplyIconSVG />
  </Icon>
)
