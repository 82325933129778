import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import {
  AccessMode,
  ApplyToExternal,
  FieldType,
  FlowTaskType,
  FormProperty,
  HistoryStateCodeGroupType
} from '@consts/enumTypes'

import { Nullable, TDataType } from '@types'
import { IApplicationState } from '@store/types/common'

// Справочник рабочих статусов
export type TSeverity = 'ERROR' | 'INFO' | 'SUCCESS' | 'UNKNOWN' | 'WARNING'

export interface IWorkflowStatus {
  severity: TSeverity
  caption: string
}

export interface IWorkflowStatuses {
  [key: string]: IWorkflowStatus
}

// Справочник типов документов
export interface IFormPropertiesObject {
  visible: boolean
  order: number
  width: number
}

export interface IFormProperties {
  [FormProperty.CARD]: IFormPropertiesObject
  [FormProperty.LIST]: IFormPropertiesObject
}

export interface ILocalization {
  [key: string]: string
}

export interface IComponent {
  componentName: Nullable<string>
  labels: ILocalization
  hints: Nullable<ILocalization>
  placeholders: Nullable<ILocalization>
  regexp: Nullable<string>
}

export interface IFieldComponent {
  componentName: Nullable<string>
  label: string
  hint: Nullable<string>
  placeholder: Nullable<string>
  regexp: Nullable<string>
}

export interface IDocumentTypeField {
  dictType: Nullable<string>
  isFixedFormProperties: boolean
  isRequired: boolean
  isSystem: boolean
  type: TDataType
  formProperties: Nullable<IFormProperties>
  component: IFieldComponent
}

export interface IDocumentTypeFields {
  [key: string]: IDocumentTypeField
}

export interface IDocumentType {
  title: string
  hint: string
  comment: string
  isFormal: boolean
  isRequireRecipientSignature: Nullable<boolean>
  isSystem: boolean
  fields: IDocumentTypeFields
}

export interface IDocumentTypeFromServer {
  comments: Nullable<ILocalization>
  fields: IField[]
  hints: Nullable<ILocalization>
  key: string
  titles: ILocalization
}

export interface IDocumentTypes {
  [key: string]: IDocumentType
}

export interface IHistoryStateCodes {
  [key: string]: IHistoryStateCode
}

export interface IHistoryStateCode {
  captionFemale: string
  captionMale: string
  group: HistoryStateCodeGroupType
  severity: TSeverity
}

// Справочник типов полей
export interface IField {
  accessMode: AccessMode
  applyToExternal: ApplyToExternal
  component: IComponent
  dictType: Nullable<string>
  formProperties: Nullable<IFormProperties>
  isOverriding?: boolean
  isRequired?: boolean
  isSystem?: boolean
  key: string
  type: FieldType
}

// Экшны
export enum MetadataActionTypes {
  RESET_METADATA = 'RESET_METADATA',
  SET_DOCUMENT_TYPES = 'SET_DOCUMENT_TYPES',
  SET_FLOW_STAGE_TYPES = 'SET_FLOW_STAGE_TYPES',
  SET_HISTORY_STATE_CODES = 'SET_HISTORY_STATE_CODES',
  SET_WORKFLOW_STATUSES = 'SET_WORKFLOW_STATUSES'
}

export interface IMetadataState {
  documentTypes: IDocumentTypes
  flowStageTypes: IFlowStageType[]
  historyStateCodes: IHistoryStateCodes
  workflowStatuses: IWorkflowStatuses
}

export interface IActionResetMetadata extends Action<string> {
  type: MetadataActionTypes.RESET_METADATA
}

export interface IActionSetDocumentTypes extends Action<string> {
  payload: IDocumentTypes
  type: MetadataActionTypes.SET_DOCUMENT_TYPES
}

export interface IActionSetFlowStageTypes extends Action<string> {
  payload: IFlowStageType[]
  type: MetadataActionTypes.SET_FLOW_STAGE_TYPES
}

export interface IActionSetHistoryStateCodes extends Action<string> {
  payload: IHistoryStateCodes
  type: MetadataActionTypes.SET_HISTORY_STATE_CODES
}

export interface IActionSetWorkflowStatuses extends Action<string> {
  payload: IWorkflowStatuses
  type: MetadataActionTypes.SET_WORKFLOW_STATUSES
}

export type TMetadataActions =
  | IActionResetMetadata
  | IActionSetDocumentTypes
  | IActionSetFlowStageTypes
  | IActionSetHistoryStateCodes
  | IActionSetWorkflowStatuses

export enum MetadataActionKey {
  SET_DOCUMENTS_TYPES = 'setDocumentTypes',
  SET_FLOW_STAGE_TYPES = 'setFlowStageTypes',
  SET_HISTORY_STATE_CODES = 'setHistoryStateCodes',
  SET_WORKFLOW_STATUSES = 'setWorkflowStatuses'
}

export type MetadataActions = {
  [key in MetadataActionKey]: ActionCreator<Action<string>>
}

export type MetadataThunkDispatch = ThunkDispatch<IMetadataState, void, Action<string>>

export type MetadataThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export interface IFlowStageType {
  buttonCaption: string
  declinedCaption: string
  name: string
  solvedCaption: string
  taskType: FlowTaskType
  type: string
}
