export enum FieldName {
  COMMENT = 'comment',
  CONTRACTOR = 'contractor',
  CURRENCY = 'currency',
  DIVISION_KPP = 'divisionKpp',
  DIVISION_NAME = 'divisionName',
  DIVISION_OGUID = 'divisionOguid',
  DIVISION_PARENT = 'divisionParent',
  DOCUMENT_DATE = 'documentDate',
  DOCUMENT_NUMBER = 'documentNumber',
  EXTERNAL_KEY = 'externalKey',
  FILENAME = 'filename',
  GROUNDS = 'grounds',
  HAS_VAT = 'hasVat',
  INITIATOR = 'initiatorOguid',
  IS_REQUIRE_RECIPIENT_SIGNATURE = 'isRequireRecipientSignature',
  IS_VALIDATED = 'isValidated',
  NAME = 'name',
  NAME_SHORT = 'nameShort',
  OGRN = 'ogrn',
  SUB_ORG = 'subOrgOguid',
  SUM_TOTAL = 'sumTotal',
  SUM_VAT = 'sumVat',
  TYPE = 'type',
  VAT = 'sumVat',
  WITHOUT_VAT = 'withoutVat'
}

export enum MessageField {
  ASSIGNED_TO_USER = 'assignedToUser',
  ASSIGNED_TO_USER_OGUID = 'assignedToUserOguid',
  DESCRIPTION = 'description'
}

export enum UserField {
  ACCESSIBLE_SUB_ORG = 'accessibleSubOrgOguids',
  EMAIL = 'email',
  EXISTING_USER = 'existingUser',
  EXTENDED_SIGNER = 'extendedSigner',
  FAKE_LOGIN = 'fakeLogin',
  IS_READONLY = 'isReadonly',
  LOCALE = 'locale',
  LOGIN = 'login',
  NAME = 'name',
  OGUID = 'oguid',
  ORGS = 'orgs',
  ROLE = 'role',
  PATRONYMIC = 'patronymic',
  PASSWORD = 'password',
  PASSWORD_CONFIRM = 'passwordConfirm',
  PASSWORD_NEW = 'passwordNew',
  PHONE = 'phone',
  POSITION = 'position',
  SCOPE = 'scope',
  SEND_INVITE = 'sendInvite',
  STATUS = 'status',
  SUB_ORG = 'subOrg',
  SUB_ORG_OGUID = 'subOrgOguid',
  SURNAME = 'surname',
  TIMEZONE = 'timeZone'
}

export enum OrgField {
  ADDR_TEXT = 'addrText',
  ALIAS = 'alias',
  CERTIFICATE_OF_REGISTRY_INFO = 'certificateOfRegistryInfo',
  FNS_PARTICIPANT_CODE = 'fnsParticipantCode',
  FOREIGN_ADDRESS = 'foreignAddress',
  FOREIGN_COUNTRY = 'foreignCountry',
  INN = 'inn',
  IS_APPLIED_TO_TAX_OFFICE = 'isAppliedToTaxOffice',
  IS_EXCHANGE_PARTICIPANT = 'isExchangeParticipant',
  IS_FLOW_FUNCTIONALITY_ENABLED = 'isFlowFunctionalityEnabled',
  IS_FNS_CODE = 'ifnsCode',
  IS_FOREIGN = 'isForeign',
  IS_ROAMING = 'isRoaming',
  KPP = 'kpp',
  LEGAL_TYPE = 'legalType',
  LIQUIDATION_DATE = 'liquidationDate',
  NAME = 'name',
  NAME_FIO = 'nameFio',
  NAME_SHORT = 'nameShort',
  OGRN = 'ogrn',
  OGUID = 'oguid',
  OPERATOR_CODE = 'operatorCode',
  SOCIABILITY = 'sociability'
}
