import { connect } from 'react-redux'

import OrganizationLink from './OrganizationLink'

import { navigationActions } from '@store/modules/navigation/actions'

import { IApplicationState } from '@store/types/common'
import { IActiveNavItem } from '@store/modules/navigation/types'
import {
  IOrganizationLinkPropsFromDispatch as IPropsFromDispatch,
  IOrganizationLinkPropsFromState as IPropsFromState
} from './types'
import { documentActions } from '@store/modules/document/actions'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  alias: state.user.activeOrg.alias,
  isAuthenticated: state.user.isAuthenticated,
  oguid: state.user.activeOrg.oguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onSetActiveNavItem: (navItem: IActiveNavItem) => dispatch(navigationActions.setActiveNavItem(navItem)),
  onSetDocumentId: (documentId: number) => dispatch(documentActions.setDocumentId(documentId))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationLink)
