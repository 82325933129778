import React, { FC } from 'react'

import { SkeletonType } from '@consts/enumTypes'

import { Content } from '@common/Content'
import {
  SkeletonButtonListItem,
  SkeletonDocumentField,
  SkeletonDocumentHistory,
  SkeletonDocumentTitle,
  SkeletonTabs
} from '@common/skeletons/components'

import '@common/skeletons/Skeletons.scss'

export const SkeletonDocumentCard: FC = () => (
  <Content>
    <SkeletonDocumentTitle />
    <SkeletonTabs type={SkeletonType.DOCUMENT} />
    <div className='mt-5 mx-4'>
      <SkeletonDocumentField />
      <SkeletonDocumentField />
      <SkeletonDocumentField />
      <SkeletonDocumentField />
    </div>
    <SkeletonButtonListItem isSingle />
    <SkeletonDocumentHistory />
  </Content>
)
