import React, { FC, ReactElement } from 'react'

import { SkeletonType } from '@consts/enumTypes'

import { SkeletonTab } from '@common/skeletons/components'

import { ISkeletonTabsProps as IProps } from './types'

import '@common/Tabs/Tabs.scss'

const getIsType = (type?: SkeletonType): boolean =>
  type ? [SkeletonType.DOCUMENT, SkeletonType.MESSAGES, SkeletonType.TASKS].includes(type) : false

const getTabs = (type?: SkeletonType): ReactElement =>
  type === SkeletonType.DOCUMENT ? (
    <>
      <SkeletonTab />
      <SkeletonTab />
      <SkeletonTab />
    </>
  ) : (
    <>
      <SkeletonTab />
      <SkeletonTab />
    </>
  )

export const SkeletonTabs: FC<IProps> = ({ type }) =>
  getIsType(type) ? <div className='tabs'>{getTabs(type)}</div> : null
