import cn from 'classnames'
import React, { FC, MouseEvent, useEffect, useState } from 'react'

import { ColorVar, Duration } from '@consts/common'

import { Nullable } from '@types'
import { IRippleEffectItem, IRippleEffectProps as IProps } from './types'

import './RippleEffect.scss'

export const RippleEffect: FC<IProps> = ({
  duration = Duration.RIPPLE_EFFECT_DEFAULT,
  color = ColorVar.BRAND500,
  classes
}: IProps) => {
  const [ripple, setRipple] = useState<Nullable<IRippleEffectItem>>(null)

  const rippleClasses = cn('ripple-effect absolute overflow-none', classes ?? 'atl-0 abr-0')

  useEffect(() => {
    let bounce: number | undefined

    if (ripple) {
      window.clearTimeout(bounce)

      bounce = window.setTimeout(() => {
        setRipple(null)
        window.clearTimeout(bounce)
      }, duration / 2)
    }

    return () => window.clearTimeout(bounce)
  }, [ripple, duration])

  const handleAddRippleEffect = (e: MouseEvent): void => {
    const { height, left, top, width } = e.currentTarget.getBoundingClientRect()

    const size = width > height ? width : height
    const x = e.pageX - left - size / 2
    const y = e.pageY - top - size / 2

    const newRipple = { x, y, size }

    setRipple(newRipple)
  }

  const stylesFromProps = {
    backgroundColor: color,
    animationDuration: `${duration}ms`
  }

  return (
    <span className={rippleClasses} onMouseDown={handleAddRippleEffect}>
      {ripple ? (
        <span
          className='ripple-effect__item absolute'
          style={{
            top: ripple.y,
            left: ripple.x,
            width: ripple.size,
            height: ripple.size,
            ...stylesFromProps
          }}
        />
      ) : null}
    </span>
  )
}
