import { Action } from 'redux'

export enum UtilsActionTypes {
  RESET_ERROR404 = 'RESET_ERROR404',
  RESET_ERROR500 = 'RESET_ERROR500',
  SET_ERROR404 = 'SET_ERROR404',
  SET_ERROR500 = 'SET_ERROR500',
  SET_EXISTING_USER = 'SET_EXISTING_USER',
  SET_MASSIVE_ACTION_PAGE = 'SET_MASSIVE_ACTION_PAGE'
}

interface IActionSetError404 extends Action<string> {
  type: UtilsActionTypes.SET_ERROR404
}

interface IActionSetError500 extends Action<string> {
  type: UtilsActionTypes.SET_ERROR500
}

export interface IActionResetError404 extends Action<string> {
  type: UtilsActionTypes.RESET_ERROR404
}

export interface IActionResetError500 extends Action<string> {
  type: UtilsActionTypes.RESET_ERROR500
}

export interface IActionSetExistingUser extends Action<string> {
  payload: string
  type: UtilsActionTypes.SET_EXISTING_USER
}

export type TUtilsActions =
  | IActionSetError404
  | IActionSetError500
  | IActionResetError404
  | IActionResetError500
  | IActionSetExistingUser

export interface IUtilsState {
  existingUserOguid: string
  isError404: boolean
  isError500: boolean
  areMessagesShown: boolean
}
