import cn from 'classnames'
import React, { ForwardedRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { ICommonLoaderProps as IProps } from './types'

import { Spinner } from '../Spinner'
import { ThemeType } from '@consts/enumTypes'

export const CommonLoader = forwardRef(({ classes, text }: IProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div className={cn('d-flex align-items-center mb-4', classes)} ref={ref}>
    <Spinner theme={ThemeType.BRAND} classes='mr-4' />
    <span className='text-brand'>{text ?? useTranslation().t('common:loading')}</span>
  </div>
))

CommonLoader.displayName = 'CommonLoader'
