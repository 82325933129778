import { NotificationType } from '@consts/enumTypes'

import StoreNotifications from '../components/StoreNotifications'

import { INotificationOptions, INotification } from '../types'

export const showBasicNotification = (options?: INotificationOptions): void => {
  const notification: INotification = {
    id: '',
    options: {
      content: options?.content ?? [
        { value: 'notifications:network.errors.notConnect.content.1' },
        { value: 'notifications:network.errors.notConnect.content.2' }
      ],
      title: {
        classes: options?.title?.classes ?? '',
        value: options?.title?.value ?? 'notifications:network.errors.notConnect.title'
      }
    },
    type: NotificationType.BASIC
  }

  StoreNotifications.add(notification)
}
