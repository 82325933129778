import React, { FC } from 'react'

import { Timeout } from '@consts/common'
import { Path } from '@consts/navItems'

import { Button } from '@common/Button'
import { IconMessages } from '@common/icons'
import { MessagesBadge } from '@common/MessagesBadge'
import { RippleEffect } from '@common/RippleEffect'
import { OrganizationLink } from '@containers/navigation'

import { TMessagesButtonProps as IProps } from './types'

const MessagesButton: FC<IProps> = ({ todoQuantity }) => (
  <OrganizationLink className='relative ml-auto' timeout={Timeout.RIPPLE_EFFECT_ICON_BUTTON} to={Path.MESSAGES}>
    <Button classes='p-4'>
      <IconMessages />
      <MessagesBadge quantity={todoQuantity} />
    </Button>
    <RippleEffect />
  </OrganizationLink>
)

export default MessagesButton
