import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as StateStopIconSVG } from './stop-state-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconStateStop: FC<IProps> = ({ color = 'warning-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <StateStopIconSVG />
  </Icon>
)
