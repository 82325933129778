import cn from 'classnames'
import React, { FC } from 'react'

import { ReactComponent as SkeletonIconSVG } from './icon.svg'

import { ISkeletonIconProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonIcon: FC<IProps> = ({ classes }) => (
  <span className={cn('skeleton skeleton--icon', classes)}>
    <SkeletonIconSVG />
  </span>
)
