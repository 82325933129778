import cn from 'classnames'
import React, { ReactElement, ReactNode } from 'react'
import { TFunction } from 'i18next'
import { v4 as uuidv4 } from 'uuid'

import { Duration } from '@consts/common'

import { getNotificationValueByT } from '@utils/utils'

import { Button } from '@common/Button'
import { Ellipsis } from '@common/Ellipsis'
import { RippleEffect } from '@common/RippleEffect'

import { TButtonTheme } from '@common/Button/types'
import { INotificationButtons, INotificationOption } from './types'

export const renderNotificationTitle = (t: TFunction, title?: INotificationOption): ReactNode =>
  title?.value && <h2 className={cn('h2 my-0 mx-2', title?.classes)}>{getNotificationValueByT(title.value, t)}</h2>

export const renderNotificationContent = (t: TFunction, content?: INotificationOption[]): ReactNode =>
  content?.length && (
    <span className='d-flex flex-column mt-4 mx-2 mb-2 text-muted'>
      {content?.map(
        ({ classes, value }) =>
          value && (
            <span key={uuidv4()} className={cn('mb-2', classes)}>
              {getNotificationValueByT(value, t)}
            </span>
          )
      )}
    </span>
  )

export const renderNotificationCloseButton = (
  t: TFunction,
  buttonTheme: TButtonTheme,
  respondNotification: (callback?: () => void) => () => void
): ReactElement => (
  <Button classes='relative fw-500 ml-auto p-2' onClick={respondNotification()} theme={buttonTheme}>
    {t('common:close')}
    <RippleEffect duration={Duration.RIPPLE_EFFECT_NOTIFICATION_BUTTON} />
  </Button>
)

export const renderConfirmNotificationButtons = (
  t: TFunction,
  { submit, cancel }: INotificationButtons,
  respondNotification: (callback?: () => void) => () => void
): ReactElement => {
  const buttonClasses = 'relative fw-500 p-2'

  return (
    <div className='d-flex ml-auto'>
      {submit && (
        <Button classes={buttonClasses} onClick={respondNotification(submit.callback)} theme='link'>
          <Ellipsis classes='notification--confirm__submit'>{t(submit.text)}</Ellipsis>
          <RippleEffect duration={Duration.RIPPLE_EFFECT_NOTIFICATION_BUTTON} />
        </Button>
      )}

      {cancel && (
        <Button classes={buttonClasses} onClick={respondNotification(cancel.callback)}>
          {t(cancel.text)}
          <RippleEffect duration={Duration.RIPPLE_EFFECT_NOTIFICATION_BUTTON} />
        </Button>
      )}
    </div>
  )
}
