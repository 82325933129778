import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as QuestionIconSVG } from './question-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconQuestion: FC<IProps> = ({ color = 'warning-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <QuestionIconSVG />
  </Icon>
)
