import React, { FC } from 'react'

import { HeaderWrapper, NavigationWrapper } from '@containers/navigation'

const Menu: FC = () => (
  <>
    <HeaderWrapper />
    <NavigationWrapper />
  </>
)

export default Menu
