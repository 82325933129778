import cn from 'classnames'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import { ColorTheme, Duration } from '@consts/common'
import { ThemeType } from '@consts/enumTypes'

import { getOrganizationInfo, createPath, transformPathToString } from '@utils/utils'

import { Ellipsis } from '@common/Ellipsis'
import { RippleEffect } from '@common/RippleEffect'

import { TCustomNavLinkProps as IProps } from './types'

import styles from './CustomNavLink.module.css'

const classes = {
  wrapper: (theme: ThemeType) =>
    cn(styles[theme], 'relative d-flex height-full flex-column align-items-center justify-content-center')
}

const CustomNavLink: FC<IProps> = ({
  activeNavItem,
  alias,
  dispatch,
  icon,
  location,
  match,
  oguid,
  staticContext,
  theme = ThemeType.DEFAULT,
  title,
  to,
  ...rest
}) => {
  const organization = getOrganizationInfo(alias, oguid)
  const newTo: string = transformPathToString(to)

  const activePathname = activeNavItem.route || location.pathname

  const { t } = useTranslation()

  return (
    <NavLink to={createPath(organization, to)} isActive={() => activePathname.includes(newTo)} {...rest}>
      <div className={classes.wrapper(theme)}>
        {icon}
        <Ellipsis classes='font-xs text-center width-full'>{t(title)}</Ellipsis>
        <RippleEffect duration={Duration.RIPPLE_EFFECT_NAVIGATION_LINK} color={ColorTheme[theme]} />
      </div>
    </NavLink>
  )
}

export default withRouter(CustomNavLink)
