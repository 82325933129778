import React, { FC } from 'react'

import { ReactComponent as SkeletonUsernameSVG } from './username.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonUsername: FC = () => (
  <div className='skeleton skeleton--username mb-2'>
    <SkeletonUsernameSVG />
  </div>
)
