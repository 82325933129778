import { INavigationState, NavigationActionTypes, TNavigationActions } from './types'

export const initialState: INavigationState = {
  activeNavItem: {
    route: '',
    title: ''
  },
  badges: {
    taskQuantity: 0,
    todoQuantity: 0
  },
  navItems: [],
  openedFromNotification: null,
  prevNavRoute: null
}

export default function navigationReducer (state = initialState, action: TNavigationActions): INavigationState {
  switch (action.type) {
    case NavigationActionTypes.RESET_NAVIGATION:
      return { ...initialState }

    case NavigationActionTypes.SET_ACTIVE_NAV_ITEM:
      return {
        ...state,
        activeNavItem: action.payload
      }

    case NavigationActionTypes.SET_BADGES:
      return {
        ...state,
        badges: { ...action.payload }
      }

    case NavigationActionTypes.SET_NAV_ITEMS:
      return {
        ...state,
        navItems: [...action.payload]
      }

    case NavigationActionTypes.SET_OPENED_FROM_NOTIFICATION:
      return {
        ...state,
        openedFromNotification: action.payload
      }

    case NavigationActionTypes.SET_PREV_NAV_ROUTE:
      return {
        ...state,
        prevNavRoute: action.payload
      }

    default:
      return state
  }
}
