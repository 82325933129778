import React, { FC } from 'react'

import { ContentType } from '@consts/enumTypes'

import { Content } from '@common/Content'
import { SkeletonTaskListItem } from '@common/skeletons/components'

// Todo подумать как вычислять количество скелетонов
export const SkeletonTasks: FC = () => (
  <Content classes='bg-light' type={ContentType.WITH_TABS}>
    <SkeletonTaskListItem />
    <SkeletonTaskListItem />
    <SkeletonTaskListItem />
    <SkeletonTaskListItem />
    <SkeletonTaskListItem />
    <SkeletonTaskListItem />
  </Content>
)
