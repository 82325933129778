import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as FolderCreateIconSVG } from './folder-create-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconFolderCreate: FC<IProps> = ({ color = 'success-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <FolderCreateIconSVG />
  </Icon>
)
