import { IUtilsState, TUtilsActions, UtilsActionTypes } from './types'

export const initialState: IUtilsState = {
  existingUserOguid: '',
  isError404: false,
  isError500: false,
  areMessagesShown: false
}

export default function utilsReducer (state = initialState, action: TUtilsActions): IUtilsState {
  switch (action.type) {
    case UtilsActionTypes.SET_ERROR404:
      return {
        ...state,
        isError404: true
      }

    case UtilsActionTypes.SET_ERROR500:
      return {
        ...state,
        isError500: true
      }

    case UtilsActionTypes.RESET_ERROR404:
      return {
        ...state,
        isError404: false
      }

    case UtilsActionTypes.RESET_ERROR500:
      return {
        ...state,
        isError500: false
      }

    case UtilsActionTypes.SET_EXISTING_USER:
      return {
        ...state,
        existingUserOguid: action.payload
      }

    default:
      return state
  }
}
