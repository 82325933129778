import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import authService from '@services/auth'
import userService from '@services/user'

import { isNotMobileDevice, needResetParams, ResponseCode } from '@consts/common'

import * as jwt from '@utils/jwt'
import { getActiveOrgOguidFromStorage, setActiveOrgToStorage } from '@utils/localStorage'

import { resetDocument } from '@store/modules/document/actions'
import { resetDocuments } from '@store/modules/documents/actions'
import { resetMessages } from '@store/modules/messages/actions'
import { resetMetadata } from '@store/modules/metadata/actions'
import { resetNavigation } from '@store/modules/navigation/actions'
import { resetStartup } from '@store/modules/startup/actions'
import { resetTasks } from '@store/modules/tasks/actions'
import { resetError404, resetError500 } from '@store/modules/utils/actions'

import { IUserOrganization } from '@store/types/common'

import {
  IActionResetAccessToken,
  IActionResetPrevActiveOrgOguid,
  IActionSetAccessToken,
  IActionSetActiveOrganization,
  IActionSetAuthData,
  IActionSetAuthenticated,
  IActionSetPrevActiveOrgOguid,
  IActionSetUserExtendedInfo,
  IActionSetUserInfo,
  IActionSetUserLocale,
  IActionSetUserOrganizations,
  IAuthTokens,
  IEmailPasswordAuth,
  IUpdatedUserInfo,
  IUser,
  IUserExtendedInfo,
  SyncThunk,
  UserActionKey,
  UserActions,
  UserActionTypes,
  UserThunkAction,
  UserThunkDispatch
} from './types'

// actions
const resetAccessTokenAction: ActionCreator<Action> = (): IActionResetAccessToken => ({
  type: UserActionTypes.RESET_ACCESS_TOKEN
})

const resetPrevActiveOrgOguidAction: ActionCreator<Action> = (): IActionResetPrevActiveOrgOguid => ({
  type: UserActionTypes.RESET_PREV_ACTIVE_ORG_OGUID
})

const setAccessTokenAction: ActionCreator<Action> = (token: string): IActionSetAccessToken => ({
  payload: token,
  type: UserActionTypes.SET_ACCESS_TOKEN
})

const setActiveOrganizationAction: ActionCreator<Action> = (
  organization: IUserOrganization
): IActionSetActiveOrganization => ({
  payload: organization,
  type: UserActionTypes.SET_ACTIVE_ORGANIZATION
})

const setAuthDataAction: ActionCreator<Action> = (authData: IEmailPasswordAuth): IActionSetAuthData => ({
  payload: authData,
  type: UserActionTypes.SET_AUTH_DATA
})

const setAuthenticatedAction: ActionCreator<Action> = (isAuthenticated = true): IActionSetAuthenticated => ({
  payload: {
    isAuthenticated
  },
  type: UserActionTypes.SET_AUTHENTICATED
})

const setPrevActiveOrgOguidAction: ActionCreator<Action> = (prevOrgOguid: string): IActionSetPrevActiveOrgOguid => ({
  payload: prevOrgOguid,
  type: UserActionTypes.SET_PREV_ACTIVE_ORG_OGUID
})

const setUserExtendedInfoAction: ActionCreator<Action> = (
  extendedInfo: IUserExtendedInfo
): IActionSetUserExtendedInfo => ({
  payload: extendedInfo,
  type: UserActionTypes.SET_USER_EXTENDED_INFO
})

const setUserInfoAction: ActionCreator<Action> = (user: IUser): IActionSetUserInfo => ({
  payload: user,
  type: UserActionTypes.SET_USER_INFO
})

const setUserLocaleAction: ActionCreator<Action> = (locale: string): IActionSetUserLocale => ({
  payload: locale,
  type: UserActionTypes.SET_USER_LOCALE
})

const setUserOrganizationsAction: ActionCreator<Action> = (
  organizations: IUserOrganization[]
): IActionSetUserOrganizations => ({
  payload: organizations,
  type: UserActionTypes.SET_USER_ORGANIZATIONS
})

export const userActions: UserActions = {
  [UserActionKey.RESET_ACCESS_TOKEN]: resetAccessTokenAction,
  [UserActionKey.RESET_PREV_ACTIVE_ORG_OGUID]: resetPrevActiveOrgOguidAction,
  [UserActionKey.SET_ACCESS_TOKEN]: setAccessTokenAction,
  [UserActionKey.SET_ACTIVE_ORGANIZATION]: setActiveOrganizationAction,
  [UserActionKey.SET_AUTH_DATA]: setAuthDataAction,
  [UserActionKey.SET_AUTHENTICATED]: setAuthenticatedAction,
  [UserActionKey.SET_PREV_ACTIVE_ORG_OGUID]: setPrevActiveOrgOguidAction,
  [UserActionKey.SET_USER_EXTENDED_INFO]: setUserExtendedInfoAction,
  [UserActionKey.SET_USER_INFO]: setUserInfoAction,
  [UserActionKey.SET_USER_LOCALE]: setUserLocaleAction,
  [UserActionKey.SET_USER_ORGANIZATIONS]: setUserOrganizationsAction
}

// thunks
export const getUserInfo: ActionCreator<UserThunkAction> = () => (
  dispatch: UserThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  userService
    .getUserInfo()
    .then((resp: AxiosResponse<IUser>) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(userActions[UserActionKey.SET_USER_INFO](resp.data))

        const { orgs } = resp.data

        if (!orgs.length) {
          dispatch(userActions[UserActionKey.RESET_ACCESS_TOKEN]())

          return resp
        }

        const { isAuthenticated } = getState().user

        if (!isAuthenticated) {
          dispatch(userActions[UserActionKey.SET_AUTHENTICATED](true))
        }

        const existedActiveOrgOguid = getActiveOrgOguidFromStorage()

        let existedActiveOrg

        if (existedActiveOrgOguid) {
          existedActiveOrg = orgs.find((org: IUserOrganization) => org.oguid === existedActiveOrgOguid)
        }

        const activeOrg = existedActiveOrg ?? orgs[0]

        if (!existedActiveOrg) {
          setActiveOrgToStorage(activeOrg.oguid)
        }

        dispatch(userActions[UserActionKey.SET_ACTIVE_ORGANIZATION](activeOrg))
      }

      return resp
    })
    .catch(Promise.reject)

export const requestUserOrganizations: ActionCreator<UserThunkAction> = () => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> =>
  userService
    .getUserInfo()
    .then((resp: AxiosResponse<IUser>) => {
      if (resp.status === ResponseCode.GET) {
        const { orgs } = resp.data

        if (!orgs.length) {
          dispatch(userActions[UserActionKey.RESET_ACCESS_TOKEN]())
          return resp
        }

        dispatch(userActions[UserActionKey.SET_USER_ORGANIZATIONS](orgs))
      }

      return resp
    })
    .catch(Promise.reject)

export const changeOrganization: SyncThunk = (organization: IUserOrganization) => (
  dispatch: UserThunkDispatch
): void => {
  setActiveOrgToStorage(organization.oguid)

  dispatch(userActions[UserActionKey.SET_ACTIVE_ORGANIZATION](organization))
}

export const resetStoreAfterOrganizationChange: SyncThunk = () => (dispatch: UserThunkDispatch): void => {
  dispatch(resetDocument)
  dispatch(resetDocuments)
  dispatch(resetError404)
  dispatch(resetError500)
  dispatch(resetMessages)
  dispatch(resetMetadata)
  dispatch(resetNavigation)
  dispatch(resetStartup)
  dispatch(resetTasks)
}

export const getOrganization: SyncThunk = () => (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
  authService
    .getOrganization()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(userActions[UserActionKey.SET_ACTIVE_ORGANIZATION](resp.data))
      }

      return resp
    })
    .catch(Promise.reject)

export const updateUserInfo: ActionCreator<UserThunkAction> = (userInfo: IUpdatedUserInfo) => (): Promise<
  AxiosResponse
> =>
  userService.updateUserInfo(userInfo).then((resp: AxiosResponse) => {
    return resp
  })

export const signin: ActionCreator<UserThunkAction> = (authData: IEmailPasswordAuth) => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse> =>
  authService.signin(authData, { ...needResetParams }).then((resp: AxiosResponse<IAuthTokens>) => {
    const {
      data: { access },
      status
    } = resp

    if (status === ResponseCode.GET) {
      // Сохраняем токен в сторе
      dispatch(userActions[UserActionKey.SET_ACCESS_TOKEN](access.value))
      // Сохраняем токен в cookies
      isNotMobileDevice && jwt.set(access.value)
    }

    return resp
  })

export const confirmPassword: ActionCreator<UserThunkAction> = (authData: IEmailPasswordAuth) => (): Promise<
  AxiosResponse
> => authService.signin(authData, { ...needResetParams })

export const getUserExtendedInfo: SyncThunk = () => (
  dispatch: UserThunkDispatch
): Promise<AxiosResponse<IUserExtendedInfo>> =>
  userService
    .getExtendedInfo()
    .then((resp: AxiosResponse<IUserExtendedInfo>) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(userActions[UserActionKey.SET_USER_EXTENDED_INFO](resp.data))
      }

      return resp
    })
    .catch(Promise.reject)
