import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import tasksService from '@services/tasks'

import { ResponseCode, SuccessCode } from '@consts/common'
import { CompleteStatus, TaskTab } from '@consts/enumTypes'

import { getPagination } from '@utils/utils'

import { navigationActions } from '../navigation/actions'

import { IParams } from '@store/types/common'
import { NavigationActionKey, NavigationThunkDispatch } from '../navigation/types'
import {
  IActionCompleteTask,
  IActionResetTasks,
  IActionResetTasksByTab,
  IActionSetActiveTabToTasks,
  IActionSetTasks,
  TasksActionKey,
  TasksActions,
  TasksActionTypes,
  TasksThunkDispatch,
  TasksThunkAction,
  ITasksAndPagination,
  IDataToCompleteTask
} from './types'

// actions
const completeTaskAction: ActionCreator<Action> = (payload: IDataToCompleteTask): IActionCompleteTask => ({
  payload,
  type: TasksActionTypes.COMPLETE_TASK
})

export const resetTasks: IActionResetTasks = {
  type: TasksActionTypes.RESET_TASKS
}

export const resetTasksByTab: IActionResetTasksByTab = {
  type: TasksActionTypes.RESET_TASKS_BY_TAB
}

const setActiveTabToTasksAction: ActionCreator<Action> = (activeTab: TaskTab): IActionSetActiveTabToTasks => ({
  payload: activeTab,
  type: TasksActionTypes.SET_ACTIVE_TAB_TO_TASKS
})

const setTasksAction: ActionCreator<Action> = (payload: ITasksAndPagination): IActionSetTasks => ({
  payload,
  type: TasksActionTypes.SET_TASKS
})

export const tasksActions: TasksActions = {
  [TasksActionKey.COMPLETE_TASK]: completeTaskAction,
  [TasksActionKey.SET_ACTIVE_TAB_TO_TASKS]: setActiveTabToTasksAction,
  [TasksActionKey.SET_TASKS]: setTasksAction
}

// thunks
export const getTasks: ActionCreator<TasksThunkAction> = (params: IParams) => (
  dispatch: TasksThunkDispatch
): Promise<AxiosResponse> =>
  tasksService.getTasks({ params }).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      const payload: ITasksAndPagination = {
        tasks: resp.data,
        pagination: getPagination(resp.headers)
      }

      dispatch(tasksActions[TasksActionKey.SET_TASKS](payload))
    }

    return resp
  })

export const completeTask = (taskOguid: string, result: CompleteStatus) => (
  dispatch: NavigationThunkDispatch
): Promise<TasksThunkAction | AxiosResponse<any>> =>
  tasksService.completeTask(taskOguid, { comment: null, result }).then((resp: AxiosResponse) => {
    if (SuccessCode.POST.includes(resp.status)) {
      dispatch(navigationActions[NavigationActionKey.SET_BADGES](resp.data))
    }

    return resp
  })
