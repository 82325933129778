import { NotificationType } from '@consts/enumTypes'

import StoreNotifications from '../components/StoreNotifications'

import { INotification, IConfirmNotificationOptions } from '../types'

export const showConfirmNotification = ({ buttons, options }: IConfirmNotificationOptions): void => {
  const notification: INotification = {
    buttons,
    options,
    id: '',
    type: NotificationType.CONFIRM
  }

  StoreNotifications.add(notification)
}
