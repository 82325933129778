import { MetadataActionTypes, IMetadataState, TMetadataActions } from './types'

export const initialState: IMetadataState = {
  documentTypes: {},
  flowStageTypes: [],
  historyStateCodes: {},
  workflowStatuses: {}
}

export default (state = initialState, action: TMetadataActions): IMetadataState => {
  switch (action.type) {
    case MetadataActionTypes.RESET_METADATA:
      return { ...initialState }

    case MetadataActionTypes.SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: { ...action.payload }
      }

    case MetadataActionTypes.SET_FLOW_STAGE_TYPES:
      return {
        ...state,
        flowStageTypes: [...action.payload]
      }

    case MetadataActionTypes.SET_HISTORY_STATE_CODES:
      return {
        ...state,
        historyStateCodes: { ...action.payload }
      }

    case MetadataActionTypes.SET_WORKFLOW_STATUSES:
      return {
        ...state,
        workflowStatuses: { ...action.payload }
      }

    default:
      return state
  }
}
