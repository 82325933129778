import { AxiosResponse } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IUpdatedUserInfo, IUser, IUserExtendedInfo } from '@store/modules/user/types'

class UserService extends AbstractHttpService {
  async getExtendedInfo (): Promise<AxiosResponse<IUserExtendedInfo>> {
    const url = urls.user.single

    const response: AxiosResponse<IUserExtendedInfo> = await this._http.get(url)

    return response
  }

  async getUserInfo (): Promise<AxiosResponse<IUser>> {
    const url = urls.user.single

    const response: AxiosResponse<IUser> = await this._http.get(url, undefined, false)

    return response
  }

  async updateUserInfo (data: IUpdatedUserInfo): Promise<AxiosResponse> {
    const url = urls.user.single

    const response: AxiosResponse = await this._http.put(url, data, undefined, false)

    return response
  }
}

export default new UserService()
