import cn from 'classnames'
import React, { FC } from 'react'

import { ThemeType } from '@consts/enumTypes'

import { ILabelCircleProps as IProps } from './types'

import './LabelCircle.scss'

const getClasses = (theme: ThemeType, classes = ''): string => cn('label-circle', `bg-${theme}`, classes)

export const LabelCircle: FC<IProps> = ({ theme = ThemeType.DEFAULT, classes }) => (
  <div className={getClasses(theme, classes)} />
)
