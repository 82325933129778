import { connect } from 'react-redux'

import CustomNavLink from './CustomNavLink'

import { IApplicationState } from '@store/types/common'
import { ICustomNavLinkPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeNavItem: state.navigation.activeNavItem,
  alias: state.user.activeOrg.alias,
  oguid: state.user.activeOrg.oguid
})

export default connect(mapStateToProps)(CustomNavLink)
