import { AxiosResponse, AxiosRequestConfig } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IAuthTokens, IEmailPasswordAuth } from '@store/modules/user/types'
import { IDataToRestorePassword } from './types'

class AuthService extends AbstractHttpService {
  async signin (data: IEmailPasswordAuth, config: AxiosRequestConfig): Promise<AxiosResponse<IAuthTokens>> {
    const url = urls.auth.signin

    const response: AxiosResponse<IAuthTokens> = await this._http.post(url, data, config, false)

    return response
  }

  async getOrganization (): Promise<AxiosResponse> {
    const url = urls.auth.org

    const response: AxiosResponse = await this._http.get(url)

    return response
  }

  async restorePassword (data: IDataToRestorePassword): Promise<AxiosResponse> {
    const url = urls.auth.restorePassword

    const response: AxiosResponse = await this._http.post(url, data, {}, false)

    return response
  }
}

export default new AuthService()
