import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import Navigation from './NavigationWrapper'

import { INavItem } from '@types'

import { navigationActions } from '@store/modules/navigation/actions'
import { resetError404 } from '@store/modules/utils/actions'

import { IApplicationState } from '@store/types/common'
import {
  INavigationWrapperPropsFromDispatch as IPropsFromDispatch,
  INavigationWrapperPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeNavItem: state.navigation.activeNavItem,
  document: state.document.document,
  isAuthenticated: state.user.isAuthenticated,
  isExchangeParticipant: state.user.isExchangeParticipant,
  isFlowFunctionalityEnabled: state.user.isFlowFunctionalityEnabled,
  isReadonly: state.user.isReadonly,
  navItems: state.navigation.navItems,
  openedFromNotification: state.navigation.openedFromNotification,
  orgOguid: state.user.activeOrg.oguid,
  prevActiveOrgOguid: state.user.prevActiveOrgOguid,
  role: state.user.role
})

const mapDispatchToProps = (dispatch: Dispatch): IPropsFromDispatch => ({
  onSetNavItems: (navItems: INavItem[]) => dispatch(navigationActions.setNavItems(navItems)),
  resetError404: () => dispatch(resetError404)
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
