import { DocumentActionTypes, IDocumentState, TDocumentActions } from './types'

export const initialState: IDocumentState = {
  document: null,
  documentId: null,
  file: {
    data: [],
    isAvailable: true,
    total: 0
  },
  flowState: {
    processOguid: '',
    routeOguid: '',
    stages: []
  },
  message: null,
  messages: [],
  title: ''
}

export default function documentReducer (
  state: IDocumentState = initialState,
  action: TDocumentActions
): IDocumentState {
  switch (action.type) {
    case DocumentActionTypes.RESET_DOCUMENT:
      return {
        ...initialState
      }

    case DocumentActionTypes.RESET_DOCUMENT_FILE:
      return {
        ...state,
        file: initialState.file
      }

    case DocumentActionTypes.RESET_DOCUMENT_MESSAGE:
      return {
        ...state,
        message: initialState.message
      }

    case DocumentActionTypes.SET_DOCUMENT:
      return {
        ...state,
        document: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_FILE:
      return {
        ...state,
        file: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_FLOW_STATE:
      return {
        ...state,
        flowState: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_MESSAGE:
      return {
        ...state,
        message: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_MESSAGES:
      return {
        ...state,
        messages: action.payload
      }

    case DocumentActionTypes.SET_DOCUMENT_TITLE:
      return {
        ...state,
        title: action.payload
      }

    default:
      return state
  }
}
