import cn from 'classnames'
import React, { ForwardedRef, forwardRef } from 'react'
import { useSelector } from 'react-redux'

import { TaskTab } from '@consts/enumTypes'

import {
  SkeletonListItemContractor,
  SkeletonDatetime,
  SkeletonStatus,
  SkeletonListItemTitle
} from '@common/skeletons/components'

import { IApplicationState } from '@store/types/common'
import { ISkeletonTaskListItemProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonTaskListItem = forwardRef(({ withTopBorder }: IProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { activeTab } = useSelector((state: IApplicationState) => state.tasks)

  return (
    <div className={cn('px-4 bg-light border-bottom', { 'border-top': withTopBorder })} ref={ref}>
      <SkeletonListItemContractor />
      <SkeletonListItemTitle />

      <div className='d-flex align-items-center mb-4'>
        <SkeletonStatus />
        {activeTab === TaskTab.COMPLETED && <SkeletonDatetime />}
      </div>
    </div>
  )
})

SkeletonTaskListItem.displayName = 'SkeletonTaskListItem'
