import React, { FC } from 'react'

import { ReactComponent as SkeletonListItemTitleSVG } from './list-item-title.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonListItemTitle: FC = () => (
  <div className='skeleton skeleton--list-item-title mb-2'>
    <SkeletonListItemTitleSVG />
  </div>
)
