import React, { FC, ReactNode } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import { TRoute as IProps } from './types'

const RouteWithSubRoutes: FC<IProps> = (props) => {
  const { path, exact, routeAdditionalProp } = props

  const renderHandler = (renderProps: RouteComponentProps): ReactNode => (
    <props.component {...renderProps} routeAdditionalProp={routeAdditionalProp} />
  )

  return <Route path={path} exact={exact} render={renderHandler} />
}

export default RouteWithSubRoutes
