import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as FlagRuIconSVG } from './flag-ru-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconFlagRu: FC<IProps> = ({ ...restProps }) => (
  <Icon {...restProps}>
    <FlagRuIconSVG />
  </Icon>
)
