import React, { FC } from 'react'

import { Icon } from '../../Icon'

import { IIconProps as IProps } from '../../Icon/types'

import { ReactComponent as QuestionAltIconSVG } from './question-alt-icon.svg'

export const IconQuestionAlt: FC<IProps> = ({ color = 'danger-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <QuestionAltIconSVG />
  </Icon>
)
