import { AxiosError } from 'axios'

import { NotificationType } from '@consts/enumTypes'

import StoreNotifications from '../components/StoreNotifications'

import { INotificationOptions, INotification } from '../types'

export const showErrorNotification = ({ response }: AxiosError, options?: INotificationOptions): void => {
  const notification: INotification = {
    id: '',
    options: {
      content: response?.data?.message
        ? [{ value: response?.data?.message }]
        : options?.content ?? [
            { value: 'notifications:network.errors.notConnect.content.1' },
            { value: 'notifications:network.errors.notConnect.content.2' }
          ],
      title: {
        classes: options?.title?.classes ?? '',
        value: response?.data?.message ? '' : options?.title?.value ?? 'notifications:network.errors.notConnect.title'
      }
    },
    type: NotificationType.ERROR
  }

  StoreNotifications.add(notification)
}
