import React, { FC } from 'react'

import { ReactComponent as SkeletonTabSVG } from './tab.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonTab: FC = () => (
  <div className='d-flex align-items-center justify-content-center height-full'>
    <div className='skeleton skeleton--tab'>
      <SkeletonTabSVG />
    </div>
  </div>
)
