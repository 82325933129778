// Класс для создания экземпляра нотификации
import { INotification } from '../../types'
import { TAddNotification, TRegisterNotification, TUnregisterNotification } from './types'

class StoreNotifications {
  // В функциях создания сообщений сюда передается объект с полями сообщения
  // Добавление сообщения в массив происходит в методе компонента add()
  public add: TAddNotification = (_notification: INotification): void => {
    // return
  }

  // Вызывается при монтировании компонента Notifications
  public register: TRegisterNotification = (addNotification: TAddNotification): void => {
    this.add = addNotification
  }

  // Вызывается при размонтировании компонента Notifications
  public unregister: TUnregisterNotification = (): void => {
    this.add = () => null
  }
}

export default new StoreNotifications()
