import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import documentService from '@services/document'

import { ResponseCode } from '@consts/common'

import { documentsActions } from '../documents/actions'

import { DocumentsActionKey, IDocument } from '../documents/types'
import {
  DocumentActionKey,
  DocumentActions,
  DocumentActionTypes,
  DocumentThunkAction,
  DocumentThunkDispatch,
  IActionResetDocument,
  IActionResetDocumentFile,
  IActionResetDocumentMessage,
  IActionSetDocument,
  IActionSetDocumentFile,
  IActionSetDocumentFlowState,
  IActionSetDocumentId,
  IActionSetDocumentMessage,
  IActionSetDocumentMessages,
  IActionSetDocumentTitle,
  IDocumentFile,
  IDocumentFlowState,
  IDocumentMessage
} from './types'

// actions
export const resetDocument: IActionResetDocument = {
  type: DocumentActionTypes.RESET_DOCUMENT
}

export const resetDocumentFile: IActionResetDocumentFile = {
  type: DocumentActionTypes.RESET_DOCUMENT_FILE
}

export const resetDocumentMessage: IActionResetDocumentMessage = {
  type: DocumentActionTypes.RESET_DOCUMENT_MESSAGE
}

const setDocumentAction: ActionCreator<Action> = (document: IDocument): IActionSetDocument => ({
  payload: document,
  type: DocumentActionTypes.SET_DOCUMENT
})

const setDocumentFileAction: ActionCreator<Action> = (file: IDocumentFile): IActionSetDocumentFile => ({
  payload: file,
  type: DocumentActionTypes.SET_DOCUMENT_FILE
})

const setDocumentFlowStateAction: ActionCreator<Action> = (
  flowState: IDocumentFlowState
): IActionSetDocumentFlowState => ({
  payload: flowState,
  type: DocumentActionTypes.SET_DOCUMENT_FLOW_STATE
})

const setDocumentIdAction: ActionCreator<Action> = (documentId: number): IActionSetDocumentId => ({
  payload: documentId,
  type: DocumentActionTypes.SET_DOCUMENT_ID
})

const setDocumentMessageAction: ActionCreator<Action> = (message: IDocumentMessage): IActionSetDocumentMessage => ({
  payload: message,
  type: DocumentActionTypes.SET_DOCUMENT_MESSAGE
})

const setDocumentMessagesAction: ActionCreator<Action> = (
  messages: IDocumentMessage[]
): IActionSetDocumentMessages => ({
  payload: messages,
  type: DocumentActionTypes.SET_DOCUMENT_MESSAGES
})

const setDocumentTitleStateAction: ActionCreator<Action> = (title: string): IActionSetDocumentTitle => ({
  payload: title,
  type: DocumentActionTypes.SET_DOCUMENT_TITLE
})

export const documentActions: DocumentActions = {
  [DocumentActionKey.SET_DOCUMENT]: setDocumentAction,
  [DocumentActionKey.SET_DOCUMENT_FILE]: setDocumentFileAction,
  [DocumentActionKey.SET_DOCUMENT_FLOW_STATE]: setDocumentFlowStateAction,
  [DocumentActionKey.SET_DOCUMENT_ID]: setDocumentIdAction,
  [DocumentActionKey.SET_DOCUMENT_MESSAGE]: setDocumentMessageAction,
  [DocumentActionKey.SET_DOCUMENT_MESSAGES]: setDocumentMessagesAction,
  [DocumentActionKey.SET_DOCUMENT_TITLE]: setDocumentTitleStateAction
}

// thunks
// Получение данных документа
export const getDocumentData: ActionCreator<DocumentThunkAction> = (documentId: string) => (
  dispatch: DocumentThunkDispatch
): Promise<AxiosResponse<IDocument>> =>
  documentService.getDocumentData(documentId).then((resp: AxiosResponse<IDocument>) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(documentActions[DocumentActionKey.SET_DOCUMENT](resp.data))
      dispatch(documentsActions[DocumentsActionKey.UPDATE_DOCUMENT](resp.data))
    }

    return resp
  })

// Получение превью файла документа
export const getDocumentFile: ActionCreator<DocumentThunkAction> = () => (
  dispatch: DocumentThunkDispatch,
  getState
): Promise<AxiosResponse<IDocumentFile>> => {
  const { file, document } = getState().document
  const { data, total } = file

  return documentService
    .getDocumentFile(document?.oguid ?? '', data.length + 1)
    .then((resp: AxiosResponse<IDocumentFile>) => {
      if (resp.status === ResponseCode.GET) {
        const mimeType = resp.headers['x-mime-type']
        const orientation = resp.headers['x-page-orientation']
        const totalPages = resp.headers['x-total-pages']

        const newDocumentFile = resp.data
          ? {
              ...file,
              data: [
                ...data,
                {
                  fileCode: resp.data,
                  mimeType,
                  orientation
                }
              ],
              total: total === 0 ? totalPages : total
            }
          : {
              ...file,
              isAvailable: false
            }

        dispatch(documentActions[DocumentActionKey.SET_DOCUMENT_FILE](newDocumentFile))
      }

      return resp
    })
}

// Получение сообщений по документу
export const getDocumentMessages: ActionCreator<DocumentThunkAction> = (documentOguid: string) => (
  dispatch: DocumentThunkDispatch
): Promise<AxiosResponse> =>
  documentService.getDocumentMessages(documentOguid).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(documentActions[DocumentActionKey.SET_DOCUMENT_MESSAGES](resp.data))
    }

    return resp
  })

// Получение текущего согласования документа
export const getDocumentFlowState: ActionCreator<DocumentThunkAction> = (documentOguid: string) => (
  dispatch: DocumentThunkDispatch
): Promise<AxiosResponse<IDocumentFlowState>> =>
  documentService.getDocumentFlowState(documentOguid).then((resp: AxiosResponse<IDocumentFlowState>) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(documentActions[DocumentActionKey.SET_DOCUMENT_FLOW_STATE](resp.data))
    }

    return resp
  })

// Удаление документа (в данный момент не используется)
export const removeDocument: ActionCreator<DocumentThunkAction> = () => (
  dispatch: DocumentThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  documentService.removeDocument(getState().document.document?.oguid ?? '').catch(Promise.reject)
