import React, { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Navigation } from '@common/Navigation'
import { NavigationItem } from '@common/NavigationItem'

import { SkeletonNavIconSVG, SkeletonNavLabelSVG } from './svg'

import '@common/skeletons/Skeletons.scss'

const SkeletonNavItem: FC = () => (
  <div className='d-flex height-full flex-column align-items-center justify-content-center'>
    <span className='skeleton skeleton--nav-icon'>
      <SkeletonNavIconSVG />
    </span>
    <span className='skeleton skeleton--nav-label mt-1'>
      <SkeletonNavLabelSVG />
    </span>
  </div>
)

export const SkeletonNavigation: FC = () => (
  <Navigation>
    <NavigationItem uuid={uuidv4()} customNavItem={<SkeletonNavItem />} />
    <NavigationItem uuid={uuidv4()} customNavItem={<SkeletonNavItem />} />
  </Navigation>
)
