import cn from 'classnames'
import React, { FC } from 'react'

import { ThemeType } from '@consts/enumTypes'

import { ReactComponent as SpinnerSVG } from './spinner.svg'

import { ISpinnerProps as IProps } from './types'

import './Spinner.scss'

const getClasses = (theme = ThemeType.DEFAULT, classes?: string): string =>
  cn(`spinner spinner--${theme}`, classes ?? 'absolute at-6 al-4')

export const Spinner: FC<IProps> = ({ classes, theme }) => (
  <div className={getClasses(theme, classes)}>
    <SpinnerSVG />
  </div>
)
