import cn from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'

import { AnimationClass, Timeout } from '@consts/common'
import { NotificationType } from '@consts/enumTypes'

import {
  renderConfirmNotificationButtons,
  renderNotificationCloseButton,
  renderNotificationContent,
  renderNotificationTitle
} from '@common/Notifications/utils'

import { Background } from '@common/Background'
import { IconCheck } from '@common/icons'

import { IConfirmNotificationProps as IProps } from '@common/Notifications/types'
import { TButtonTheme } from '@common/Button/types'

import '@common/Notifications/Notifications.scss'

const classNames = {
  enter: 'notification--enter',
  enterActive: 'notification--enter-active',
  exit: 'notification--exit',
  exitActive: 'notification--exit-aActive'
}

export const Notification: FC<IProps> = ({ notification, onRemove }) => {
  const { t } = useTranslation()

  const { buttons, id, options, type } = notification
  const { title, content } = options

  const [animationClass, setAnimationClass] = useState('')

  // Устанавливается класс для показа анимации
  useEffect(() => {
    setAnimationClass(AnimationClass.FADE_IN)

    return removeNotification
  }, [])

  // Убирается класс анимации
  useEffect(() => {
    if (animationClass === AnimationClass.FADE_OUT) setTimeout(onRemove, Timeout.RIPPLE_EFFECT_NOTIFICATION_BUTTON)
  }, [animationClass])

  const removeNotification = (): void => setAnimationClass(AnimationClass.FADE_OUT)

  const removeNotificationWithTimeout = (): void => {
    setTimeout(removeNotification, Timeout.RIPPLE_EFFECT_NOTIFICATION_BUTTON)
  }

  const respondNotification = (callback?: () => void) => (): void => {
    callback?.()
    removeNotificationWithTimeout()
  }

  const notificationClasses = cn(
    'notification d-flex flex-column bg-light p-4 fixed at-half al-half trf-n-half',
    `notification--${type.toLowerCase()}`
  )

  const isSuccessNotification = notification.type === NotificationType.SUCCESS

  const buttonTheme: TButtonTheme = isSuccessNotification ? 'default' : 'link'

  return (
    <Background onClose={removeNotification} keyboard backdrop classes={cn('animated', animationClass)}>
      <CSSTransition timeout={Timeout.RIPPLE_EFFECT_NOTIFICATION_BUTTON} classNames={classNames} in={!!id}>
        <div className={notificationClasses}>
          {isSuccessNotification && <IconCheck color='success-500' classes='mt-6 mx-auto' size='xxxl' />}
          {renderNotificationTitle(t, title)}
          {renderNotificationContent(t, content)}
          {buttons
            ? renderConfirmNotificationButtons(t, buttons, respondNotification)
            : renderNotificationCloseButton(t, buttonTheme, respondNotification)}
        </div>
      </CSSTransition>
    </Background>
  )
}
