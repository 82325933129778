import Backend from 'i18next-fetch-backend'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as Sentry from '@sentry/browser'

import { Language, LANGUAGES, NS } from '@consts/languages'

import { getLanguageFromStorage } from '@utils/localStorage'

// ToDo добавить определение локали на основе языка устройства пользователя для формы авторизации
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getLanguageFromStorage() ?? Language.EN,
    fallbackLng: LANGUAGES,
    ns: NS,

    debug: false,

    react: {
      useSuspense: true
    },

    interpolation: {
      escapeValue: false
    }
  }).catch((err) => {
    const errorText = 'i18n error'
    const errorLevel = Sentry.Severity.Fatal
    const errorInfo = { err }

    Sentry.withScope((scope) => {
      scope.setLevel(errorLevel)
      scope.setExtras(errorInfo)
      Sentry.captureException(new Error(errorText))
    })
  })

export default i18n
