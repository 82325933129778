import cn from 'classnames'
import React, { ForwardedRef, forwardRef } from 'react'

import { SkeletonSize } from '@consts/enumTypes'

import {
  SkeletonUsername,
  SkeletonDatetime,
  SkeletonLinkToDocument,
  SkeletonMessageText
} from '@common/skeletons/components'

import { ISkeletonMessageListItemProps as IProps } from './types'

import '@views/organization/Messages/components/Message/Message.scss'
import '@common/skeletons/Skeletons.scss'

export const SkeletonMessageListItem = forwardRef(({ withTopBorder }: IProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div className={cn('message pt-3 pb-2 px-4 mb-4 bg-light', { 'border-top': withTopBorder })} ref={ref}>
    <SkeletonUsername />
    <SkeletonLinkToDocument />
    <SkeletonMessageText />

    <div className='d-flex'>
      <SkeletonDatetime size={SkeletonSize.XS} />
    </div>
  </div>
))

SkeletonMessageListItem.displayName = 'SkeletonMessageListItem'
