import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as ApproveDeclineIconSVG } from './approve-decline-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconApproveDecline: FC<IProps> = ({ color = 'danger-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ApproveDeclineIconSVG />
  </Icon>
)
