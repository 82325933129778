import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as SendingStateIconSVG } from './sending-state-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconStateSending: FC<IProps> = ({ color = 'info-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <SendingStateIconSVG />
  </Icon>
)
