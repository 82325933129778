import React, { FC } from 'react'

import { ReactComponent as SkeletonMessageTextSVG } from './message-text.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonMessageText: FC = () => (
  <div className='skeleton skeleton--message-text mb-2'>
    <SkeletonMessageTextSVG />
  </div>
)
