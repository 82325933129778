import { AxiosResponse, AxiosRequestConfig } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IDocument } from '@store/modules/documents/types'

class DocumentsService extends AbstractHttpService {
  async getDocuments (config: AxiosRequestConfig): Promise<AxiosResponse<IDocument[]>> {
    const url = urls.documents.list

    const response: AxiosResponse<IDocument[]> = await this._http.get(url, config)

    return response
  }
}

export default new DocumentsService()
