import { ILoaderState, LoaderActionTypes, TLoaderActions } from './types'

export const initialState: ILoaderState = {
  isLoading: false
}

export default function loaderReducer (state = initialState, action: TLoaderActions): ILoaderState {
  switch (action.type) {
    case LoaderActionTypes.SHOW_LOADER:
      return { isLoading: true }

    case LoaderActionTypes.CLOSE_LOADER:
      return { isLoading: false }

    default:
      return state
  }
}
