import cn from 'classnames'
import React, { FC } from 'react'

import { IEllipsisProps as IProps } from './types'

import styles from './Ellipsis.module.css'

export const Ellipsis: FC<IProps> = ({ children, classes }) => (
  <span className={cn(styles.content, 'd-block', classes)}>{children}</span>
)
