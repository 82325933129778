import { connect } from 'react-redux'

import QuestionButton from './QuestionButton'

import { resetDocumentMessage } from '@store/modules/document/actions'
import { closeModal, showModal } from '@store/modules/modal/actions'

import { IApplicationState } from '@store/types/common'
import {
  IQuestionButtonPropsFromDispatch as IPropsFromDispatch,
  IQuestionButtonPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  modalIdfromState: state.modal.id
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onCloseModal: () => dispatch(closeModal),
  onResetDocumentMessage: () => dispatch(resetDocumentMessage),
  onShowModal: (modalId: string) => dispatch(showModal(modalId))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuestionButton)
