import cn from 'classnames'
import React, { FC } from 'react'

import { ReactComponent as SkeletonSomeTextSVG } from './some-text.svg'

import { ISkeletonSomeTextProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonSomeText: FC<IProps> = ({ classes }) => (
  <div className={cn('skeleton skeleton--some-text', classes)}>
    <SkeletonSomeTextSVG />
  </div>
)
