import cn from 'classnames'
import React, { FC } from 'react'

import { isPlatformIOS } from '@utils/device'

import { HeaderType } from '@consts/enumTypes'

import { IHeaderProps as IProps } from './types'

const getClasses = (type = HeaderType.PAGE): string =>
  cn(
    'header fixed at-0 d-flex align-items-center width-full',
    isPlatformIOS() ? 'header-height-ios' : 'header-height',
    type === HeaderType.PAGE && 'header-shadow'
  )

export const Header: FC<IProps> = ({ children, type }) => <header className={getClasses(type)}>{children}</header>
