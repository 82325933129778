import React, { FC } from 'react'

import { ReactComponent as SkeletonDocumentTitleSVG } from './doc-title.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonDocumentTitle: FC = () => (
  <div className='skeleton skeleton--doc-title mx-4 mb-2'>
    <SkeletonDocumentTitleSVG />
  </div>
)
