import { connect } from 'react-redux'

import Page404 from './Page404'

import { resetError404 } from '@store/modules/utils/actions'

import { IApplicationState } from '@store/types/common'
import { IPage404PropsFromDispatch as IPropsFromDispatch, IPage404PropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  mainNavItemRoute: state.navigation.navItems[0].route
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onResetError404: () => dispatch(resetError404)
})

export default connect(mapStateToProps, mapDispatchToProps)(Page404)
