import React, { FC } from 'react'

import { PinCodeLabels } from '@consts/common'

import { LabelCircle } from '@common/LabelCircle'

import '@common/skeletons/Skeletons.scss'

export const SkeletonPinCodeLabel: FC = () => {
  return (
    <div className='skeleton skeleton--pin-code-labels'>
      <div className='d-flex justify-content-center'>
        {PinCodeLabels.map((label) => (
          <LabelCircle key={label} classes='m-3 label-circle--opacity' />
        ))}
      </div>
    </div>
  )
}
