import { isNotMobileDevice } from '@consts/common'
import { DevicePlatform } from '@consts/enumTypes'

const $window = window as any

const getIsTargetPlatform = (platform: DevicePlatform): boolean =>
  !isNotMobileDevice ? $window.device.platform.toLocaleLowerCase() === platform : false

export const isPlatformAndroid = (): boolean => getIsTargetPlatform(DevicePlatform.ANDROID)

export const isPlatformIOS = (): boolean => getIsTargetPlatform(DevicePlatform.IOS)
