import { MessagesTab } from '@consts/enumTypes'

import { sortMessagesByTime } from '@utils/utils'

import { Nullable } from '@types'
import { IMessage, IMessagesState, MessagesActionTypes, TMessagesActions } from './types'

export const initialState: IMessagesState = {
  activeTab: MessagesTab.NOT_COMPLETED,
  message: null,
  [MessagesTab.COMPLETED]: {
    messages: [],
    pagination: null
  },
  [MessagesTab.NOT_COMPLETED]: {
    messages: [],
    pagination: null
  }
}

export default function messagesReducer (state = initialState, action: TMessagesActions): IMessagesState {
  switch (action.type) {
    case MessagesActionTypes.MARK_MESSAGE_AS_READ: {
      const messages = state[MessagesTab.NOT_COMPLETED].messages

      const message = messages.find((message: IMessage) => message.task.oguid === action.payload)

      if (message) {
        message.task.isRead = true
      }

      return {
        ...state,
        [MessagesTab.NOT_COMPLETED]: {
          ...state[MessagesTab.NOT_COMPLETED],
          messages
        }
      }
    }

    case MessagesActionTypes.RESET_MESSAGE:
      return {
        ...state,
        message: null
      }

    case MessagesActionTypes.RESET_MESSAGES:
      return { ...initialState }

    case MessagesActionTypes.RESET_MESSAGES_TAB:
      return {
        ...state,
        activeTab: initialState.activeTab
      }

    case MessagesActionTypes.RESET_MESSAGES_BY_TAB:
      return state.activeTab === MessagesTab.COMPLETED
        ? {
            ...state,
            [MessagesTab.COMPLETED]: { ...initialState[MessagesTab.COMPLETED] }
          }
        : {
            ...state,
            [MessagesTab.NOT_COMPLETED]: { ...initialState[MessagesTab.NOT_COMPLETED] }
          }

    case MessagesActionTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload
      }

    case MessagesActionTypes.SET_MESSAGES:
      return state.activeTab === MessagesTab.COMPLETED
        ? {
            ...state,
            [MessagesTab.COMPLETED]: {
              messages: [...state[MessagesTab.COMPLETED].messages, ...action.payload.messages],
              pagination: action.payload.pagination
            }
          }
        : {
            ...state,
            [MessagesTab.NOT_COMPLETED]: {
              messages: [...state[MessagesTab.NOT_COMPLETED].messages, ...action.payload.messages],
              pagination: action.payload.pagination
            }
          }

    case MessagesActionTypes.SET_ACTIVE_TAB_TO_MESSAGES:
      return {
        ...state,
        activeTab: action.payload
      }

    case MessagesActionTypes.TRANSFER_MESSAGE_TO_CLOSE: {
      let transferedMessage: Nullable<IMessage> = null

      const newOpenMessages = state[MessagesTab.NOT_COMPLETED].messages.reduce(
        (accumulator: IMessage[], currentValue: IMessage) => {
          if (currentValue.task.oguid !== action.payload) {
            return [...accumulator, currentValue]
          } else {
            transferedMessage = currentValue
            transferedMessage.task.isRead = true

            return [...accumulator]
          }
        },
        []
      )

      let newClosedMessages: IMessage[] = state[MessagesTab.COMPLETED].messages

      if (transferedMessage && state[MessagesTab.COMPLETED].pagination) {
        newClosedMessages = [transferedMessage, ...state[MessagesTab.COMPLETED].messages].sort(sortMessagesByTime)
      }

      return {
        ...state,
        [MessagesTab.COMPLETED]: {
          ...state[MessagesTab.COMPLETED],
          messages: newClosedMessages
        },
        [MessagesTab.NOT_COMPLETED]: {
          ...state[MessagesTab.NOT_COMPLETED],
          messages: newOpenMessages
        }
      }
    }

    default:
      return state
  }
}
