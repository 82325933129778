import { connect } from 'react-redux'

import Startup from './Startup'

import { getBadges } from '@store/modules/navigation/actions'
import {
  getDocumentTypes,
  getFlowStageTypes,
  getHistoryStateCodes,
  getWorkflowStatuses
} from '@store/modules/metadata/actions'
import { logout } from '@store/modules/root/actions'
import { setLoaded } from '@store/modules/startup/actions'
import { getUserExtendedInfo, getUserInfo, userActions } from '@store/modules/user/actions'

import { IApplicationState } from '@store/types/common'

import { IStartupPropsFromDispatch as IPropsFromDispatch, IStartupPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  accessToken: state.user.accessToken,
  isAuthenticated: state.user.isAuthenticated,
  isLoaded: state.app.isLoaded,
  language: state.user.user.locale,
  orgOguid: state.user.activeOrg.oguid,
  userEmail: state.user.user.email,
  userLogin: state.user.user.login,
  userOguid: state.user.user.oguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetBadges: () => dispatch(getBadges()),
  onGetDocumentTypes: () => dispatch(getDocumentTypes()),
  onGetFlowStageTypes: () => dispatch(getFlowStageTypes()),
  onGetHistoryStateCodes: () => dispatch(getHistoryStateCodes()),
  onGetUserExtendedInfo: () => dispatch(getUserExtendedInfo()),
  onGetUserInfo: () => dispatch(getUserInfo()),
  onGetWorkflowStatuses: () => dispatch(getWorkflowStatuses()),
  onLogout: () => dispatch(logout()),
  onSetAccessToken: (token: string) => dispatch(userActions.setAccessToken(token)),
  onSetLoaded: () => dispatch(setLoaded())
})

export default connect(mapStateToProps, mapDispatchToProps)(Startup)
