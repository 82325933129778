import React, { FC, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, RouteComponentProps } from 'react-router-dom'

import { getAvailableOrganization } from '@utils/utils'

import RedirectToSigninPage from '@containers/routing/RedirectToSigninPage'

import { userActions } from '@store/modules/user/actions'

import { IApplicationState, TAllServiceParams } from '@store/types/common'
import { IRouteMainParams as IProps } from '@containers/routing/RouteWithSubRoutes/types'

const Authentication: FC<IProps> = ({ component: ComposedComponent, ...rest }) => {
  const dispatch = useDispatch()
  const {
    activeOrg: { alias, oguid },
    isAuthenticated
  } = useSelector((state: IApplicationState) => state.user)

  const handleRender = (renderProps: RouteComponentProps<TAllServiceParams>): ReactElement => {
    const {
      match: { params }
    } = renderProps

    if (!isAuthenticated) return <RedirectToSigninPage />

    if (params.org !== oguid && params.org !== alias) {
      const availableOrganization = getAvailableOrganization(params)

      if (availableOrganization) dispatch(userActions.setActiveOrganization(availableOrganization))
    }

    return <ComposedComponent {...renderProps} />
  }

  return <Route {...rest} render={handleRender as any} />
}

export default Authentication
