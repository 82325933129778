import { AuthByDeviceRequest, AuthDeviceSensor, LocalStorage, FaceIdActivation } from '@consts/enumTypes'

import { Nullable } from '@types'

// Установка значений параметров
export const setActiveOrgToStorage = (orgOguid: string): void =>
  window.localStorage.setItem(LocalStorage.ACTIVE_ORGANIZATION, orgOguid)

export const setAuthByDeviceRequestToStorage = (value = AuthByDeviceRequest.NO): void =>
  window.localStorage.setItem(LocalStorage.AUTH_BY_DEVICE_REQUEST, value)

export const setAuthErrorToStorage = (authError: string): void =>
  window.localStorage.setItem(LocalStorage.AUTH_ERROR, authError)

export const setAuthUserEmailToStorage = (userEmail: string): void =>
  window.localStorage.setItem(LocalStorage.AUTH_USER_EMAIL, userEmail)

export const setAuthUserPasswordToStorage = (password: string): void =>
  window.localStorage.setItem(LocalStorage.AUTH_USER_PASSWORD, password)

export const setFaceIdIsActiveToStorage = (value = FaceIdActivation.ON): void =>
  window.localStorage.setItem(LocalStorage.FACEID_IS_ACTIVE, value)

export const setFaceIdSensorToStorage = (): void =>
  window.localStorage.setItem(LocalStorage.FACEID_SENSOR, AuthDeviceSensor.YES)

export const setFingerprintSensorToStorage = (): void =>
  window.localStorage.setItem(LocalStorage.FINGERPRINT_SENSOR, AuthDeviceSensor.YES)

export const setFingerprintTokenToStorage = (token: string): void =>
  window.localStorage.setItem(LocalStorage.FINGERPRINT_TOKEN, token)

export const setLanguageToStorage = (language: string): void =>
  window.localStorage.setItem(LocalStorage.LANGUAGE, language)

export const setPinCodeToStorage = (pinCode: string): void =>
  window.localStorage.setItem(LocalStorage.PIN_CODE, pinCode)

export const setPushNotificationId = (id: string): void =>
  window.localStorage.setItem(LocalStorage.PUSH_NOTIFICATION_ID, id)

export const setPrevPushNotificationsTokenToStorage = (token: string): void =>
  window.localStorage.setItem(LocalStorage.PREV_PUSH_NOTIFICATIONS_TOKEN, token)

export const setPushNotificationsTokenToStorage = (token: string): void =>
  window.localStorage.setItem(LocalStorage.PUSH_NOTIFICATIONS_TOKEN, token)

export const setPushNotificationsUserEmailToStorage = (userEmail: string): void =>
  window.localStorage.setItem(LocalStorage.PUSH_NOTIFICATIONS_USER_EMAIL, userEmail)

// Очистка/сброс значений параметров
const clearAuthDataToStorageMobile = (): void => {
  setAuthUserEmailToStorage('')
  setAuthUserPasswordToStorage('')

  setPinCodeToStorage('')

  setAuthByDeviceRequestToStorage(AuthByDeviceRequest.YES)
}

export const clearAuthDataToStorageAndroid = (): void => {
  clearAuthDataToStorageMobile()

  setFingerprintTokenToStorage('')
}

export const clearAuthDataToStorageIOS = (): void => {
  clearAuthDataToStorageMobile()

  setFaceIdIsActiveToStorage(FaceIdActivation.OFF)
}

// Получение значений параметров
export const getActiveOrgOguidFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.ACTIVE_ORGANIZATION)

export const getAuthByDeviceRequestFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.AUTH_BY_DEVICE_REQUEST)

export const getAuthErrorFromStorage = (): Nullable<string> => window.localStorage.getItem(LocalStorage.AUTH_ERROR)

export const getAuthUserEmailFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.AUTH_USER_EMAIL)

export const getAuthUserPasswordFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.AUTH_USER_PASSWORD)

export const getFaceIdIsActiveFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.FACEID_IS_ACTIVE)

export const getFaceIdSensorFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.FACEID_SENSOR)

export const getFingerprintSensorFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.FINGERPRINT_SENSOR)

export const getFingerprintTokenFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.FINGERPRINT_TOKEN)

export const getLanguageFromStorage = (): Nullable<string> => window.localStorage.getItem(LocalStorage.LANGUAGE)

export const getPinCodeFromStorage = (): Nullable<string> => window.localStorage.getItem(LocalStorage.PIN_CODE)

export const getPrevPushNotificationsTokenFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.PREV_PUSH_NOTIFICATIONS_TOKEN)

export const getPushNotificationIdFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.PUSH_NOTIFICATION_ID)

export const getPushNotificationsTokenFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.PUSH_NOTIFICATIONS_TOKEN)

export const getPushNotificationsUserEmailFromStorage = (): Nullable<string> =>
  window.localStorage.getItem(LocalStorage.PUSH_NOTIFICATIONS_USER_EMAIL)
