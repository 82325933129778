import React, { FC } from 'react'
import { AxiosResponse } from 'axios'
import { detect } from 'detect-browser'
import { useSelector } from 'react-redux'
import FileSaver from 'file-saver'

import documentService from '@services/document'

import { Browser, Character, Duration, Extension, isNotMobileDevice, Timeout } from '@consts/common'

import { downloadFileOnDevice } from '@utils/files'
import { debounce } from '@utils/utils'

import { Button } from '@common/Button'
import { IconSave } from '@common/icons'
import { showErrorNotification } from '@common/Notifications'
import { RippleEffect } from '@common/RippleEffect'

import { IApplicationState } from '@store/types/common'

const isMobileSafari = detect()?.name === Browser.IOS

export const DownloadButton: FC = () => {
  const {
    document: {
      file: { data, isAvailable },
      document,
      title
    }
  } = useSelector((state: IApplicationState) => state)

  const handleDownloadDocumentFile = debounce(() => {
    documentService
      .downloadDocumentFile(document?.oguid ?? '')
      .then(({ data }: AxiosResponse<Blob>) => {
        if (data) {
          const fileName = title + Character.DOT + Extension.PDF

          if (isNotMobileDevice && !isMobileSafari) window.open(URL.createObjectURL(data))

          if (isNotMobileDevice && isMobileSafari) FileSaver.saveAs(data, fileName)

          if (!isNotMobileDevice) downloadFileOnDevice(data, fileName)
        }
      })
      .catch(showErrorNotification)
  }, Timeout.RIPPLE_EFFECT_ICON_BUTTON)

  return isAvailable && data.length ? (
    <Button classes='relative ml-auto p-4' onClick={handleDownloadDocumentFile}>
      <IconSave />
      <RippleEffect duration={Duration.RIPPLE_EFFECT_ICON_BUTTON} />
    </Button>
  ) : null
}
