import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as SignatureDeclineIconSVG } from './signature-decline-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconSignatureDecline: FC<IProps> = ({ color = 'danger-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <SignatureDeclineIconSVG />
  </Icon>
)
