import React, { FC } from 'react'

import { ContentType } from '@consts/enumTypes'

import { Content } from '@common/Content'
import { SkeletonMessageListItem } from '@common/skeletons/components'

// Todo подумать как вычислять количество скелетонов
export const SkeletonMessages: FC = () => (
  <Content classes='pt-4 px-4 bg-muted-200' type={ContentType.WITH_TABS}>
    <SkeletonMessageListItem />
    <SkeletonMessageListItem />
    <SkeletonMessageListItem />
    <SkeletonMessageListItem />
    <SkeletonMessageListItem />
    <SkeletonMessageListItem />
  </Content>
)
