import { NotificationType } from '@consts/enumTypes'

import StoreNotifications from '../components/StoreNotifications'

import { INotificationOptions } from '../types'

export const showSuccessNotification = (options: INotificationOptions): void => {
  StoreNotifications.add({
    id: '',
    options,
    type: NotificationType.SUCCESS
  })
}
