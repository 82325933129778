import { TaskTab } from '@consts/enumTypes'
import { Nullable } from '@types'
import { ITask, ITasksState, TasksActionTypes, TTasksActions } from './types'

export const initialState: ITasksState = {
  activeTab: TaskTab.NOT_COMPLETED,
  [TaskTab.COMPLETED]: {
    tasks: [],
    pagination: null
  },
  [TaskTab.NOT_COMPLETED]: {
    tasks: [],
    pagination: null
  }
}

export default function tasksReducer (state = initialState, action: TTasksActions): ITasksState {
  switch (action.type) {
    case TasksActionTypes.COMPLETE_TASK: {
      const { completedTimestamp, result, state: taskState, taskOguid } = action.payload

      let completedTask: Nullable<ITask> = null

      const newNotCompletedTasks = state[TaskTab.NOT_COMPLETED].tasks.reduce(
        (accumulator: ITask[], currentValue: ITask) => {
          if (currentValue.task.oguid !== taskOguid) {
            return [...accumulator, currentValue]
          } else {
            completedTask = currentValue
            completedTask.task.completedTimestamp = completedTimestamp
            completedTask.task.result = result
            completedTask.task.state = taskState

            return [...accumulator]
          }
        },
        []
      )

      let newCompletedTasks: ITask[] = state[TaskTab.COMPLETED].tasks

      if (completedTask && state[TaskTab.COMPLETED].pagination) {
        newCompletedTasks = [completedTask, ...state[TaskTab.COMPLETED].tasks]
      }

      return {
        ...state,
        [TaskTab.COMPLETED]: {
          ...state[TaskTab.COMPLETED],
          tasks: newCompletedTasks
        },
        [TaskTab.NOT_COMPLETED]: {
          ...state[TaskTab.NOT_COMPLETED],
          tasks: newNotCompletedTasks
        }
      }
    }

    case TasksActionTypes.RESET_TASKS:
      return { ...initialState }

    case TasksActionTypes.RESET_TASKS_BY_TAB:
      return state.activeTab === TaskTab.COMPLETED
        ? {
            ...state,
            [TaskTab.COMPLETED]: { ...initialState[TaskTab.COMPLETED] }
          }
        : {
            ...state,
            [TaskTab.NOT_COMPLETED]: { ...initialState[TaskTab.NOT_COMPLETED] }
          }

    case TasksActionTypes.SET_TASKS:
      return state.activeTab === TaskTab.COMPLETED
        ? {
            ...state,
            [TaskTab.COMPLETED]: {
              tasks: [...state[TaskTab.COMPLETED].tasks, ...action.payload.tasks],
              pagination: action.payload.pagination
            }
          }
        : {
            ...state,
            [TaskTab.NOT_COMPLETED]: {
              tasks: [...state[TaskTab.NOT_COMPLETED].tasks, ...action.payload.tasks],
              pagination: action.payload.pagination
            }
          }

    case TasksActionTypes.SET_ACTIVE_TAB_TO_TASKS:
      return {
        ...state,
        activeTab: action.payload
      }

    default:
      return state
  }
}
