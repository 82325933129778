import { IStartupState, StartupActionTypes, TStartupActions } from './types'

export const initialState: IStartupState = {
  isLoaded: false
}

export default function startupReducer (state = initialState, action: TStartupActions): IStartupState {
  switch (action.type) {
    case StartupActionTypes.RESET_STARTUP:
      return initialState

    case StartupActionTypes.SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }

    default:
      return state
  }
}
