import React, { FC } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { RouteName } from '@consts/navItems'

const RedirectToSigninPage: FC = () => {
  const {
    location: { pathname, search }
  } = useHistory()

  const currentLocation = `${pathname}${search}`

  return (
    <Redirect
      to={{
        pathname: RouteName.SIGN_IN,
        state: { from: currentLocation }
      }}
    />
  )
}

export default RedirectToSigninPage
