import React, { FC } from 'react'

import { ReactComponent as SkeletonUserActionSVG } from './user-action.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonUserAction: FC = () => (
  <div className='skeleton skeleton--user-action mb-2'>
    <SkeletonUserActionSVG />
  </div>
)
