import React, { ReactNode } from 'react'
import { cloneDeep } from 'lodash'
import { DateTime } from 'luxon'
import { FormikErrors, FormikTouched } from 'formik'
import { match } from 'react-router'
import { History, Location, LocationDescriptor } from 'history'
import { TFunction } from 'i18next'
import * as Sentry from '@sentry/browser'

import store from '@store/configureStore'

import {
  AllRoles,
  Character,
  CurrencyCharacter,
  DateFormat,
  DEVICE_PLATFORM_ID,
  isNotMobileDevice,
  PaginationProperty,
  PER_PAGE_ITEMS_DEFAULT,
  RoleModelRestrictionKeys,
  Space
} from '@consts/common'
import { AllowedAction, DevicePlatform, DocumentDirection, OpenedFromNotification } from '@consts/enumTypes'
import { NAV_DOCS, Path, RouteName } from '@consts/navItems'
import { privateRoutes } from '@consts/routes'

import { getAuthByDeviceRequestFromStorage } from '@utils/localStorage'

import { RouteWithSubRoutes } from '@containers/routing'

import { navigationActions } from '@store/modules/navigation/actions'

import { IClasses, INameInterface, INavItem, ISentryOptions, Nullable } from '@types'
import { INotificationValue } from '@common/Notifications/types'
import { IUserRoleRestrictions } from '@containers/navigation/NavigationWrapper/types'
import { TRoute } from '@containers/routing/RouteWithSubRoutes/types'
import { IPagination, IUserOrganization, TAllServiceParams } from '@store/types/common'
import { IDocumentMessage } from '@store/modules/document/types'
import { IDocumentField } from '@store/modules/documents/types'
import { IMessage } from '@store/modules/messages/types'

export const hasError = (name: string, errors?: FormikErrors<any>, touched?: FormikTouched<any>): boolean =>
  !!(errors?.[name] && touched?.[name])

/**
 * Для работы переводов функция должна быть в месте вызова обернута в t()
 */
export const getErrorText = (name: string, errors?: FormikErrors<any>): string =>
  errors?.hasOwnProperty(name) ? String(errors[name]) : ''

/**
 * Из-за функции переводов t() в тексте самого перевода нельзя использовать двоеточие
 */
export const getValidationTextAfterT = (value: string): string => value.replace(Character.DOT, Character.COLON)

export const getRoutesList = (routes: TRoute[]): ReactNode[] =>
  routes.map((route: TRoute) => <RouteWithSubRoutes key={route.name} {...route} path={route.path} />)

export const createUrl = (urlData: match<TAllServiceParams> | string, ...rest: Array<string | number>): string => {
  const org = typeof urlData === 'string' ? `/${urlData}` : getOrgDataFromMatch(urlData)

  return (
    org +
    rest.reduce((accumulator: string, currentValue: string | number) => {
      if (!currentValue) return accumulator

      const newCurrentValue =
        typeof currentValue !== 'number' && !currentValue.includes('/') && accumulator.slice(-1) !== '/'
          ? `/${currentValue}`
          : currentValue
      return `${accumulator}${newCurrentValue}`
    }, '')
  )
}

export const getOrgDataFromMatch = (matchData: match<TAllServiceParams>): string => {
  if (matchData?.params?.org) return `/${matchData.params.org}`

  return ''
}

export const getClasses = (classes?: IClasses | string): IClasses => {
  if (!classes) return {}

  if (typeof classes === 'string') return { wrapper: classes }

  return classes
}

export const modifyRoutes = (user: IUserRoleRestrictions): TRoute[] => {
  const newRoutes = filterAvailableRoutes(cloneDeep(privateRoutes), user)

  return addOrgInPath(newRoutes)
}

const filterAvailableRoutes = (routes: TRoute[], user: IUserRoleRestrictions): TRoute[] => {
  const { role } = user

  return routes.filter((route: TRoute) => {
    const isRoleCorrect = !!role && route.roles && route.roles.includes(role)

    if (!isRoleCorrect) return false

    const routeKeys = Object.keys(route)

    const routeRestrictionsKeys: string[] = []
    const notFulfilledRestrictions: string[] = []

    routeKeys.forEach((key: string) => {
      if (RoleModelRestrictionKeys.includes(key)) {
        routeRestrictionsKeys.push(key)

        if (route[key] !== user[key]) {
          notFulfilledRestrictions.push(key)
        }
      }
    })

    const isRouteWithoutRestrictions = !routeRestrictionsKeys.length

    if (isRouteWithoutRestrictions) return true

    return !notFulfilledRestrictions.length
  })
}

export const addOrgInPath = (routes: TRoute[]): TRoute[] =>
  routes.reduce((accumulator: TRoute[], currentValue: TRoute) => {
    const newRoute = {
      ...currentValue,
      path: `${RouteName.ORGANIZATION_URL}${currentValue.path}`
    }

    return [...accumulator, newRoute]
  }, [])

export const getOrganizationInfo = (alias: Nullable<string>, oguid: string): string => alias ?? oguid

export const createPath = (
  org: string,
  to: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>)
): string | any => {
  const orgOguid: string = '/' + org

  if (typeof to === 'string') return orgOguid + to
  if (typeof to === 'object' && to.pathname) return { ...to, pathname: `${orgOguid}${to.pathname}` }

  return to
}

export const transformPathToString = (
  to: LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>)
): string => {
  const newTo = ''

  if (typeof to === 'string') return newTo + to
  if (typeof to === 'object' && to.pathname) return to.pathname

  return newTo
}

export const removeUnavailableNavItems = (navItems: INavItem[], user: IUserRoleRestrictions): INavItem[] =>
  filterAvailableNavItems(cloneDeep(navItems), user)

const filterAvailableNavItems = (navItems: INavItem[], user: IUserRoleRestrictions): INavItem[] => {
  const { role } = user

  return navItems.filter((navItem: INavItem) => {
    const isRoleCorrect = !!role && navItem.roles.includes(role)

    if (!isRoleCorrect) return false

    if (navItem.items?.length) {
      navItem.items = filterAvailableNavItems(navItem.items, user)
    }

    const navItemKeys = Object.keys(navItem)

    const navItemRestrictionsKeys: string[] = []
    const notFulfilledRestrictions: string[] = []

    navItemKeys.forEach((key: string) => {
      if (RoleModelRestrictionKeys.includes(key)) {
        navItemRestrictionsKeys.push(key)

        if (navItem[key] !== user[key]) {
          notFulfilledRestrictions.push(key)
        }
      }
    })

    const isNavItemWithoutRestrictions = !navItemRestrictionsKeys.length

    if (isNavItemWithoutRestrictions) return true

    return !notFulfilledRestrictions.length
  })
}

export const getPagination = (headers: any): Nullable<IPagination> => {
  if (
    !headers.hasOwnProperty(PaginationProperty.PER) &&
    !headers.hasOwnProperty(PaginationProperty.NEXT) &&
    !headers.hasOwnProperty(PaginationProperty.PAGE) &&
    !headers.hasOwnProperty(PaginationProperty.PREV) &&
    !headers.hasOwnProperty(PaginationProperty.TOTAL)
  ) {
    return null
  }

  return {
    itemsPerPage: headers?.[PaginationProperty.PER] ?? PER_PAGE_ITEMS_DEFAULT,
    nextPage: headers?.[PaginationProperty.NEXT] ?? null,
    pageIndex: headers?.[PaginationProperty.PAGE] !== '-1' ? +headers[PaginationProperty.PAGE] : 1,
    prevPage: headers?.[PaginationProperty.PREV] ?? null,
    total: headers?.[PaginationProperty.TOTAL] ?? null
  }
}

export function getSurnameWithInitials (data: INameInterface): string {
  let result = data.surname ? `${data.surname} ` : ''

  if (data.name) {
    result = result.length > 0 ? `${result}${data.name[0].toUpperCase()}.` : `${data.name} `

    if (data.patronymic) {
      result = result ? `${result}${data.patronymic[0].toUpperCase()}.` : result
    }
  }

  return result.length ? result.trim() : result
}

export function getSurnameWithFullName (data: INameInterface): string {
  let result = data.surname ? `${data.surname} ` : ''

  if (data.name) {
    result = result.length > 0 ? `${result}${data.name} ` : `${data.name} `

    if (data.patronymic) {
      result = result ? `${result}${data.patronymic}` : result
    }
  }

  return result.length ? result.trim() : result
}

export const getCurrencyCharacter = (currency: Nullable<IDocumentField>): string => {
  const stringCurrency = currency ? String(currency) : ''
  const currencyChar: string = CurrencyCharacter[stringCurrency]

  return `${Space.NON_BREAK}${currencyChar !== undefined ? currencyChar : stringCurrency}`
}

export const getNestedMessages = (messages: IDocumentMessage[]): IDocumentMessage[] =>
  messages.reduce(
    (result: IDocumentMessage[], message: IDocumentMessage) => [
      ...result,
      message,
      ...getNestedMessages(message.todos)
    ],
    []
  )

export const getFormattedDate = (date: number, format: string): string => DateTime.fromMillis(date).toFormat(format)

export const getFormattedDateValue = (field: IDocumentField): string =>
  typeof field === 'number' ? getFormattedDate(field, DateFormat.DATE_FULL_YEAR) : ''

export const getFormattedDatetimeValue = (field: IDocumentField): string =>
  typeof field === 'number' ? getFormattedDate(field, DateFormat.DATETIME_FULL_YEAR) : ''

export const debounce = (func: any, wait: number): any => {
  let timeout: any = null

  return (...args: any[]) => {
    const next = (): any => func(...args)

    clearTimeout(timeout)
    timeout = setTimeout(next, wait)
  }
}

// ToDo https://3.basecamp.com/4382260/buckets/21311268/todos/3785182913
export const getCopiedText = (messageDescription?: Nullable<string>): void => {
  if (!isNotMobileDevice && DEVICE_PLATFORM_ID !== DevicePlatform.BROWSER) {
    messageDescription && (window as any).cordova.plugins.clipboard.copy(messageDescription)
  } else {
    messageDescription && navigator.clipboard.writeText(messageDescription)
  }
}

/**
 * Упорядочивание доступных действий согласно шаблону
 * @param templateActions шаблон, по которому выстраивается порядок действий
 * @param sortingActions упорядочиваемые действия
 */
export const sortActions = (templateActions: AllowedAction[]): AllowedAction[] => {
  const sortedActions: AllowedAction[] = []

  const document = store.getState().document.document

  if (!document) return []

  const { allowedActions, direction, isExternal } = document
  const { role, isFlowFunctionalityEnabled } = store.getState().user

  const isAccessAllowed = AllRoles.includes(role) && isFlowFunctionalityEnabled
  const isOutgoing = direction === DocumentDirection.OUTGOING

  templateActions.forEach((action: AllowedAction) => {
    const isDeclineSignOutgoing = isExternal && isOutgoing && action === AllowedAction.DECLINE_SIGN
    const isNotAccessStart = action === AllowedAction.START_PROCESS && !isAccessAllowed

    if ((allowedActions.includes(action) && !isDeclineSignOutgoing) || isNotAccessStart) {
      sortedActions.push(action)
    }
  })

  return sortedActions
}

export const sendErrorToSentry = (options: ISentryOptions): void => {
  const {
    activeOrg: { name, oguid },
    user
  } = store.getState().user

  const errorInfo = {
    ...options.info,
    organizationName: name,
    organizationOguid: oguid,
    userOguid: user.oguid
  }

  Sentry.withScope((scope: Sentry.Scope) => {
    scope.setLevel(Sentry.Severity.Fatal)
    scope.setExtras(errorInfo)
    Sentry.captureException(new Error(options.message))
  })
}

export const getIsAnotherValue = (prevValue: Nullable<string>, newValue: Nullable<string>): boolean =>
  !!(prevValue && newValue && newValue !== prevValue)

export const getAvailableOrganization = (params: TAllServiceParams): IUserOrganization | undefined => {
  const { orgs } = store.getState().user.user

  return (
    orgs.find((organization) => organization.alias === params.org) ??
    orgs.find((organization) => organization.oguid === params.org)
  )
}

export const setStartPage = (history: History): void => {
  const {
    activeOrg: { alias, oguid },
    isExchangeParticipant,
    isFlowFunctionalityEnabled,
    isReadonly,
    role
  } = store.getState().user

  const availableNavItems = removeUnavailableNavItems(NAV_DOCS, {
    isExchangeParticipant,
    isFlowFunctionalityEnabled,
    isReadonly,
    role
  })

  if (availableNavItems.length) {
    const route = availableNavItems[0].route

    if ((alias || oguid) && route) {
      store.dispatch(navigationActions.setNavItems(availableNavItems))

      if (!isNotMobileDevice) {
        !getAuthByDeviceRequestFromStorage()
          ? history.push(Path.SET_PIN_CODE)
          : history.push(createUrl(alias || oguid, route))
      } else history.push(createUrl(alias || oguid, route))
    }
  }
}

export const navigateBackPage = (history: History): void => {
  const {
    modal,
    navigation: { navItems, openedFromNotification, prevNavRoute },
    user: {
      activeOrg: { oguid },
      prevActiveOrgOguid
    }
  } = store.getState()

  const goToMain = (): void => {
    store.dispatch(navigationActions.setOpenedFromNotification(null))

    history.push(createPath(oguid, navItems[0].route))
  }

  const goToMainAndResetPrevNavRoute = (): void => {
    store.dispatch(navigationActions.setPrevNavRoute(null))

    history.push(createPath(oguid, navItems[0].route))
  }

  if (!modal.id) {
    prevActiveOrgOguid
      ? openedFromNotification && openedFromNotification === OpenedFromNotification.PUSH
        ? goToMain()
        : history.push(createPath(oguid, navItems[0].route))
      : prevNavRoute
      ? goToMainAndResetPrevNavRoute()
      : history.goBack()
  }
}

export const getNotificationValueByT = (value: string | INotificationValue, t: TFunction): string =>
  typeof value === 'string' ? t(value) : t(value?.key, value?.options)

export const removeLastChar = (value: string): string => value.substring(0, value.length - 1)

export const sortMessagesByTime = (
  { task: { startedTimestamp: timeFromFirstMessage } }: IMessage,
  { task: { startedTimestamp: timeFromNextMessage } }: IMessage
): number => {
  if (timeFromFirstMessage < timeFromNextMessage) return 1

  if (timeFromFirstMessage > timeFromNextMessage) return -1

  return 0
}
