import React, { FC } from 'react'

import { Content } from '@common/Content'
import { SkeletonSettingsBlockButton } from '@common/skeletons/components'

import '@common/skeletons/Skeletons.scss'

export const SkeletonSettings: FC = () => (
  <Content>
    <SkeletonSettingsBlockButton />
    <SkeletonSettingsBlockButton />
    <SkeletonSettingsBlockButton />
    <SkeletonSettingsBlockButton />
  </Content>
)
