import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as OutgoingIconSVG } from './outgoing-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconOutgoing: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <OutgoingIconSVG />
  </Icon>
)
