import { DocumentsActionTypes, IDocumentsState, TDocumentsActions } from './types'

export const initialState: IDocumentsState = {
  documents: [],
  pagination: null
}

export default function documentsReducer (
  state: IDocumentsState = initialState,
  action: TDocumentsActions
): IDocumentsState {
  switch (action.type) {
    case DocumentsActionTypes.RESET_DOCUMENTS:
      return {
        ...initialState
      }

    case DocumentsActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: [...state.documents, ...action.payload.documents],
        pagination: action.payload.pagination
      }

    case DocumentsActionTypes.UPDATE_DOCUMENT: {
      const updatedDocuments = state.documents.map((document) => {
        if (document.oguid === action.payload.oguid) {
          document = action.payload
        }

        return document
      })

      return {
        ...state,
        documents: updatedDocuments
      }
    }

    default:
      return state
  }
}
