import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { DocumentActionIcon, DocumentActionTheme } from '@consts/common'
import { AccessTaskType, AllowedAction, CompleteStatus } from '@consts/enumTypes'

import {
  completeTaskAction,
  confirmDocumentAction,
  getAccessTaskTypeToApprove,
  getCaptionForDocumentAction,
  showUnsupportedActionNotification,
  signInternalDocumentAction,
  startProcessAction
} from './utils'

import { NavigationItem } from '@common/NavigationItem'
import CustomNavButton from '../CustomNavButton'

import { IDocumentActionsProps as IProps } from './types'
import { useSelector } from 'react-redux'
import { IApplicationState } from '@store/types/common'

const DocumentActions: FC<IProps> = ({ actions }) => {
  const { document } = useSelector((state: IApplicationState) => state.document)

  if (!document) return null

  const { t } = useTranslation()
  const { flowStageType, isExternal } = document

  const DocumentActionHandler = {
    [AllowedAction.START_PROCESS]: (title: string) => confirmDocumentAction(title, () => startProcessAction(t)),

    [AllowedAction.APPROVE]: (title: string) =>
      confirmDocumentAction(title, () =>
        completeTaskAction(t, CompleteStatus.SOLVED, getAccessTaskTypeToApprove(flowStageType))
      ),

    [AllowedAction.DECLINE_APPROVE]: (title: string) =>
      confirmDocumentAction(title, () =>
        completeTaskAction(t, CompleteStatus.DECLINED, getAccessTaskTypeToApprove(flowStageType))
      ),

    [AllowedAction.DECLINE_SIGN]: isExternal
      ? showUnsupportedActionNotification
      : (title: string) =>
          confirmDocumentAction(title, () => completeTaskAction(t, CompleteStatus.DECLINED, AccessTaskType.SIGNING)),

    [AllowedAction.SIGN]: isExternal
      ? showUnsupportedActionNotification
      : (title: string) => confirmDocumentAction(title, () => signInternalDocumentAction(t, AccessTaskType.SIGNING))
  }

  const actionButtons = actions.map((action) => {
    const title = t(getCaptionForDocumentAction(action, flowStageType ?? '', t))

    return {
      key: uuidv4(),
      icon: DocumentActionIcon[action],
      onClick: DocumentActionHandler[action]
        ? () => DocumentActionHandler[action](title)
        : showUnsupportedActionNotification,
      theme: DocumentActionTheme[action],
      title
    }
  })

  return (
    <>
      {actionButtons.map(({ icon, key, onClick, theme, title }) => (
        <NavigationItem
          key={key}
          customNavItem={<CustomNavButton icon={icon} onClick={onClick} theme={theme} title={title} />}
          uuid={key}
        />
      ))}
    </>
  )
}

export default DocumentActions
