import { AxiosResponse, AxiosRequestConfig } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { ITransferMessageToClose, IMessage, IMessageBody, IMessageTask } from '@store/modules/messages/types'
import { IBadges } from '@store/modules/navigation/types'

class MessagesService extends AbstractHttpService {
  async addMessage (documentOguid: string, data: IMessageBody): Promise<AxiosResponse<IMessageTask>> {
    const url = urls.messages.new.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<IMessageTask> = await this._http.post(url, data)

    return response
  }

  async getMessages (config: AxiosRequestConfig): Promise<AxiosResponse<IMessage[]>> {
    const url = urls.messages.list

    const response: AxiosResponse<IMessage[]> = await this._http.get(url, config)

    return response
  }

  async markMessageAsRead (taskOguid: string): Promise<AxiosResponse<any>> {
    const url = urls.messages.markAsRead.replace('{{ taskOguid }}', taskOguid)

    const response: AxiosResponse<any> = await this._http.post(url, {})

    return response
  }

  async transferMessageToClose (taskOguid: string, data: ITransferMessageToClose): Promise<AxiosResponse<IBadges>> {
    const url = urls.messages.transferToClose.replace('{{ taskOguid }}', taskOguid)

    const response: AxiosResponse<IBadges> = await this._http.post(url, data)

    return response
  }
}

export default new MessagesService()
