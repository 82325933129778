import cn from 'classnames'
import React, { FC } from 'react'

import { SkeletonSize } from '@consts/enumTypes'

import { SkeletonDateSVG, SkeletonTimeSVG } from './svg'

import { ISkeletonDatetimeProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonDatetime: FC<IProps> = ({ size = SkeletonSize.BASE }) => (
  <span className='d-flex align-items-center ml-auto'>
    <span className={cn('skeleton mr-1', `skeleton--date-${size}`)}>
      <SkeletonDateSVG />
    </span>
    <span className={cn('skeleton', `skeleton--time-${size}`)}>
      <SkeletonTimeSVG />
    </span>
  </span>
)
