import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import documentsService from '@services/documents'

import { ResponseCode } from '@consts/common'

import { getPagination } from '@utils/utils'

import { IParams } from '@store/types/common'
import {
  DocumentsActionKey,
  DocumentsActions,
  DocumentsActionTypes,
  DocumentsThunkAction,
  DocumentsThunkDispatch,
  IActionResetDocuments,
  IActionSetDocuments,
  IActionUpdateDocument,
  IDocument,
  IDocumentsAndPagination
} from './types'

// actions
export const resetDocuments: IActionResetDocuments = {
  type: DocumentsActionTypes.RESET_DOCUMENTS
}

const setDocumentsAction: ActionCreator<Action> = (payload: IDocumentsAndPagination): IActionSetDocuments => ({
  payload,
  type: DocumentsActionTypes.SET_DOCUMENTS
})
const updateDocumentAction: ActionCreator<Action> = (payload: IDocument): IActionUpdateDocument => ({
  payload,
  type: DocumentsActionTypes.UPDATE_DOCUMENT
})

export const documentsActions: DocumentsActions = {
  [DocumentsActionKey.SET_DOCUMENTS]: setDocumentsAction,
  [DocumentsActionKey.UPDATE_DOCUMENT]: updateDocumentAction
}

// thunks
export const getDocuments: ActionCreator<DocumentsThunkAction> = (params: IParams) => (
  dispatch: DocumentsThunkDispatch
): Promise<AxiosResponse<IDocument[]>> =>
  documentsService.getDocuments({ params }).then((resp: AxiosResponse<IDocument[]>) => {
    if (resp.status === ResponseCode.GET) {
      const payload: IDocumentsAndPagination = {
        documents: resp.data,
        pagination: getPagination(resp.headers)
      }

      dispatch(documentsActions[DocumentsActionKey.SET_DOCUMENTS](payload))
    }

    return resp
  })
