import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as MenuIconSVG } from './menu-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconMenu: FC<IProps> = ({ color = 'text-color', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <MenuIconSVG />
  </Icon>
)
