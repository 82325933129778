import cn from 'classnames'
import React, { FC } from 'react'

import { ColorVar } from '@consts/common'

import { Button } from '@common/Button'
import { Content } from '@common/Content'
import { RippleEffect } from '@common/RippleEffect'
import { OrganizationLink } from '@containers/navigation'
import { ReactComponent as ErrorSVG } from '../error.svg'

import { TPage500Props as IProps } from './types'

import styles from '../Errors.module.css'

// ToDo изображения по src не грузятся. заменить на svg и исправить размеры
const Page500: FC<IProps> = ({ mainNavItemRoute, onResetError500 }) => (
  <Content classes='d-flex flex-column justify-content-between mx-6'>
    <div className='d-flex flex-column align-items-center m-4'>
      <span className={styles.error}>
        <ErrorSVG />
      </span>

      <p className={cn('fw-700 text-brand', styles.error_code)}>500</p>
      <p className='text-center font-md fw-600 mt-4'>{'Внутренняя ошибка сервера'}</p>
      <p className='text-center text-muted font-sm mt-4'>{'Мы знаем о проблеме и делаем все, чтобы ее устранить'}</p>
    </div>

    <OrganizationLink to={mainNavItemRoute} className='mb-4'>
      <Button theme='brand' classes='relative width-full fw-500' onClick={onResetError500}>
        {'На главную'}
        <RippleEffect color={ColorVar.LIGHT} />
      </Button>
    </OrganizationLink>
  </Content>
)

export default Page500
