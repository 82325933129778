import React, { FC } from 'react'

import { ReactComponent as SkeletonLinkDocSVG } from './link-doc.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonLinkToDocument: FC = () => (
  <div className='skeleton skeleton--link-doc mb-2'>
    <SkeletonLinkDocSVG />
  </div>
)
