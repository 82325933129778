import React, { FC } from 'react'

import { Header } from '@common/Header'

import { SkeletonHeaderTitle, SkeletonIcon } from '@common/skeletons/components'
import { SkeletonBadgeSVG, SkeletonMessagesSVG } from './svg'

import '@common/skeletons/Skeletons.scss'

// ToDo подумать, если тень накладывается на тень, то, возможно, в Header нужно еще передавать тип
export const SkeletonHeader: FC = () => (
  <Header>
    <SkeletonIcon classes='m-4' />
    <SkeletonHeaderTitle />
    <span className='skeleton skeleton--messages ml-auto mr-4'>
      <span className='skeleton skeleton--badge absolute trf-x-quarter-ny-half'>
        <SkeletonBadgeSVG />
      </span>
      <SkeletonMessagesSVG />
    </span>
  </Header>
)
