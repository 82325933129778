import { IPushNotificationPayload } from '@types'
import { History } from 'history'

import store from '@store/configureStore'

import { iOSPushPermissionRequest } from '@consts/common'
import { OpenedFromNotification, PushNotificationDataType, PushNotificationPayloadKey } from '@consts/enumTypes'
import { Path, RouteName } from '@consts/navItems'

import {
  getPushNotificationIdFromStorage,
  getPushNotificationsTokenFromStorage,
  setPrevPushNotificationsTokenToStorage,
  setPushNotificationId,
  setPushNotificationsTokenToStorage
} from './localStorage'
import { createPath, createUrl, getIsAnotherValue, setStartPage } from './utils'

import { showBasicNotification } from '@common/Notifications'

import { navigationActions } from '@store/modules/navigation/actions'
import { changeOrganization, resetStoreAfterOrganizationChange, userActions } from '@store/modules/user/actions'

const $window = window as any

// Получение токена устройства
export const getPushNotificationsToken = (): void => {
  getToken().catch((err: any) => {
    if (err) throw new Error(err)
  })
}

// Открытие нотификации, когда приложение свернуто или устройство заблокировано
export const openPushNotification = (history: History): void => {
  $window.FCM.onNotification(handleOpenPushNotification(history))
}

// Открытие нотификации, когда приложение закрыто
export const openInitialPushNotification = (history: History): void => {
  $window.FCM.getInitialPushPayload()
    .then((notificationData: IPushNotificationPayload) => {
      if (notificationData) {
        const pushId = notificationData[PushNotificationPayloadKey.ID] ?? null
        const prevPushId = getPushNotificationIdFromStorage()

        pushId && !prevPushId
          ? processPushNotificationData(history, notificationData, true)
          : getIsAnotherValue(prevPushId, pushId)
          ? processPushNotificationData(history, notificationData, true)
          : setStartPage(history)

        setPushNotificationId(pushId)
      } else {
        setStartPage(history)
      }
    })
    .catch((err: any) => {
      if (err) throw new Error(err)
    })
}

// Получение токена устройства и сохранение на устройстве
const getToken = async (): Promise<void> => {
  const prevToken = getPushNotificationsTokenFromStorage()

  // для Android всегда возвращает true
  const wasPermissionGiven: boolean = await $window.FCM.requestPushPermission({
    ios9Support: {
      timeout: iOSPushPermissionRequest.TIMEOUT,
      interval: iOSPushPermissionRequest.INTERVAL
    }
  })

  if (wasPermissionGiven) {
    const newToken = await $window.FCM.getToken()
    const isAnotherToken = getIsAnotherValue(prevToken, newToken)

    if (!prevToken || isAnotherToken) {
      setPushNotificationsTokenToStorage(newToken)

      if (prevToken) setPrevPushNotificationsTokenToStorage(prevToken)
    }
  }
}

// Помощник, обеспечивающий запуск обработки данных нотификации
const handleOpenPushNotification = (history: History) => (notificationData: IPushNotificationPayload): void => {
  processPushNotificationData(history, notificationData)
}

// Получение маршрута в зависимости от типа нотификации
const getTo = (notificationType: string, documentId?: string): string => {
  if (notificationType === PushNotificationDataType.DOCUMENT && documentId) {
    return `${RouteName.DOCUMENTS}/${documentId}`
  }

  if (notificationType === PushNotificationDataType.MESSAGES) {
    return Path.MESSAGES
  }

  // Возможно, в будущем можно будет заменить ссылку на "Центр уведомлений"
  return store.getState().navigation.navItems[0].route
}

// Обработка данных нотификации
const processPushNotificationData = (
  history: History,
  notificationData: IPushNotificationPayload,
  isInitialPushNotification?: boolean
): void => {
  const { loader, user } = store.getState()

  // Если нотификация открыта из статус-бара
  if (notificationData[PushNotificationPayloadKey.WAS_TAPPED] && !loader.isLoading) {
    const {
      user: { orgs },
      activeOrg
    } = user

    const isAnotherOrg = activeOrg.oguid !== notificationData[PushNotificationPayloadKey.ORG_OGUID]

    const to = getTo(
      notificationData[PushNotificationPayloadKey.TYPE],
      notificationData[PushNotificationPayloadKey.DOCUMENT_ID]
    )

    // Если уведомление поступило из текущей выбранной пользователем организации, то:
    if (!isAnotherOrg) {
      // - вычисляется путь
      const path = createPath(notificationData[PushNotificationPayloadKey.ORG_OGUID], to)

      // - открывается карточка документа, при этом учитывается было ли до этого запущено приложение
      isInitialPushNotification ? history.push(path) : history.replace(path)

      // - устанавливается признак открытия карточки из уведомления
      store.dispatch(navigationActions.setOpenedFromNotification(OpenedFromNotification.PUSH))
    }

    // Если уведомление поступило из другой организации, то:
    if (isAnotherOrg) {
      // - поиск организации, из которой поступило уведомление, среди доступных пользователю
      const newOrg = orgs.find(({ oguid }) => oguid === notificationData[PushNotificationPayloadKey.ORG_OGUID])

      // - если организация найдена, то:
      if (newOrg) {
        // - очищается store
        store.dispatch(resetStoreAfterOrganizationChange())

        // - сохраняется идентификатор прошлой активной организации (требуется для ограничения работы c history кнопки <BackButton />)
        store.dispatch(userActions.setPrevActiveOrgOguidAction(activeOrg.oguid))

        // - устанавливается признак открытия сущности из уведомления
        store.dispatch(navigationActions.setOpenedFromNotification(OpenedFromNotification.PUSH))

        // - открывается карточка документа
        history.replace(createUrl(newOrg.oguid, to))

        // - устанавливается активной новая организация
        store.dispatch(changeOrganization(newOrg))
      } else {
        // - отображается нотификация о том, что органиция, из которой поступило уведомление, недоступна пользователю
        showBasicNotification({
          content: [
            { value: 'notifications:push.errors.notOrg.content.1' },
            { value: 'notifications:push.errors.notOrg.content.2' }
          ],
          title: { value: 'notifications:push.errors.notOrg.title' }
        })

        // - выполняется переход на первый доступный раздел текущей организации, если приложение не было до этого запущено
        isInitialPushNotification && setStartPage(history)
      }
    }
  }
}
