import React, { FC } from 'react'

import { SkeletonStatusIconSVG, SkeletonStatusLabelSVG } from './svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonStatus: FC = () => (
  <div className='d-flex align-items-center'>
    <span className='skeleton skeleton--status-icon mr-1'>
      <SkeletonStatusIconSVG />
    </span>
    <span className='skeleton skeleton--status-label'>
      <SkeletonStatusLabelSVG />
    </span>
  </div>
)
