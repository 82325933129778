import { AxiosError, AxiosResponse } from 'axios'
import { TFunction } from 'i18next'

import store from '@store/configureStore'

import approvalService from '@services/approval'
import dscService from '@services/dsc'
import signingService from '@services/signing'

import { ResponseCode, SuccessCode } from '@consts/common'
import { AccessTaskType, AllowedAction, AssignMode, CompleteStatus, FlowTaskType } from '@consts/enumTypes'
import { DocumentActions } from '@consts/translations'

import { sendErrorToSentry } from '@utils/utils'
import { getFlowStage, getFlowTaskStatus } from '@utils/views'

import {
  showBasicNotification,
  showConfirmNotification,
  showErrorNotification,
  showSuccessNotification
} from '@common/Notifications'

import { getDocumentData, getDocumentFlowState } from '@store/modules/document/actions'
import { completeTask, tasksActions } from '@store/modules/tasks/actions'

import { Nullable } from '@types'
import {
  IApprovalSchemeByRoute,
  IApprovalSchemeStage,
  IApprovalSchemeTask,
  IApprovedScheme,
  IApprovedStage,
  IApprovedTask
} from '@services/approval/types'
import { IDocumentStage, IDocumentTask } from '@store/modules/document/types'
import { IFlowStageType } from '@store/modules/metadata/types'
import { IUserCertificate } from '@store/modules/user/types'
import { IAccessTask } from '@store/modules/documents/types'
import { IDataToCompleteTask, TasksActionKey } from '@store/modules/tasks/types'

export const getAccessTaskTypeToApprove = (flowStageType: Nullable<string>): AccessTaskType =>
  flowStageType === FlowTaskType.ACQUAINTANCE
    ? AccessTaskType[FlowTaskType.ACQUAINTANCE]
    : AccessTaskType[FlowTaskType.APPROVAL]

// Отображение сообщения для неподдерживаемого действия
export const showUnsupportedActionNotification = (): void => {
  showBasicNotification({
    content: [
      { value: 'notifications:documentActions.errors.unsupported.content.1' },
      { value: 'notifications:documentActions.errors.unsupported.content.2' }
    ]
  })
}

// Отображение сообщения о выполнении действия
const showCompletedActionNotification = (taskOguid: string): void => {
  const stages = store.getState().document.flowState.stages

  let completedTask: Nullable<IDocumentTask> = null

  const stageWithCompletedTask = stages.find((stage: IDocumentStage) =>
    stage.tasks.find((task: IDocumentTask) => {
      const isCompletedTask = task.oguid === taskOguid

      if (isCompletedTask) completedTask = task

      return isCompletedTask
    })
  )

  if (stageWithCompletedTask && completedTask) {
    const { completedTimestamp, result, state } = completedTask
    const taskStage = getFlowStage(stageWithCompletedTask.type)
    const status = getFlowTaskStatus(state, result, taskStage)

    const dataToCompleteTask: IDataToCompleteTask = {
      taskOguid,
      completedTimestamp,
      status,
      state,
      result
    }

    store.dispatch(tasksActions[TasksActionKey.COMPLETE_TASK](dataToCompleteTask))

    showSuccessNotification({ content: [{ value: status, classes: 'text-center' }] })
  }
}

const getUpdatedDocumentFlowState = (taskOguid: string): void => {
  const { document } = store.getState().document

  store
    .dispatch<any>(getDocumentFlowState(document?.oguid))
    .then(({ status }: AxiosResponse) => {
      if (status === ResponseCode.GET) showCompletedActionNotification(taskOguid)
    })
    .catch(showErrorNotification)
}

// Обновление данных документа после задач подписания
const updateDocumentDataAfterSigning = (status: number, taskOguid: string): void => {
  if (SuccessCode.POST.includes(status)) {
    const { document } = store.getState().document

    store
      .dispatch<any>(getDocumentData(document?.oguid))
      .then(({ status }: AxiosResponse) => {
        if (status === ResponseCode.GET) getUpdatedDocumentFlowState(taskOguid)
      })
      .catch(showErrorNotification)
  }
}

// Обновление данных документа после выполнения задач согласования
const updateDocumentData = (status: number, taskOguid?: string): void => {
  if (SuccessCode.POST.includes(status)) {
    const { document } = store.getState().document

    store.dispatch<any>(getDocumentData(document?.oguid)).catch(showErrorNotification)

    taskOguid && getUpdatedDocumentFlowState(taskOguid)
  }
}

export const confirmDocumentAction = (title: string, performAction: () => void): void => {
  showConfirmNotification({
    buttons: {
      cancel: { text: 'common:cancel' },
      submit: { text: title, callback: performAction }
    },
    options: {
      content: [{ value: store.getState().document.title }]
    }
  })
}

// Запуск процесса согласования документа
export const startProcessAction = (t: TFunction): void => {
  const { document } = store.getState().document

  if (document) {
    const { oguid } = document

    approvalService
      .getApprovalScheme(oguid)
      .then(({ data }: AxiosResponse<IApprovalSchemeByRoute>) => {
        if (!data.stages) {
          return sendErrorDocumentActionToSentry(oguid, t('notifications:flow.errors.notApprovalScheme.content.1'))
        }

        const targetScheme: IApprovedScheme = {
          stages: data.stages.map(getApprovalStage)
        }

        approvalService
          .startApprovalScheme(oguid, targetScheme)
          .then(({ status }: AxiosResponse) => {
            updateDocumentData(status)

            showSuccessNotification({
              content: [{ value: 'notifications:flow.success.content.1', classes: 'text-center' }]
            })
          })
          .catch(showErrorNotification)
      })
      .catch(showErrorNotification)
  }
}

// Выполнение задачи процесса согласования документа
export const completeTaskAction = (t: TFunction, result: CompleteStatus, taskType: AccessTaskType): void => {
  const { document } = store.getState().document

  if (document) {
    const { oguid, tasks } = document

    const taskOguid = getCurrentTaskOguid(taskType, tasks)

    if (!taskOguid) {
      return sendErrorDocumentActionToSentry(oguid, t('notifications:documentActions.errors.notTaskOguid.content.1'))
    }

    store
      .dispatch<any>(completeTask(taskOguid, result))
      .then(({ status }: AxiosResponse) => {
        updateDocumentData(status, taskOguid)
      })
      .catch(showErrorNotification)
  }
}

// Подписание документа с использованием сертификата docuForce
export const signInternalDocumentAction = (t: TFunction, taskType: AccessTaskType): void => {
  const {
    document: { document },
    user: { user }
  } = store.getState()

  if (document) {
    const { oguid, tasks } = document

    const taskOguid = getCurrentTaskOguid(taskType, tasks)

    if (!taskOguid) {
      return sendErrorDocumentActionToSentry(oguid, t('notifications:documentActions.errors.notTaskOguid.content.1'))
    }

    dscService
      .getUserCertificate(user.oguid)
      .then(({ data, status }: AxiosResponse<IUserCertificate>) => {
        if (status === ResponseCode.GET && data.oguid) {
          sendDscSign(data)
            .then(({ status }: AxiosResponse) => {
              updateDocumentDataAfterSigning(status, taskOguid)
            })
            .catch(showErrorNotification)
        }
      })
      .catch(() =>
        showBasicNotification({
          title: { value: 'notifications:certificates.dss.errors.title' },
          content: [
            { value: 'notifications:certificates.dss.errors.content.1' },
            { value: 'notifications:certificates.dss.errors.content.2' }
          ]
        })
      )
  }
}

// Получение названия действия
export const getCaptionForDocumentAction = (action: AllowedAction, flowStageType: string, t: TFunction): string =>
  action === AllowedAction.APPROVE ? getApprovalCaption(flowStageType, t) : DocumentActions[action]

const getApprovalCaption = (flowStageType: string, t: TFunction): string => {
  const {
    metadata: { flowStageTypes },
    document: { document }
  } = store.getState()

  const targetFlowStageType = flowStageTypes.find(({ type }: IFlowStageType) => type === flowStageType)

  if (!targetFlowStageType) {
    const message = `${t('notifications:flow.errors.notFlowStageType.content.1')} ${flowStageType}`

    const sentryParams = {
      info: {
        documentOguid: document?.oguid ?? '',
        flowStageType
      },
      message
    }
    sendErrorToSentry(sentryParams)

    throw new Error(message)
  }

  return targetFlowStageType.buttonCaption ?? ''
}

// Получение идентификатора задачи процесса согласования, для который отображаются доступные действия
const getCurrentTaskOguid = (taskType: AccessTaskType, tasks?: IAccessTask[]): string | undefined =>
  tasks?.find((task: IAccessTask) => task.type === taskType)?.oguid

// Определение процесса согласования документа для запуска
const getApprovalStage = ({ userName, type, tasks }: IApprovalSchemeStage): IApprovedStage => ({
  userName,
  type,
  tasks: tasks.map(getApprovalTask)
})

// Определение исполнителей для задач процесса согласования документа
const getApprovalTask = ({ assignedToGroup, assignedToUser, assignMode }: IApprovalSchemeTask): IApprovedTask => ({
  assignedToGroupOguid: assignedToGroup?.oguid ?? null,
  assignedToUserOguid: assignedToUser?.oguid ?? null,
  assignMode: assignedToGroup ? assignMode ?? AssignMode.ANY : null
})

// Отправка сообщения об ошибке документа в Sentry
const sendErrorDocumentActionToSentry = (documentOguid: string, message: string): void => {
  const sentryParams = {
    info: { documentOguid },
    message
  }
  sendErrorToSentry(sentryParams)

  showBasicNotification({
    content: [{ value: message }]
  })
}

// Непосредственно сам процесс подписания документа на сервере с использованием сертификата docuForce
const sendDscSign = async (certificate: IUserCertificate): Promise<any> => {
  const {
    document: { document },
    user: { user }
  } = store.getState()

  if (!document) return

  const { documentAttachmentOguid, oguid } = document

  let hashResponse

  try {
    hashResponse = await signingService.getHashFile({
      standard: certificate.standard,
      attachmentOguid: documentAttachmentOguid
    })
  } catch (err) {
    // ToDo здесь и ниже в подобных местах избавиться от any
    await handleCatchDscError(err as AxiosError)
  }

  let signResponse

  if (hashResponse) {
    try {
      signResponse = await dscService.signFileHash(user.oguid, certificate.oguid, hashResponse?.data.hash)
    } catch (err) {
      await handleCatchDscError(err as AxiosError)
    }

    if (signResponse) {
      try {
        return await signingService.attachSignature(oguid, {
          oguid: signResponse.data,
          comment: null
        })
      } catch (err) {
        await handleCatchDscError(err as AxiosError)
      }
    }
  }
}

// ToDo избавиться от any
const handleCatchDscError = (err: AxiosError<any>): void => {
  err.response?.data?.message !== ''
    ? showErrorNotification(err)
    : showBasicNotification({ content: [{ value: 'notifications:basic.content.1' }] })
}
