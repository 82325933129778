import React, { Component, ReactElement } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
import { withTranslation } from 'react-i18next'
import * as Sentry from '@sentry/browser'

import * as jwt from '@utils/jwt'

import { SkeletonLoader } from '@common/loaders'
import { showErrorNotification } from '@common/Notifications'

import { TStartupProps as IProps } from './types'
import { setLanguageToStorage } from '@utils/localStorage'

class Startup extends Component<IProps> {
  public componentDidMount (): void {
    this.authUser().catch(showErrorNotification)
  }

  public componentDidUpdate (prevProps: IProps): void {
    const { i18n, isAuthenticated, isLoaded, language, orgOguid, onSetLoaded } = this.props

    if (isAuthenticated && i18n.language !== language) {
      i18n
        .changeLanguage(language)
        .then(() => setLanguageToStorage(language))
        .catch(showErrorNotification)
    }

    if ((isAuthenticated && !prevProps.isAuthenticated) || (!isLoaded && prevProps.isLoaded)) {
      this.authUser().catch(showErrorNotification)
    }

    if (isAuthenticated && prevProps.orgOguid && prevProps.orgOguid !== orgOguid && prevProps.isLoaded) {
      Promise.all(this.getPromises())
        .then(onSetLoaded)
        .catch(showErrorNotification)
    }

    if (isAuthenticated && prevProps.language !== language) {
      Promise.all(this.getPromises(true))
        .then(onSetLoaded)
        .catch(showErrorNotification)
    }
  }

  public render (): ReactElement {
    const { children, isLoaded } = this.props

    return isLoaded ? children : <SkeletonLoader loading />
  }

  private readonly authUser = async (): Promise<void> => {
    const {
      accessToken,
      onGetBadges,
      onGetUserInfo,
      onSetAccessToken,
      onSetLoaded,
      userEmail,
      userLogin,
      userOguid
    } = this.props

    // Ищем токен в cookies
    const rememberedToken = jwt.get()

    if (rememberedToken.accessToken && !accessToken) {
      // Если токен есть в cookies, но его ещё нет в сторе - кладём в стор
      onSetAccessToken(rememberedToken.accessToken)
    }

    // Если токен есть в cookies или в сторе - авторизуем пользователя
    if (rememberedToken.accessToken || accessToken) {
      try {
        const {
          data: { orgs }
        } = await onGetUserInfo()

        if (!orgs.length) return onSetLoaded()

        Sentry.configureScope((scope) => {
          scope.setUser({
            email: userEmail ?? userLogin ?? '',
            id: userOguid
          })
        })

        /**
         * Если после получения информации пользователя у него (пользователя) будет перечень организаций,
         * но в действительности ни в одной из них он не состоит,
         * то при попытке получения счетчиков (onGetBadges) будет получена ошибка авторизации и http-сервис выполнит действия условия 'status === ErrorCode.NOT_AUTH'
         */
        await onGetBadges()

        await Promise.all(this.getPromises())
      } catch (err) {
        showErrorNotification(err as AxiosError)
      }
    }

    onSetLoaded()
  }

  private readonly getPromises = (isUpdate?: boolean): Array<Promise<AxiosResponse>> => {
    const {
      onGetDocumentTypes,
      onGetFlowStageTypes,
      onGetHistoryStateCodes,
      onGetUserExtendedInfo,
      onGetWorkflowStatuses
    } = this.props

    const promises = [onGetDocumentTypes(), onGetFlowStageTypes(), onGetHistoryStateCodes(), onGetWorkflowStatuses()]

    return isUpdate ? promises : [onGetUserExtendedInfo(), ...promises]
  }
}

export default withTranslation()(Startup)
