import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import { AllRoles } from '@consts/common'

import { IconAgreement, IconInternal, IconQuestionAlt } from '@common/icons'

import { IDocNavItem, INavItem } from '@types'

const PathServiceName = {
  PARENT: '/:parent',
  ID: '/:id',
  TYPE: '/:type',
  ASTERISK_SYMBOL: '/*',
  ADD: '/add',
  EDIT: '/edit'
}

export const RouteName = {
  DEFAULT: '/',
  DOCUMENTS: '/documents',
  LANGUAGES: '/languages',
  MESSAGES: '/messages',
  ORGANIZATION_URL: '/:org',
  ORGANIZATIONS: '/orgs',
  PASSWORD: '/password',
  PROFILE: '/profile',
  RESTORE_PASSWORD: '/restore-password',
  SET_PIN_CODE: '/set-pin-code',
  SETTINGS: '/settings',
  SIGN_IN: '/login',
  SIGN_UP: '/register'
}

export enum DefaultSubNavItem {
  ALL = 'all',
  INCOMING = 'incoming',
  OUTGOING = 'outgoing',
  TASKS = 'tasks'
}

export const Section = {
  DOCUMENTS_ALL: `${RouteName.DOCUMENTS}/${DefaultSubNavItem.ALL}`,
  MESSAGES_INCOMING: `${RouteName.MESSAGES}/${DefaultSubNavItem.INCOMING}`,
  MESSAGES_OUTGOING: `${RouteName.MESSAGES}/${DefaultSubNavItem.OUTGOING}`,
  SETTINGS: RouteName.SETTINGS,
  TASKS: `${RouteName.DOCUMENTS}/${DefaultSubNavItem.TASKS}`
}

export const Path = {
  DOCUMENT_CARD: `${RouteName.DOCUMENTS}${PathServiceName.ID}`,
  DOCUMENTS_ALL: Section.DOCUMENTS_ALL,
  LANGUAGES: RouteName.LANGUAGES,
  MESSAGES: RouteName.MESSAGES,
  MESSAGES_INCOMING: Section.MESSAGES_INCOMING,
  MESSAGES_OUTGOING: Section.MESSAGES_OUTGOING,
  ORGANIZATION_ROUTE: `${RouteName.ORGANIZATION_URL}${PathServiceName.ASTERISK_SYMBOL}`,
  ORGANIZATIONS: RouteName.ORGANIZATIONS,
  PASSWORD: RouteName.PASSWORD,
  PROFILE: RouteName.PROFILE,
  RESTORE_PASSWORD: RouteName.RESTORE_PASSWORD,
  SET_PIN_CODE: RouteName.SET_PIN_CODE,
  SETTINGS: RouteName.SETTINGS,
  SIGN_IN: RouteName.SIGN_IN,
  TASKS: Section.TASKS
}

export const navTasks: INavItem = {
  icon: <IconAgreement />,
  isFlowFunctionalityEnabled: true,
  title: 'tasks:route',
  route: Path.TASKS,
  roles: AllRoles,
  uuid: uuidv4()
}

export const navDocuments: INavItem = {
  icon: <IconInternal />,
  title: 'documents:route',
  route: Path.DOCUMENTS_ALL,
  roles: AllRoles,
  uuid: uuidv4()
}

export const NAV_DOCS: INavItem[] = [
  navTasks,
  navDocuments
  // Todo скрыто по задаче https://3.basecamp.com/4382260/buckets/21311268/todos/3703666970
  // {
  //   icon: <IconIncoming />,
  //   isExchangeParticipant: true,
  //   title: 'Входящие',
  //   route: Path.MESSAGES_INCOMING,
  //   roles: AllRoles,
  //   uuid: uuidv4(),
  // },
  // {
  //   icon: <IconOutgoing />,
  //   isExchangeParticipant: true,
  //   title: 'Исходящие',
  //   route: Path.MESSAGES_OUTGOING,
  //   roles: AllRoles,
  //   uuid: uuidv4(),
  // }
]

export const navMessages: INavItem = {
  route: Path.MESSAGES,
  roles: AllRoles,
  title: 'messages:route',
  uuid: uuidv4()
}

export const navDocWrite: IDocNavItem = {
  icon: <IconQuestionAlt color='primary-500' />,
  title: 'document:actions.askQuestion',
  uuid: uuidv4()
}

export const navSettings: INavItem = {
  title: 'settings:route',
  route: Path.SETTINGS,
  roles: AllRoles,
  uuid: uuidv4()
}

export const navLanguages: INavItem = {
  title: 'settings:blocks.languages.title',
  route: Path.LANGUAGES,
  roles: AllRoles,
  uuid: uuidv4()
}

export const navOrgs: INavItem = {
  title: 'organizations:route',
  route: Path.ORGANIZATIONS,
  roles: AllRoles,
  uuid: uuidv4()
}

export const navProfile: INavItem = {
  title: 'profile:route',
  route: Path.PROFILE,
  roles: AllRoles,
  uuid: uuidv4()
}

export const navPassword: INavItem = {
  title: 'password:route',
  route: Path.PASSWORD,
  roles: AllRoles,
  uuid: uuidv4()
}

export const NAV_SETTINGS_CHILDREN: INavItem[] = [navLanguages, navOrgs, navPassword, navProfile]

export const NAV_SETTINGS: INavItem[] = [navSettings, ...NAV_SETTINGS_CHILDREN]

export const NAV_ALL: INavItem[] = [...NAV_DOCS, ...NAV_SETTINGS, navMessages]
