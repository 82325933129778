export default {
  approval: {
    scheme: 'flows/entities/{{ documentOguid }}/flowStart'
  },
  auth: {
    org: '',
    restorePassword: 'password-reset',
    signin: 'signin',
    signup: 'signup'
  },
  document: {
    file: 'documents/{{ documentOguid }}/page/{{ documentPage }}',
    flowState: 'flows/entities/{{ documentOguid }}/flowState',
    downloadFile: 'documents/{{ documentOguid }}/printPdf',
    downloadFileByPage: 'attachments/{{ documentAttachmentOguid }}/file',
    messages: 'flows/entities/{{ documentOguid }}/todos',
    remove: 'documents/{{ documentOguid }}',
    single: 'documents/{{ documentId }}'
  },
  documents: {
    list: 'documents'
  },
  dsc: {
    getCertificate: 'users/{{ oguid }}/certificate',
    signFileHash: 'users/{{ oguid }}/docuForceCertificate/{{ certificateOguid }}/sign'
  },
  messages: {
    list: 'flows/tasks',
    markAsRead: 'flows/tasks/{{ taskOguid }}/markAsRead',
    new: 'flows/entities/{{ documentOguid }}/tasks',
    transferToClose: 'flows/tasks/{{ taskOguid }}/complete'
  },
  metadata: {
    documentTypes: 'documents/types',
    flowStageTypes: 'routes/flowStageTypes',
    historyStateCodes: 'documents/historyStateCodes',
    workflowStatuses: 'documents/workflowStatuses'
  },
  navigation: {
    badges: 'flows/tasksQuantities'
  },
  notifications: {
    registerDevice: 'user/devices'
  },
  recipients: {
    list: 'recipients'
  },
  routes: {
    list: 'routes',
    single: 'routes/{{ routeOguid }}'
  },
  signing: {
    attach: 'documents/{{ documentOguid }}/attachments/signatures',
    hash: 'attachments/hash'
  },
  tasks: {
    complete: 'flows/tasks/{{ taskOguid }}/complete',
    list: 'flows/tasks'
  },
  user: {
    single: 'user'
  }
}
