import React, { FC } from 'react'

import { SkeletonFieldLabelnSVG, SkeletonFieldValueSVG } from './svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonDocumentField: FC = () => (
  <div className='d-flex justify-content-between mt-2'>
    <span className='skeleton skeleton--field-label'>
      <SkeletonFieldLabelnSVG />
    </span>
    <span className='skeleton skeleton--field-value'>
      <SkeletonFieldValueSVG />
    </span>
  </div>
)
