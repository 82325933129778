import cn from 'classnames'
import React, { FC } from 'react'

import { SkeletonIcon } from '@common/skeletons/components'
import { ReactComponent as SkeletonButtonListItemSVG } from './block-button.svg'

import { ISkeletonBlockButtonProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

const getClasses = (isSingle?: boolean): string =>
  cn('d-flex align-items-center mx-4 py-4', { 'mt-4 border-top border-bottom-with-last': isSingle })

export const SkeletonButtonListItem: FC<IProps> = ({ isSingle }) => (
  <div className={getClasses(isSingle)}>
    <span className='skeleton skeleton--block-button'>
      <SkeletonButtonListItemSVG />
    </span>
    <SkeletonIcon classes='ml-auto' />
  </div>
)
