import { OrgField, UserField } from '@consts/fields'

import { IUserState, TUserActions, UserActionTypes } from './types'

export const initialState: IUserState = {
  accessToken: null,
  activeOrg: {
    [OrgField.ALIAS]: '',
    [OrgField.INN]: '',
    [OrgField.KPP]: '',
    [OrgField.NAME]: '',
    [OrgField.NAME_SHORT]: '',
    [OrgField.OGUID]: ''
  },
  authData: {
    [UserField.EMAIL]: '',
    [UserField.PASSWORD]: ''
  },
  [UserField.EXTENDED_SIGNER]: null,
  isAuthenticated: false,
  [OrgField.IS_EXCHANGE_PARTICIPANT]: false,
  [OrgField.IS_FLOW_FUNCTIONALITY_ENABLED]: false,
  [UserField.IS_READONLY]: false,
  [UserField.POSITION]: null,
  prevActiveOrgOguid: '',
  [UserField.ROLE]: '',
  user: {
    [UserField.EMAIL]: '',
    [UserField.LOCALE]: '',
    [UserField.LOGIN]: '',
    [UserField.NAME]: '',
    [UserField.OGUID]: '',
    [UserField.ORGS]: [],
    [UserField.PATRONYMIC]: '',
    [UserField.PHONE]: '',
    [UserField.SURNAME]: '',
    [UserField.TIMEZONE]: ''
  }
}

export default function userReducer (state: IUserState = initialState, action: TUserActions): IUserState {
  switch (action.type) {
    case UserActionTypes.RESET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: null
      }

    case UserActionTypes.RESET_PREV_ACTIVE_ORG_OGUID:
      return {
        ...state,
        prevActiveOrgOguid: ''
      }

    case UserActionTypes.SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload
      }

    case UserActionTypes.SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        activeOrg: {
          ...action.payload
        }
      }

    case UserActionTypes.SET_AUTH_DATA:
      return {
        ...state,
        authData: {
          ...action.payload
        }
      }

    case UserActionTypes.SET_AUTHENTICATED:
      return {
        ...state,
        ...action.payload
      }

    case UserActionTypes.SET_PREV_ACTIVE_ORG_OGUID:
      return {
        ...state,
        prevActiveOrgOguid: action.payload
      }

    case UserActionTypes.SET_USER_EXTENDED_INFO:
      return {
        ...state,
        ...action.payload
      }

    case UserActionTypes.SET_USER_INFO:
      return {
        ...state,
        user: {
          ...action.payload
        }
      }

    case UserActionTypes.SET_USER_LOCALE: {
      return {
        ...state,
        user: {
          ...state.user,
          [UserField.LOCALE]: action.payload
        }
      }
    }

    case UserActionTypes.SET_USER_ORGANIZATIONS:
      return {
        ...state,
        user: {
          ...state.user,
          orgs: action.payload
        }
      }

    default:
      return state
  }
}
