import { isPlatformAndroid, isPlatformIOS } from '@utils/device'

import { showBasicNotification } from '@common/Notifications'

const $window = window as any

const getFileStorageLocation = (): string => {
  if (isPlatformAndroid()) return $window.cordova.file.externalDataDirectory

  if (isPlatformIOS()) return $window.cordova.file.documentsDirectory

  return ''
}

export const downloadFileOnDevice = (fileData: Blob, fileName: string, isPreview?: boolean): void => {
  $window.resolveLocalFileSystemURL(getFileStorageLocation(), saveFile(fileData, fileName, isPreview))
}

const saveFile = (fileData: Blob, fileName: string, isPreview?: boolean) => (dirEntry: any) =>
  dirEntry.getFile(
    fileName,
    { create: true, exclusive: false },
    createFileWriter(fileData, isPreview),
    showSavedFileError
  )

const createFileWriter = (fileData: Blob, isPreview?: boolean) => (fileEntry: any) =>
  fileEntry.createWriter(writeFile(fileData, fileEntry, isPreview))

const writeFile = (fileData: Blob, fileEntry: any, isPreview?: boolean) => (fileWriter: any): void => {
  fileWriter.onwriteend = (): void => {
    if (isPlatformAndroid() || (isPlatformIOS() && !isPreview)) {
      $window.cordova.plugins.fileOpener2.showOpenWithDialog(fileEntry.toURL(), fileData.type, {
        error: showWritingFilePreviewError,
        position: [0, 0]
      })
    }

    if (isPlatformIOS() && isPreview) {
      $window.cordova.plugins.fileOpener2.open(fileEntry.toURL(), fileData.type, {
        error: showWritingFilePreviewError
      })
    }
  }

  fileWriter.onerror = (): void => showSavedFileError()

  fileWriter.write(fileData)
}

const showSavedFileError = (): void =>
  showBasicNotification({
    title: { value: '' },
    content: [
      {
        value: 'notifications:files.errors.notSaved.content',
        classes: 'text-center'
      }
    ]
  })

const showWritingFilePreviewError = (): void =>
  showBasicNotification({
    title: { value: '' },
    content: [{ value: 'document:errors.noApp', classes: 'text-center' }]
  })
