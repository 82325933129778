import React, { cloneElement, ReactElement, ReactNode, SetStateAction } from 'react'
import { AxiosResponse } from 'axios'
import { TFunction } from 'i18next'

import store from '@store/configureStore'

import {
  ColorVar,
  FlowTaskStatusColor,
  HistoryStateIcon,
  HistoryStateIconProps,
  SeverityColor,
  Space,
  StatusIconPropsToFlowTab,
  StatusIconPropsToList,
  SuccessCode,
  Timeout
} from '@consts/common'
import {
  AssignMode,
  DocumentType,
  FlowDocumentResult,
  FlowDocumentState,
  FlowTaskResult,
  FlowTaskState,
  InputType,
  Severity,
  UpdatedSettingsType,
  WorkflowStatus
} from '@consts/enumTypes'
import { FieldName } from '@consts/fields'
import { DocumentsDirections, FlowDocumentStatusText, FlowTaskStateStatusText, Route } from '@consts/translations'

import {
  debounce,
  getCurrencyCharacter,
  getFormattedDatetimeValue,
  getFormattedDateValue,
  getSurnameWithInitials,
  sendErrorToSentry
} from '@utils/utils'

import { Button } from '@common/Button'
import { Ellipsis } from '@common/Ellipsis'
import {
  IconAgreement,
  IconCanceled,
  IconDisk,
  IconEmptyCircle,
  IconInfo,
  IconQuestionAlt,
  IconReject,
  IconStop,
  IconTime,
  IconVisibleOff,
  IconVisibleOn
} from '@common/icons'
import { showErrorNotification, showSuccessNotification } from '@common/Notifications'
import { RippleEffect } from '@common/RippleEffect'

import { getUserInfo, updateUserInfo } from '@store/modules/user/actions'

import { IDocumentInfo, IFlowStatusInfo, Nullable } from '@types'
import { IIconProps } from '@common/Icon/types'
import { IDocumentTask } from '@store/modules/document/types'
import { IDocument, IDocumentField } from '@store/modules/documents/types'
import { IFlowStageType, IHistoryStateCode } from '@store/modules/metadata/types'
import { ITaskData, ITaskStage } from '@store/modules/tasks/types'
import { IUpdatedUserInfo } from '@store/modules/user/types'

export const renderContractor = ({ fields: { contractor } }: IDocument): ReactNode =>
  contractor && typeof contractor === 'object' && 'nameShort' in contractor ? (
    <p className='font-md fw-500 mb-2'>
      <Ellipsis>{contractor.nameShort}</Ellipsis>
    </p>
  ) : null

export const renderDocumentInfo = ({ direction, fields, type }: IDocument, t: TFunction): ReactNode => {
  if (!fields) return null

  const { documentDate, documentNumber, filename } = fields

  const currencyCharacter = getCurrencyCharacter(fields[FieldName.CURRENCY])

  const info: IDocumentInfo = {
    date: getDocumentDate(documentDate, t),
    direction: `${t(DocumentsDirections[direction])} `,
    filename: getFilename(type, filename),
    number: getDocumentNumber(documentNumber),
    sum: renderSum(currencyCharacter, fields[FieldName.SUM_TOTAL], t),
    vat: renderVat(currencyCharacter, fields[FieldName.SUM_VAT], t)
  }

  return (
    <div className='d-flex flex-column'>
      <p className='mb-1'>
        <span className='text-brand'>
          {info.direction}
          {info.filename}
          {info.number}
        </span>
        {info.date}
      </p>
      {(info.sum ?? info.vat) && (
        <p className='mb-1'>
          {info.sum}
          {info.vat}
        </p>
      )}
    </div>
  )
}

export const renderDocumentTitle = ({ direction, fields, type }: IDocument, t: TFunction): ReactNode => {
  const { documentDate, documentNumber, filename } = fields

  const info: IDocumentInfo = {
    date: getDocumentDate(documentDate, t),
    direction: `${t(DocumentsDirections[direction])} `,
    filename: getFilename(type, filename),
    number: getDocumentNumber(documentNumber)
  }

  return (
    <span className='text-brand'>
      {info.direction}
      {info.filename}
      {info.number}
      {info.date}
    </span>
  )
}

export const renderTaskStatus = (
  { completedTimestamp, result, state }: ITaskData,
  { type }: ITaskStage,
  t: TFunction
): ReactElement => {
  const taskStage = getFlowStage(type)

  const status: IFlowStatusInfo = {
    date: completedTimestamp ? getFormattedDatetimeValue(completedTimestamp) : undefined,
    icon: getFlowTaskIcon(state, result, StatusIconPropsToList),
    status: t(getFlowTaskStatus(state, result, taskStage))
  }

  return renderFlowStatus(status)
}

export const renderDocumentStatus = (
  { documentTimestamp, flowResult, flowStageType, flowState, workflowStatuses }: IDocument,
  t: TFunction
): ReactNode => {
  const { workflowStatuses: workflowStatusesFromState } = store.getState().metadata

  const date = getFormattedDatetimeValue(documentTimestamp)

  if (workflowStatuses?.length) {
    const [workflowStatus] = workflowStatuses

    const status: IFlowStatusInfo = {
      date,
      icon: getWorkflowDocumentIcon(flowState, workflowStatuses),
      status: workflowStatusesFromState[workflowStatus].caption
    }

    return renderFlowStatus(status)
  }

  const stage = flowStageType ? getFlowStage(flowStageType) : undefined

  const status: IFlowStatusInfo = {
    date,
    icon: getFlowDocumentIcon(flowState, flowResult),
    status: t(getFlowDocumentStatus(flowState, flowResult, stage))
  }

  return status ? renderFlowStatus(status) : null
}

const renderFlowStatus = ({ date, icon, status }: IFlowStatusInfo): ReactElement => (
  <div className='d-flex flex-row align-items-center'>
    {icon}
    {status}
    {date && <span className='ml-auto text-muted'>{date}</span>}
  </div>
)

const renderSum = (currency: string, sumTotal: Nullable<IDocumentField>, t: TFunction): ReactNode =>
  sumTotal ? (
    <>
      <span>{t('document:fields.sum')}</span>
      {getFieldStringValue(sumTotal)}
      {currency}
    </>
  ) : null

const renderVat = (currency: string, sumVat: Nullable<IDocumentField>, t: TFunction): ReactNode =>
  sumVat ? (
    <span className='ml-2 text-muted'>
      {t('document:fields.vat')}
      {Space.STANDART}
      {sumVat}
      {currency}
    </span>
  ) : null

const getDocumentNumber = (documentNumber: Nullable<IDocumentField>): string =>
  documentNumber && typeof documentNumber === 'string' ? ` № ${String(documentNumber)}` : ''

const getDocumentDate = (documentDate: Nullable<IDocumentField>, t: TFunction): string => {
  if (documentDate && typeof documentDate === 'number') {
    const date: string = getFormattedDateValue(documentDate)

    return ` ${t('common:from')} ${date}`
  }

  return ''
}

const getFilename = (type: string, filename: Nullable<IDocumentField>): string => {
  const { documentTypes } = store.getState().metadata

  return type === DocumentType.NONFORMALIZED && typeof filename === 'string' ? filename : documentTypes[type].title
}

const getFieldStringValue = (value: Nullable<IDocumentField>): string =>
  value && typeof value !== 'object' ? ` ${String(value)}` : ''

const getWorkflowDocumentIconBySeverity = (workflowStatuses: WorkflowStatus[]): ReactNode => {
  const { workflowStatuses: workflowStatusesFromState } = store.getState().metadata

  for (const status of workflowStatuses) {
    const { severity } = workflowStatusesFromState[status]

    switch (severity) {
      case Severity.ERROR:
        return <IconDisk color={SeverityColor[Severity.ERROR]} {...StatusIconPropsToList} />

      case Severity.INFO:
        return <IconInfo color={SeverityColor[Severity.INFO]} {...StatusIconPropsToList} />

      case Severity.SUCCESS:
        return <IconAgreement color={SeverityColor[Severity.SUCCESS]} {...StatusIconPropsToList} />

      case Severity.UNKNOWN:
        return <IconEmptyCircle color={SeverityColor[Severity.UNKNOWN]} {...StatusIconPropsToList} />

      case Severity.WARNING:
        return <IconDisk color={SeverityColor[Severity.WARNING]} {...StatusIconPropsToList} />
    }
  }

  return <IconQuestionAlt {...StatusIconPropsToList} />
}

const getWorkflowDocumentIcon = (
  flowState: Nullable<FlowDocumentState>,
  workflowStatuses: WorkflowStatus[]
): ReactNode =>
  flowState === FlowDocumentState.IN_PROGRESS ? (
    <IconTime {...StatusIconPropsToList} />
  ) : (
    getWorkflowDocumentIconBySeverity(workflowStatuses)
  )

const getFlowDocumentIcon = (
  flowState: Nullable<FlowDocumentState>,
  flowResult: Nullable<FlowDocumentResult>
): ReactNode => {
  switch (flowState) {
    case FlowDocumentState.COMPLETED:
      switch (flowResult) {
        case FlowDocumentResult.DECLINED:
          return <IconReject {...StatusIconPropsToList} />

        case FlowDocumentResult.ERROR:
          return <IconReject {...StatusIconPropsToList} />

        case FlowDocumentResult.SOLVED:
          return <IconAgreement color='success-500' {...StatusIconPropsToList} />

        case FlowDocumentResult.STOPPED:
          return <IconStop {...StatusIconPropsToList} />

        default:
          return <IconQuestionAlt {...StatusIconPropsToList} />
      }

    case FlowDocumentState.IN_PROGRESS:
      switch (flowResult) {
        case FlowDocumentResult.DECLINED:
        case FlowDocumentResult.SOLVED:
          return <IconTime {...StatusIconPropsToList} />

        case FlowDocumentResult.ERROR:
          return <IconReject {...StatusIconPropsToList} />

        default:
          return <IconTime {...StatusIconPropsToList} />
      }

    case FlowDocumentState.NOT_STARTED:
      switch (flowResult) {
        case FlowDocumentResult.DECLINED:
        case FlowDocumentResult.SOLVED:
          return <IconEmptyCircle {...StatusIconPropsToList} />

        case FlowDocumentResult.ERROR:
          return <IconReject {...StatusIconPropsToList} />

        default:
          return <IconEmptyCircle {...StatusIconPropsToList} />
      }

    case null:
      switch (flowResult) {
        case FlowDocumentResult.DECLINED:
        case FlowDocumentResult.SOLVED:
          return <IconQuestionAlt {...StatusIconPropsToList} />

        case FlowDocumentResult.ERROR:
          return <IconReject {...StatusIconPropsToList} />

        default:
          return null
      }

    default:
      return null
  }
}

export const checkDocument = (document: IDocument, t: TFunction): boolean => {
  const { documentTypes } = store.getState().metadata
  const { fields, oguid, type } = document

  if (!documentTypes.hasOwnProperty(type)) {
    const message = t('document:errors.noDocumentType')

    const sentryParams = {
      info: {
        documentOguid: oguid,
        documentType: type
      },
      message
    }

    sendErrorToSentry(sentryParams)

    return false
  }

  const metadataFields = documentTypes[type].fields

  const invalidKeys = fields ? Object.keys(fields).filter((key: string) => !metadataFields.hasOwnProperty(key)) : []

  invalidKeys.forEach((key: string) => {
    const message = t('document:errors.noField')

    const sentryParams = {
      info: {
        attributeKey: key,
        documentOguid: oguid,
        documentType: type
      },
      message
    }

    sendErrorToSentry(sentryParams)
  })

  return invalidKeys.length === 0
}

export const getFlowTaskIcon = (
  state: Nullable<FlowTaskState>,
  result: Nullable<FlowTaskResult>,
  iconProps?: IIconProps
): ReactNode => {
  switch (state) {
    case FlowTaskState.COMPLETED:
      switch (result) {
        case FlowTaskResult.CANCELED:
          return <IconCanceled {...iconProps} />

        case FlowTaskResult.DECLINED:
          return <IconReject {...iconProps} />

        case FlowTaskResult.SOLVED:
          return <IconAgreement color='success-500' {...iconProps} />

        default:
          return <IconQuestionAlt {...iconProps} />
      }

    case FlowTaskState.IN_PROGRESS:
      switch (result) {
        case FlowTaskResult.DECLINED:
        case FlowTaskResult.SOLVED:
          return <IconTime {...iconProps} />

        default:
          return <IconTime {...iconProps} />
      }

    case FlowTaskState.WAITING:
      switch (result) {
        case FlowTaskResult.DECLINED:
        case FlowTaskResult.SOLVED:
          return <IconEmptyCircle {...iconProps} />

        default:
          return <IconEmptyCircle {...iconProps} />
      }

    case null:
      switch (result) {
        case FlowTaskResult.DECLINED:
        case FlowTaskResult.SOLVED:
          return {
            icon: <IconQuestionAlt {...iconProps} />,
            status: FlowTaskStateStatusText.WAITING
          }

        default:
          return null
      }

    default:
      return null
  }
}

export const getFlowTaskStateColor = ({ result, state }: IDocumentTask): string => {
  const flowResult = String(result)
  const flowState = String(state)

  if (flowResult === FlowTaskResult.CANCELED) {
    return FlowTaskStatusColor.result.CANCELED
  }

  return flowState === FlowTaskState.COMPLETED
    ? FlowTaskStatusColor.result[flowResult]
    : FlowTaskStatusColor.state[flowState]
}

export const getFlowDocumentStatus = (
  state: Nullable<FlowDocumentState>,
  result: Nullable<FlowDocumentResult>,
  stage: IFlowStageType | undefined
): string => {
  switch (state) {
    case FlowDocumentState.COMPLETED:
      switch (result) {
        case FlowDocumentResult.DECLINED:
          return stage?.declinedCaption ?? FlowDocumentStatusText[FlowDocumentResult.DECLINED]

        case FlowDocumentResult.SOLVED:
          return stage?.solvedCaption ?? FlowDocumentStatusText[FlowDocumentResult.SOLVED]

        case FlowDocumentResult.STOPPED:
          return FlowDocumentStatusText[FlowDocumentResult.STOPPED]

        default:
          return ''
      }

    case FlowDocumentState.IN_PROGRESS:
      return stage?.name ?? FlowDocumentStatusText.IN_PROGRESS

    case FlowDocumentState.NOT_STARTED:
      return FlowDocumentStatusText.NOT_STARTED

    default:
      return ''
  }
}

export const getFlowTaskStatus = (
  state: Nullable<FlowTaskState>,
  result: Nullable<FlowTaskResult>,
  stage: IFlowStageType | undefined
): string => {
  if (result === FlowTaskResult.CANCELED) {
    return FlowTaskStateStatusText.CANCELED
  }

  switch (state) {
    case FlowTaskState.COMPLETED:
      switch (result) {
        case FlowTaskResult.DECLINED:
          return stage?.declinedCaption ?? FlowTaskStateStatusText.DECLINED

        case FlowTaskResult.SOLVED:
          return stage?.solvedCaption ?? FlowTaskStateStatusText.SOLVED

        default:
          return ''
      }

    case FlowTaskState.IN_PROGRESS:
      return stage?.name ?? FlowTaskStateStatusText.IN_PROGRESS

    case FlowTaskState.WAITING:
      return FlowTaskStateStatusText.WAITING

    default:
      return FlowTaskStateStatusText.WAITING
  }
}

export const getFlowTaskStateDate = ({
  completedTimestamp,
  startedTimestamp,
  state
}: IDocumentTask): Nullable<string> => {
  if (!state || state === FlowTaskState.WAITING) return null

  const dates = {
    [FlowTaskState.COMPLETED]: completedTimestamp,
    [FlowTaskState.IN_PROGRESS]: startedTimestamp
  }

  const date = dates[state]

  return date ? getFormattedDatetimeValue(date) : null
}

export const getAssigneeInfo = ({ assignedToUser, assignedToGroup }: IDocumentTask, t: TFunction): string =>
  assignedToUser
    ? getSurnameWithInitials(assignedToUser)
    : assignedToGroup
    ? assignedToGroup.name
    : t('document:noAssigneeInfo')

export const getAdditionalAssigneeInfo = ({ assignMode, assignedToUser }: IDocumentTask): string =>
  assignedToUser
    ? assignedToUser.position ?? ''
    : assignMode
    ? Route.assignMode[assignMode]
    : Route.assignMode[AssignMode.ANY]

export const getFlowStage = (flowStageType: string): IFlowStageType | undefined => {
  const { flowStageTypes } = store.getState().metadata

  return flowStageTypes.find(({ type }) => type === flowStageType)
}

export const getFlowItemInfo = (task: IDocumentTask, stage: IFlowStageType | undefined): IFlowStatusInfo => ({
  icon: getFlowTaskIcon(task.state, task.result, StatusIconPropsToFlowTab),
  date: getFlowTaskStateDate(task),
  color: getFlowTaskStateColor(task),
  status: getFlowTaskStatus(task.state, task.result, stage)
})

export const getPasswordViewButton = (
  currentType: string,
  setInputType: (value: SetStateAction<string>) => void,
  isError?: boolean
): ReactElement => {
  const rippleColor = isError ? ColorVar.DANGER500 : ColorVar.BRAND500
  const buttonIcon = currentType === InputType.PASSWORD ? <IconVisibleOff /> : <IconVisibleOn />

  const handleChangeInputType = (): void => {
    setInputType(currentType === InputType.PASSWORD ? InputType.TEXT : InputType.PASSWORD)
  }

  return (
    <Button
      classes='relative p-4'
      onClick={debounce(handleChangeInputType, Timeout.RIPPLE_EFFECT_ICON_BUTTON)}
      theme='icon'
    >
      {buttonIcon}
      <RippleEffect color={rippleColor} />
    </Button>
  )
}

export const getHistoryStateIcon = ({ group, severity }: IHistoryStateCode): ReactNode => {
  const icon = HistoryStateIcon[group]

  if (!icon) return null

  const iconProps: IIconProps = {
    ...HistoryStateIconProps,
    color: SeverityColor[severity]
  }

  return cloneElement(icon, iconProps)
}

export const transformUserInfoValueToNull = (value: Nullable<string>): Nullable<string> => (value !== '' ? value : null)

export const transformUserInfoValueToEmpty = (value: Nullable<string>): string => value ?? ''

export const updateProfileOrPassword = (updatedProfile: IUpdatedUserInfo, type: UpdatedSettingsType): void => {
  store
    .dispatch<any>(updateUserInfo(updatedProfile))
    .then((resp: AxiosResponse) => {
      if (SuccessCode.PUT.includes(resp.status) && type !== UpdatedSettingsType.LANGUAGE) {
        showSuccessNotification({
          content: [{ value: `notifications:${type}.success.content`, classes: 'text-center' }]
        })
      }

      store.dispatch(getUserInfo())
    })
    .catch(showErrorNotification)
}
