import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import {
  AccessTaskType,
  AllowedAction,
  DocumentDirection,
  FlowDocumentResult,
  FlowDocumentState,
  WorkflowStatus
} from '@consts/enumTypes'

import { Nullable } from '@types'
import { IApplicationState, IPagination, ISubOrg } from '@store/types/common'

export interface IOrganization {
  oguid: Nullable<string>
  inn: Nullable<string>
  kpp: Nullable<string>
  ogrn: Nullable<string>
  nameShort: string
}

export interface IInitiator {
  email: string
  oguid: Nullable<string>
  surname: string
  name: string
  patronymic: Nullable<string>
  position: string
}

export interface IContractorField {
  oguid: string
  inn: string
  kpp: string
  ogrn: string
  nameShort: string
}

export interface IDictionary {
  key: string
  value: string
}

export type IDocumentField = number | string | boolean | IContractorField | IDictionary

export interface IDocumentFields {
  [key: string]: Nullable<IDocumentField>
}

export interface IHistoryOrg {
  inn: Nullable<string>
  kpp: Nullable<string>
  nameShort: string
  ogrn: Nullable<string>
  oguid: Nullable<string>
}

export interface IHistoryUser {
  email: string
  name: string
  oguid: Nullable<string>
  patronymic: Nullable<string>
  position: string
  surname: string
}

export interface IHistory {
  comment: Nullable<string>
  historyStateCaption: string
  historyStateCode: string
  historyTimestamp: Nullable<number>
  orgWhereHappened: IHistoryOrg
  signatureAttachmentOguid: Nullable<string>
  subOrgName: Nullable<string>
  user: Nullable<IHistoryUser>
}

export interface IDocument {
  allowedActions: AllowedAction[]
  contractorOrg: Nullable<IOrganization>
  creator?: IInitiator
  direction: DocumentDirection
  documentId: number
  documentAttachmentOguid: string
  documentTimestamp: number
  fields: IDocumentFields
  flowResult: Nullable<FlowDocumentResult>
  flowStagesCurrentNumber?: number
  flowStagesTotalCount?: number
  flowStageType: Nullable<string>
  flowState: Nullable<FlowDocumentState>
  history: IHistory[]
  initiator: Nullable<IInitiator>
  isExternal: boolean
  isReadOnly: boolean
  isWorkflowFinished: Nullable<boolean>
  messageOguid?: string
  oguid: string
  packageDocumentCount?: number
  readOnlyFields: string[]
  relatedDocumentCount?: number
  subOrg: Nullable<ISubOrg>
  tasks?: IAccessTask[]
  type: string
  unreadTodosCount?: number
  workflowStatuses: Nullable<WorkflowStatus[]>
}

export interface IAccessTask {
  oguid: string
  type: AccessTaskType
}

// Экшны
export enum DocumentsActionTypes {
  RESET_DOCUMENTS = 'RESET_DOCUMENTS',
  SET_DOCUMENTS = 'SET_DOCUMENTS',
  UPDATE_DOCUMENT = 'UPDATE_DOCUMENT'
}

export interface IDocumentsAndPagination {
  documents: IDocument[]
  pagination: Nullable<IPagination>
}

export interface IActionResetDocuments extends Action<string> {
  type: DocumentsActionTypes.RESET_DOCUMENTS
}

export interface IActionSetDocuments extends Action<string> {
  payload: IDocumentsAndPagination
  type: DocumentsActionTypes.SET_DOCUMENTS
}

export interface IActionUpdateDocument extends Action<string> {
  payload: IDocument
  type: DocumentsActionTypes.UPDATE_DOCUMENT
}

export type TDocumentsActions = IActionResetDocuments | IActionSetDocuments | IActionUpdateDocument

export enum DocumentsActionKey {
  SET_DOCUMENTS = 'setDocuments',
  UPDATE_DOCUMENT = 'updateDocument'
}

export interface IDocumentsState {
  documents: IDocument[]
  pagination: Nullable<IPagination>
}

export type DocumentsActions = {
  [key in DocumentsActionKey]: ActionCreator<Action<string>>
}

export type DocumentsThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export type DocumentsThunkDispatch = ThunkDispatch<IDocumentsState, void, Action<string>>
