import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as ActionsIconSVG } from './actions-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconActions: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ActionsIconSVG />
  </Icon>
)
