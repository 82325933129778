import React, { FC } from 'react'
import Loadable from 'react-loadable'

import { AllRoles } from '@consts/common'
import { SkeletonType } from '@consts/enumTypes'
import { DefaultSubNavItem, Path } from '@consts/navItems'

import { SkeletonLayout } from '@common/skeletons/views'

import { TRoute } from '@containers/routing/RouteWithSubRoutes/types'

const SkeletonLayoutDocumentCard: FC = () => <SkeletonLayout type={SkeletonType.DOCUMENT} />
const SkeletonLayoutDocuments: FC = () => <SkeletonLayout type={SkeletonType.DOCUMENTS} />
const SkeletonLayoutMessages: FC = () => <SkeletonLayout type={SkeletonType.MESSAGES} />
const SkeletonLayoutTasks: FC = () => <SkeletonLayout type={SkeletonType.TASKS} />

const Document = Loadable({
  loader: () => import('@views/organization/Document'),
  loading: SkeletonLayoutDocumentCard
})

const Documents = Loadable({
  loader: () => import('@views/organization/Documents'),
  loading: SkeletonLayoutDocuments
})

const Tasks = Loadable({
  loader: () => import('@views/organization/Tasks'),
  loading: SkeletonLayoutTasks
})

const Messages = Loadable({
  loader: () => import('@views/organization/Messages'),
  loading: SkeletonLayoutMessages
})

export const routes: TRoute[] = [
  {
    path: Path.DOCUMENTS_ALL,
    exact: true,
    name: 'Documents',
    component: Documents,
    routeAdditionalProp: DefaultSubNavItem.ALL,
    roles: AllRoles
  },
  {
    path: Path.TASKS,
    exact: true,
    name: 'Agreement',
    component: Tasks,
    routeAdditionalProp: DefaultSubNavItem.TASKS,
    isFlowFunctionalityEnabled: true,
    roles: AllRoles
  },
  {
    path: Path.MESSAGES,
    exact: true,
    name: 'Messages',
    component: Messages,
    roles: AllRoles
  },
  // ToDo отключены, т.к. пока реальзация отсутствует
  // {
  //   path: Path.MESSAGES_INCOMING,
  //   exact: true,
  //   name: 'IncomingMessages',
  //   component: IncomingMessages,
  //   routeAdditionalProp: DefaultSubNavItem.INCOMING,
  //   roles: AllRoles,
  //   isExchangeParticipant: true
  // },
  // {
  //   path: Path.MESSAGES_OUTGOING,
  //   exact: true,
  //   name: 'OutgoingMessages',
  //   component: OutgoingMessages,
  //   routeAdditionalProp: DefaultSubNavItem.OUTGOING,
  //   roles: AllRoles,
  //   isExchangeParticipant: true
  // },
  {
    path: Path.DOCUMENT_CARD,
    exact: true,
    name: 'Document',
    component: Document,
    roles: AllRoles
  }
]
