import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as PlayIconSVG } from './play-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconPlay: FC<IProps> = ({ color = 'brand-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <PlayIconSVG />
  </Icon>
)
