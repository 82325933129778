import cn from 'classnames'

import { ContentType } from '@consts/enumTypes'
import { docLines } from './consts'

import { IContentListeners, IContentActions } from './types'

export const getClasses = (isRefreshing: boolean, type: ContentType, classes = ''): string => {
  const isDefault = type === ContentType.DEFAULT
  const isNotNavigation = type === ContentType.WITHOUT_NAVIGATION

  return cn(
    'content relative overflow-y-scroll',
    {
      ['content--refresh']: isRefreshing,
      'mt-4': isDefault || isNotNavigation
    },
    classes
  )
}

const getDocLines = (heightCorrection: number): string => {
  if (heightCorrection >= docLines[4].limit) return docLines[4].count

  if (heightCorrection >= docLines[3].limit) return docLines[3].count

  if (heightCorrection >= docLines[2].limit) return docLines[2].count

  return docLines[1].count
}

export const getSpinnerClasses = (type: ContentType, heightCorrection?: number | string): string => {
  const isDoc = type === ContentType.DOCUMENT
  const isFile = type === ContentType.FILE
  const isTabs = type === ContentType.WITH_TABS
  const isNotNavigation = type === ContentType.WITHOUT_NAVIGATION

  // const isHeight = heightCorrection && typeof heightCorrection === 'number'
  const countLines: string =
    heightCorrection && typeof heightCorrection === 'number' ? getDocLines(heightCorrection) : docLines[1].count

  return cn('absolute al-half trf-nx-half spinner--content', {
    [`spinner--content-doc${countLines}`]: isDoc,
    [`spinner--content-file${countLines}`]: isFile,
    'spinner--content-tabs': isTabs,
    'at-9': isNotNavigation,
    'at-4': !isDoc && !isFile && !isTabs && !isNotNavigation,
    'bg-light': !isDoc && !isFile
  })
}

export const getAddingListeners = (
  contentElement: HTMLDivElement,
  { startListener, motionListener, endListener }: IContentListeners,
  { startAction, motionAction, endAction }: IContentActions,
  isMouseListeners?: boolean
): void => {
  contentElement.addEventListener(startListener, startAction)

  if (!isMouseListeners) contentElement.addEventListener(motionListener, motionAction)

  contentElement.addEventListener(endListener, endAction)
}

export const getRemovingListeners = (
  contentElement: HTMLDivElement,
  { startListener, motionListener, endListener }: IContentListeners,
  { startAction, motionAction, endAction }: IContentActions
): void => {
  contentElement.removeEventListener(startListener, startAction)
  contentElement.removeEventListener(motionListener, motionAction)
  contentElement.removeEventListener(endListener, endAction)
}

export const getHeightCorrection = (value: number | string): string =>
  typeof value === 'number' ? `${value}px` : value
