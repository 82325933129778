import React, { FC, ReactElement } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useSelector } from 'react-redux'

import { Timeout } from '@consts/common'
import { Path, RouteName } from '@consts/navItems'

import {
  SkeletonDocuments,
  SkeletonDocumentCard,
  SkeletonLayout,
  SkeletonMessages,
  SkeletonSettings,
  SkeletonTasks
} from '@common/skeletons/views'

import { Nullable } from '@types'
import { IApplicationState } from '@store/types/common'
import { ISkeletonLoaderProps as IProps } from './types'

import './SkeletonLoader.scss'

const classes = {
  enter: 'skeleton-loader--enter',
  enterActive: 'skeleton-loader--enter-active',
  exit: 'skeleton-loader--exit',
  exitActive: 'skeleton-loader--exit-active'
}

const getSkeleton = (activeNavItemRoute: string, documentId: Nullable<number>): ReactElement => {
  switch (activeNavItemRoute) {
    case Path.TASKS:
      return <SkeletonTasks />

    case Path.MESSAGES:
      return <SkeletonMessages />

    case Path.DOCUMENTS_ALL:
      return <SkeletonDocuments />

    case Path.SETTINGS:
      return <SkeletonSettings />

    case `${RouteName.DOCUMENTS}/${String(documentId)}`:
      return <SkeletonDocumentCard />

    default:
      return <SkeletonLayout />
  }
}

export const SkeletonLoader: FC<IProps> = ({ loading }) => {
  const {
    document: { documentId },
    navigation: {
      activeNavItem: { route: activeNavItemRoute }
    }
  } = useSelector((state: IApplicationState) => state)

  return (
    <CSSTransition in={loading} timeout={Timeout.SHOW_SKELETON_LOADER} classNames={classes} unmountOnExit={true}>
      <div className='skeleton-loader'>{getSkeleton(activeNavItemRoute, documentId)}</div>
    </CSSTransition>
  )
}
