import React from 'react'

import { IconFlagBr, IconFlagEn, IconFlagRu } from '@common/icons'

export enum Language {
  EN = 'en_US',
  PT = 'pt_PT',
  RU = 'ru_RU'
}

export const LanguageToFingerprint = {
  [Language.EN]: 'en_US',
  [Language.PT]: 'pt',
  [Language.RU]: 'ru'
}

export const LanguageToBackend = {
  [Language.EN]: 'en',
  [Language.PT]: 'pt',
  [Language.RU]: 'ru'
}

export const LanguageIcon = {
  [Language.EN]: <IconFlagEn classes='mr-2' />,
  [Language.PT]: <IconFlagBr classes='mr-2' />,
  [Language.RU]: <IconFlagRu classes='mr-2' />
}

export const LANGUAGES = [Language.EN, Language.PT, Language.RU]

export const NS = [
  'auth',
  'common',
  'document',
  'documents',
  'messages',
  'notifications',
  'organizations',
  'password',
  'profile',
  'settings',
  'tasks',
  'translation'
]
