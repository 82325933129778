import React from 'react'

import {
  AllowedAction,
  CommentType,
  DevicePlatform,
  DeviceType,
  EnviropmentApp,
  FlowTaskResult,
  FlowTaskState,
  HistoryStateCodeGroupType,
  Severity,
  ThemeType
} from '@consts/enumTypes'

import {
  IconAgreement,
  IconChange,
  IconDelete,
  IconIncoming,
  IconOutgoing,
  IconPlay,
  IconReject,
  IconStateApproving,
  IconStateCreating,
  IconStateDeclining,
  IconStateQuestion,
  IconStateReceiving,
  IconStateSending,
  IconStateSigning,
  IconStateStop,
  IconStateUnknown
} from '@common/icons'

import { IParams } from '@store/types/common'
import { IIconProps } from '@common/Icon/types'

export const BASE_URL =
  process.env.REACT_APP_API_ENV === EnviropmentApp.DEV
    ? 'https://im-api.df-backend-dev.dev.info-logistics.eu/'
    : 'https://api.docuforce.infologistics.ru/'

export const CLIENT_ID = 'ru.infologistics.docuforce'

export const SENTRY_DSN = 'https://827fff0a4ab743b7bee017f829b263fe@sentry.infologistics.ru/14'

// ToDo https://3.basecamp.com/4382260/buckets/21311268/todos/3785182913
export const isNotMobileDevice = !(window as any).cordova

export const DEVICE_PLATFORM_ID: string = isNotMobileDevice
  ? DevicePlatform.BROWSER
  : (window as any).cordova.platformId

export const SuccessCode = {
  GET: 200,
  POST: [200, 201, 204],
  PUT: [200, 201, 204],
  DELETE: [200, 202, 204]
}

export const ResponseCode = {
  DEL: 204,
  GET: 200,
  POST: 201,
  PUT: 201,
  ERROR: 400
}

export const ErrorCode = {
  NOT_AUTH: 401,
  NOT_FOUND: 404
}

export const MILLISECONDS_IN_SECOND = 1000

export const PIN_CODE_LENGTH = 4

// Для ролевой модели енам не годится
export const Role = {
  USER: 'USER',
  ORGANIZATION: 'ORGANIZATION',
  ADMINISTRATOR: 'ADMINISTRATOR'
}

export const AllRoles = [Role.USER, Role.ORGANIZATION, Role.ADMINISTRATOR]

/**
 * Используется в ситуациях, когда не нужно, чтобы ошибка в запросе приводила к редиректу на 404-ю страницу.
 * Например, при загрузке данных для опций в селекте
 */
export const needResetParams: IParams = { isNeedResetError: true }

export enum DateFormat {
  DATE = 'dd.MM.yy',
  DATE_FULL_YEAR = 'dd.MM.yyyy',
  DATETIME = 'dd.MM.yy HH:mm',
  DATETIME_FULL_YEAR = 'dd.MM.yyyy HH:mm'
}

export enum TimeZoneAffix {
  GMT = 'GMT',
  UTC = 'Etc/UTC',
  Z = 'Z'
}

export enum Space {
  EM = '\u2003',
  EN = '\u2002',
  NON_BREAK = '\u00a0',
  STANDART = '\u0020',
  THIN = '\u2009'
}

export const RoleModelRestrictionKey = {
  IS_FLOW_FUNCTIONALITY_ENABLED: 'isFlowFunctionalityEnabled',
  IS_EXCHANGE_PARTICIPANT: 'isExchangeParticipant',
  IS_READONLY: 'isReadonly'
}

export const RoleModelRestrictionKeys = [
  RoleModelRestrictionKey.IS_FLOW_FUNCTIONALITY_ENABLED,
  RoleModelRestrictionKey.IS_EXCHANGE_PARTICIPANT,
  RoleModelRestrictionKey.IS_READONLY
]

export const PER_PAGE_ITEMS_DEFAULT = 25

export enum PaginationProperty {
  PER = 'x-per-page',
  NEXT = 'x-next-page',
  PAGE = 'x-page',
  PREV = 'x-prev-page',
  TOTAL = 'x-total'
}

export enum Character {
  AT = '@',
  COLON = ':',
  DOT = '.',
  EM_DASH = '—',
  MINUS = '-',
  NUMBER = '№',
  PLUS = '+',
  QUESTION = '?',
  SLASH = '/',
  UNDERSCORE = '_'
}

export enum CurrencyCharacter {
  EUR = '€',
  RUB = '₽',
  USD = '$'
}

export enum Key {
  ENTER = 'Enter',
  ESC = 'Escape',
  ARROW_DOWN = 'ArrowDown',
  ARROW_UP = 'ArrowUp',
  TAB = 'Tab'
}

export const SUGGEST_RECIPIENT_MIN_LENGTH_FOR_REQUEST = 3

export enum HeightVar {
  HEADER = 'var(--height-header)',
  HEADER_WITH_SPACE = 'var(--height-header-with-space)',
  INPUT = 'var(--height-input-base)',
  NAVIGATION = 'var(--height-navigation)',
  TABS_WITH_SPACE = 'var(--height-tabs)'
}

export enum HeightVarIOS {
  HEADER = 'calc(var(--height-header) + var(--safe-area-inset-top))',
  HEADER_WITH_SPACE = 'calc(var(--height-header-with-space) + var(--safe-area-inset-top))',
  NAVIGATION = 'calc(var(--height-navigation) + var(--safe-area-inset-bottom))'
}

export enum Modal {
  CONFIRM_PASSWORD_TO_PIN_CODE = 'CONFIRM_PASSWORD_TO_PIN_CODE',
  CONFIRM_PASSWORD_TO_FINGERPRINT = 'CONFIRM_PASSWORD_TO_FINGERPRINT',
  CONFIRM_PASSWORD_TO_FACEID = 'CONFIRM_PASSWORD_TO_FACEID',
  FILE = 'FILE',
  MESSAGE_ACTIONS = 'MESSAGE_ACTIONS',
  MESSAGE_NEW = 'MESSAGE_NEW'
}

export enum Timeout {
  CLOSE_LOADER = 300,
  DOWNLOAD_FILE = 300,
  RIPPLE_EFFECT_ACTION = 600,
  RIPPLE_EFFECT_DEFAULT = 700,
  RIPPLE_EFFECT_DROPDOWN = 200,
  RIPPLE_EFFECT_ICON_BUTTON = 350,
  RIPPLE_EFFECT_NOTIFICATION_BUTTON = 700,
  RIPPLE_EFFECT_NAVIGATION_BUTTON = 250,
  RIPPLE_EFFECT_SETTINGS_BLOCK = 350,
  RIPPLE_EFFECT_TAB = 200,
  RIPPLE_EFFECT_VIEWING_FILE = 350,
  RIPPLE_EFFECT_VIEWS_LIST_ITEM = 600,
  SHOW_NOTIFICATION = 300,
  SHOW_SKELETON_LOADER = 400,
  UPDATE_BADGES = 60000
}

export enum Duration {
  RIPPLE_EFFECT_ACTION = 1100,
  RIPPLE_EFFECT_DEFAULT = 700,
  RIPPLE_EFFECT_DROPDOWN = 200,
  RIPPLE_EFFECT_ICON_BUTTON = 700,
  RIPPLE_EFFECT_NAVIGATION_LINK = 1100,
  RIPPLE_EFFECT_NOTIFICATION_BUTTON = 700,
  RIPPLE_EFFECT_OPEN_FILE_WITH = 1100,
  RIPPLE_EFFECT_SETTINGS_BLOCK = 1100,
  RIPPLE_EFFECT_VIEWING_FILE = 1100,
  RIPPLE_EFFECT_VIEWS_LIST_ITEM = 1100
}

export const MAXIMUM_BADGES_QUANTITY = 99

export const SeverityColor = {
  [Severity.ERROR]: 'danger-500',
  [Severity.INFO]: 'info-500',
  [Severity.SUCCESS]: 'success-500',
  [Severity.UNKNOWN]: 'muted-500',
  [Severity.WARNING]: 'warning-500'
}

export const FlowTaskStatusColor = {
  state: {
    [FlowTaskState.COMPLETED]: 'success',
    [FlowTaskState.IN_PROGRESS]: 'warning',
    [FlowTaskState.WAITING]: 'muted',
    null: 'muted'
  },
  result: {
    [FlowTaskResult.CANCELED]: 'danger',
    [FlowTaskResult.DECLINED]: 'danger',
    [FlowTaskResult.SOLVED]: 'success',
    null: 'muted'
  }
}

export const StatusIconPropsToList: IIconProps = {
  classes: 'mr-2',
  size: 'sm'
}

export const StatusIconPropsToFlowTab: IIconProps = {
  classes: 'mr-2',
  size: 'sm'
}

export const HistoryStateIconProps: IIconProps = {
  type: 'solid',
  style: 'round'
}

/**
 * Массив для упорядочивания доступных IAllowedAction для карточки документа, действия:
 * - MODIFY,
 * - DELETE,
 * - STOP_PROCESS
 * - SIGN_TICKET,
 * - CORRECTION_REQUEST
 * убраны по отсутствию реализации или иным причинам
 */
export const TEMPLATE_DOCUMENT_COMMON_ACTIONS: AllowedAction[] = [
  AllowedAction.START_PROCESS,
  AllowedAction.APPROVE,
  AllowedAction.DECLINE_APPROVE,
  AllowedAction.SIGN,
  AllowedAction.SIGN_TITLE,
  AllowedAction.DECLINE_SIGN
]

export const DocumentActionTheme = {
  [AllowedAction.START_PROCESS]: ThemeType.BRAND,
  [AllowedAction.APPROVE]: ThemeType.SUCCESS,
  [AllowedAction.DECLINE_APPROVE]: ThemeType.DANGER,
  [AllowedAction.DECLINE_SIGN]: ThemeType.DANGER,
  [AllowedAction.SIGN]: ThemeType.SUCCESS
}

export const DocumentActionIcon = {
  [AllowedAction.APPROVE]: <IconAgreement color='success-500' />,
  [AllowedAction.DECLINE_APPROVE]: <IconReject />,
  [AllowedAction.DECLINE_SIGN]: <IconReject />,
  [AllowedAction.DELETE]: <IconDelete color='danger-500' />,
  [AllowedAction.MODIFY]: <IconChange />,
  [AllowedAction.SIGN]: <IconAgreement color='success-500' />,
  [AllowedAction.SIGN_TITLE]: <IconAgreement color='success-500' />,
  [AllowedAction.START_PROCESS]: <IconPlay />
}

export const HistoryStateIcon = {
  [HistoryStateCodeGroupType.APPROVING]: <IconStateApproving />,
  [HistoryStateCodeGroupType.CREATING]: <IconStateCreating />,
  [HistoryStateCodeGroupType.DECLINING]: <IconStateDeclining />,
  [HistoryStateCodeGroupType.QUESTION]: <IconStateQuestion />,
  [HistoryStateCodeGroupType.RECEIVING]: <IconStateReceiving />,
  [HistoryStateCodeGroupType.SENDING]: <IconStateSending />,
  [HistoryStateCodeGroupType.SIGNING]: <IconStateSigning />,
  [HistoryStateCodeGroupType.STOP]: <IconStateStop />,
  [HistoryStateCodeGroupType.UNKNOWN]: <IconStateUnknown />
}

export const DirectionIcon = {
  [CommentType.INCOMING]: <IconIncoming {...HistoryStateIconProps} />,
  [CommentType.OUTGOING]: <IconOutgoing {...HistoryStateIconProps} />
}

export const AnimationClass = {
  FADE_IN: 'fadeIn',
  FADE_OUT: 'fadeOut'
}

export enum ColorVar {
  BRAND500 = 'var(--brand-500)',
  DANGER500 = 'var(--danger-500)',
  LIGHT = 'var(--light)',
  PRIMARY500 = 'var(--primary-500)'
}

export const ColorTheme = {
  [ThemeType.BRAND]: 'var(--brand-500)',
  [ThemeType.DANGER]: 'var(--danger-500)',
  [ThemeType.DEFAULT]: 'var(--muted-500)',
  [ThemeType.INFO]: 'var(--info-500)',
  [ThemeType.PRIMARY]: 'var(--primary-500)',
  [ThemeType.SUCCESS]: 'var(--success-500)',
  [ThemeType.WARNING]: 'var(--warning-500)'
}

export const DeviceTypeByPlatform = {
  [DevicePlatform.ANDROID]: DeviceType.ANDROID,
  [DevicePlatform.BROWSER]: DeviceType.BROWSER,
  [DevicePlatform.IOS]: DeviceType.IOS
}

export const iOSPushPermissionRequest = {
  TIMEOUT: 10,
  INTERVAL: 0.3
}

export enum Extension {
  PDF = 'pdf'
}

export const PinCodeLabels = [0, 1, 2, 3]

export enum Browser {
  IOS = 'ios'
}
