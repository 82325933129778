import React, { FC } from 'react'

import {
  SkeletonHeaderTitle,
  SkeletonIcon,
  SkeletonPinCodeKeyBoard,
  SkeletonPinCodeLabel
} from '@common/skeletons/components'

import '@views/auth/SetPinCode/SetPinCode.scss'

const formClasses = 'set-pin-code set-pin-code--ios d-flex flex-column justify-content-between'

export const SkeletonSetPinCode: FC = () => (
  <div className={formClasses}>
    <header className='d-flex align-items-center width-full'>
      <SkeletonIcon classes='m-4' />
      <SkeletonHeaderTitle />
    </header>

    <SkeletonPinCodeLabel />

    <SkeletonPinCodeKeyBoard />
  </div>
)
