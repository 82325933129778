import React, { Component, ReactNode } from 'react'
import { isEqual } from 'lodash'
import { withRouter } from 'react-router'

import { TEMPLATE_DOCUMENT_COMMON_ACTIONS } from '@consts/common'
import { ThemeType } from '@consts/enumTypes'
import { navSettings, NAV_SETTINGS_CHILDREN, NAV_DOCS, RouteName } from '@consts/navItems'

import { createUrl, removeUnavailableNavItems, sortActions } from '@utils/utils'

import { Navigation } from '@common/Navigation'
import { NavigationItem } from '@common/NavigationItem'
import CustomNavLink from '../CustomNavLink'
import DocumentActions from '../DocumentActions'
import SignOutButton from '../SignOutButton'
import QuestionButton from '../QuestionButton'

import { INavItem } from '@types'
import { INavigationItem } from '@common/NavigationItem/types'
import { TNavigationProps as IProps } from './types'

class NavigationWrapper extends Component<IProps> {
  public componentDidMount (): void {
    this.getNavItems()
  }

  public componentDidUpdate (prevProps: Readonly<IProps>): void {
    const {
      isAuthenticated,
      isFlowFunctionalityEnabled,
      location: { pathname }
    } = this.props

    const {
      isFlowFunctionalityEnabled: prevFlowFunctionalityEnabled,
      location: { pathname: prevPathname }
    } = prevProps

    if (isAuthenticated && pathname !== prevPathname) this.props.resetError404()

    if (!isEqual(isFlowFunctionalityEnabled, prevFlowFunctionalityEnabled)) this.getNavItems()
  }

  public render (): ReactNode {
    const { activeNavItem, document, navItems } = this.props

    if (NAV_SETTINGS_CHILDREN.filter(({ route }) => route === activeNavItem.route).length) return null

    if (activeNavItem.route === navSettings.route) {
      return (
        <Navigation>
          <SignOutButton />
        </Navigation>
      )
    }

    if (document && activeNavItem.route.match(`${RouteName.DOCUMENTS}/${document.documentId}`)) {
      const filteredActions = sortActions(TEMPLATE_DOCUMENT_COMMON_ACTIONS)

      return (
        <Navigation>
          {filteredActions.length ? <DocumentActions actions={filteredActions} /> : null}
          <QuestionButton />
        </Navigation>
      )
    }

    const extendedNavItems = this.getExtendedNavItems(navItems)

    return (
      <Navigation>
        {extendedNavItems.map((item) => (
          <NavigationItem key={item.uuid} {...item} />
        ))}
      </Navigation>
    )
  }

  private readonly getExtendedNavItems = (navItems: INavItem[]): INavigationItem[] => {
    const { activeNavItem, location } = this.props

    const activePathname = activeNavItem.route || location.pathname
    const activeNavItems = navItems.filter(({ route }) => activePathname.includes(route))

    const activeNavItemUuid = activeNavItems.length ? activeNavItems[0].uuid : ''

    return navItems.map((item): INavigationItem => this.getExtendedNavItem(item, activeNavItemUuid))
  }

  private readonly getExtendedNavItem = (navItem: INavItem, activeNavItemUuid: string): INavigationItem => {
    const { icon, route, title, uuid } = navItem

    const isActive = activeNavItemUuid === uuid
    const theme = isActive ? ThemeType.BRAND : ThemeType.DEFAULT

    return {
      customNavItem: <CustomNavLink key={uuid} icon={icon} theme={theme} title={title} to={route} />,
      uuid
    }
  }

  private readonly getNavItems = (): void => {
    const {
      history,
      isAuthenticated,
      isExchangeParticipant,
      isFlowFunctionalityEnabled,
      isReadonly,
      openedFromNotification,
      orgOguid,
      onSetNavItems,
      prevActiveOrgOguid,
      role
    } = this.props

    const user = {
      role,
      isExchangeParticipant,
      isFlowFunctionalityEnabled,
      isReadonly
    }

    const availableNavItems = removeUnavailableNavItems(NAV_DOCS, user)

    onSetNavItems(availableNavItems)

    // ToDo Решение не подходят для развертывания web-версии, + дублируется по сути функция setStartPage() - надо переделать
    // ToDo https://3.basecamp.com/4382260/buckets/21311268/todos/4148240879
    if (isAuthenticated && !openedFromNotification && availableNavItems && prevActiveOrgOguid) {
      history.push(createUrl(orgOguid, availableNavItems[0].route))
    }
  }
}

export default withRouter(NavigationWrapper)
