import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import navigationService from '@services/navigation'

import { ResponseCode } from '@consts/common'

import { INavItem, Nullable } from '@types'

import {
  IActiveNavItem,
  IActionResetNavigation,
  IActionSetActiveNavItem,
  IActionSetBadges,
  IActionSetOpenedFromNotification,
  IActionSetNavItems,
  IBadges,
  NavigationActionKey,
  NavigationActions,
  NavigationActionTypes,
  NavigationThunkAction,
  NavigationThunkDispatch,
  IActionSetPrevNavRoute
} from './types'

// actions
export const resetNavigation: IActionResetNavigation = {
  type: NavigationActionTypes.RESET_NAVIGATION
}

export const setActiveNavItemAction: ActionCreator<Action> = (
  activeNavItem: IActiveNavItem
): IActionSetActiveNavItem => ({
  payload: activeNavItem,
  type: NavigationActionTypes.SET_ACTIVE_NAV_ITEM
})

const setBadgesAction: ActionCreator<Action> = (badges: IBadges): IActionSetBadges => ({
  payload: badges,
  type: NavigationActionTypes.SET_BADGES
})

const setNavItemsAction = (navItems: INavItem[]): IActionSetNavItems => ({
  payload: navItems,
  type: NavigationActionTypes.SET_NAV_ITEMS
})

const setOpenedFromNotificationAction = (fromNotification: Nullable<string>): IActionSetOpenedFromNotification => ({
  payload: fromNotification,
  type: NavigationActionTypes.SET_OPENED_FROM_NOTIFICATION
})

const setPrevNavRouteAction = (prevNavRoute: Nullable<string>): IActionSetPrevNavRoute => ({
  payload: prevNavRoute,
  type: NavigationActionTypes.SET_PREV_NAV_ROUTE
})

export const navigationActions: NavigationActions = {
  [NavigationActionKey.SET_ACTIVE_NAV_ITEM]: setActiveNavItemAction,
  [NavigationActionKey.SET_BADGES]: setBadgesAction,
  [NavigationActionKey.SET_NAV_ITEMS]: setNavItemsAction,
  [NavigationActionKey.SET_OPENED_FROM_NOTIFICATION]: setOpenedFromNotificationAction,
  [NavigationActionKey.SET_PREV_NAV_ROUTE]: setPrevNavRouteAction
}

// thunks
export const getBadges: ActionCreator<NavigationThunkAction> = () => (dispatch: NavigationThunkDispatch) =>
  navigationService
    .getBadges()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(navigationActions[NavigationActionKey.SET_BADGES](resp.data))
      }

      return resp
    })
    .catch(Promise.reject)
