import React, { FC } from 'react'

import { Icon } from '../../Icon'

import { IIconProps as IProps } from '../../Icon/types'

import { ReactComponent as ArrowDownIconSVG } from './arrow-down-icon.svg'

export const IconArrowDown: FC<IProps> = ({ color = 'text-color', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ArrowDownIconSVG />
  </Icon>
)
