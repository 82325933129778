import React, { Component, createRef, ReactNode, RefObject } from 'react'

import { NextPageType } from '@consts/enumTypes'

import { SkeletonDocumentListItem, SkeletonMessageListItem, SkeletonTaskListItem } from '@common/skeletons/components'
import { CommonLoader } from '../CommonLoader'

import { INextPageLoaderProps as IProps } from './types'

const observerOptions = {
  root: null,
  rootMargin: '0px',
  threshold: 0
}

export class NextPageLoader extends Component<IProps> {
  public loadingRef: RefObject<HTMLDivElement>

  public observer: IntersectionObserver

  constructor (props: IProps) {
    super(props)

    this.loadingRef = createRef<HTMLDivElement>()
    this.observer = new IntersectionObserver(this.handleObserver.bind(this), {
      ...observerOptions,
      threshold: props.threshold ?? observerOptions.threshold
    })
  }

  public componentDidMount (): void {
    const loadingRef = this.loadingRef.current

    if (loadingRef instanceof HTMLDivElement) {
      this.observer.observe(loadingRef)
    }
  }

  public componentWillUnmount (): void {
    const loadingRef = this.loadingRef.current

    if (this.observer && loadingRef instanceof HTMLDivElement) {
      this.observer.unobserve(loadingRef)
    }
  }

  public render (): ReactNode {
    const { isNextPage } = this.props

    return isNextPage && this.rendePlaceholder()
  }

  private readonly rendePlaceholder = (): ReactNode => {
    const { classes, type } = this.props

    switch (type) {
      case NextPageType.DOCUMENT: {
        return <SkeletonDocumentListItem ref={this.loadingRef} withTopBorder />
      }

      case NextPageType.MESSAGE: {
        return <SkeletonMessageListItem ref={this.loadingRef} withTopBorder />
      }

      case NextPageType.TASK: {
        return <SkeletonTaskListItem ref={this.loadingRef} withTopBorder />
      }

      default: {
        return <CommonLoader classes={classes} ref={this.loadingRef} />
      }
    }
  }

  private readonly handleObserver = (entities: IntersectionObserverEntry[]): void => {
    const { onGetData } = this.props

    if (entities[0].isIntersecting) onGetData()
  }
}
