import Loadable from 'react-loadable'

import { AllRoles } from '@consts/common'
import { Path } from '@consts/navItems'

import { SkeletonSettings } from '@common/skeletons/views'

import { TRoute } from '@containers/routing/RouteWithSubRoutes/types'

const Languages = Loadable({
  loader: () => import('@views/settings/Languages'),
  loading: SkeletonSettings
})

const Organizations = Loadable({
  loader: () => import('@views/settings/Organizations'),
  loading: SkeletonSettings
})

const Password = Loadable({
  loader: () => import('@views/settings/Password'),
  loading: SkeletonSettings
})

const Profile = Loadable({
  loader: () => import('@views/settings/Profile'),
  loading: SkeletonSettings
})

const Settings = Loadable({
  loader: () => import('@views/settings/Settings'),
  loading: SkeletonSettings
})

export const routes: TRoute[] = [
  {
    path: Path.LANGUAGES,
    exact: true,
    name: 'Languages',
    component: Languages,
    roles: AllRoles
  },
  {
    path: Path.ORGANIZATIONS,
    exact: true,
    name: 'Organizations',
    component: Organizations,
    roles: AllRoles
  },
  {
    path: Path.PASSWORD,
    exact: true,
    name: 'Password',
    component: Password,
    roles: AllRoles
  },
  {
    path: Path.PROFILE,
    exact: true,
    name: 'Profile',
    component: Profile,
    roles: AllRoles
  },

  {
    path: Path.SETTINGS,
    exact: true,
    name: 'Settings',
    component: Settings,
    roles: AllRoles
  }
]
