import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Modal } from '@consts/common'
import { NAV_DOCS } from '@consts/navItems'

import { navigateBackPage } from '@utils/utils'

import { Header } from '@common/Header'
import { BackButton } from '../BackButton'
import { DownloadButton } from './components/DownloadButton'
import { SettingsButton } from './components/SettingsButton'
import MessagesButton from './components/MessagesButton'

import { closeModal } from '@store/modules/modal/actions'

import { IApplicationState } from '@store/types/common'
import { IHeaderWrapperParams } from './types'

const HeaderWrapper: FC = () => {
  const {
    modal,
    navigation: { activeNavItem }
  } = useSelector((state: IApplicationState) => state)

  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()

  const isDocs = NAV_DOCS.find(({ route }) => activeNavItem.route === route)
  const isFile = modal.id === Modal.FILE

  const getParams = (): IHeaderWrapperParams => {
    if (isFile) {
      return {
        onClick: () => dispatch(closeModal),
        title: 'document:viewingDocument'
      }
    }

    return {
      onClick: () => navigateBackPage(history),
      title: activeNavItem.title
    }
  }

  const { onClick, title } = getParams()

  return (
    <Header>
      {isDocs ? <SettingsButton /> : <BackButton onClick={onClick} />}
      <h2 className='fw-500'>{t(title)}</h2>
      {isDocs && <MessagesButton />}
      {isFile && <DownloadButton />}
    </Header>
  )
}

export default HeaderWrapper
