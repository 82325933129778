import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios'

import i18next from './../i18next'

import store from '@store/configureStore'

import { BASE_URL, ErrorCode, isNotMobileDevice } from '@consts/common'
import { AuthError } from '@consts/enumTypes'
import { LanguageToBackend } from '@consts/languages'

import * as jwt from '@utils/jwt'
import { setAuthErrorToStorage } from '@utils/localStorage'

import { UtilsActionTypes } from '@store/modules/utils/types'

class Http {
  private isNeedResetError = {}
  private readonly subscribers: any[] = []
  private readonly axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    responseType: 'json'
  })

  constructor () {
    this.addInterceptors()
  }

  private addInterceptors (): void {
    this.axiosInstance.interceptors.request.use((config) => {
      const { url } = config
      if (config.params?.isNeedResetError && url) {
        this.isNeedResetError[url] = true

        delete config.params.isNeedResetError
      }

      return config
    })

    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        const { url } = response.config

        if (url) {
          delete this.isNeedResetError[url]
        }

        return response
      },
      (error: any) => {
        const {
          config,
          response: { status }
        } = error

        const originalRequest = config

        if (originalRequest) {
          const { url } = originalRequest

          if (url.includes('signin') && status === ErrorCode.NOT_AUTH) {
            return Promise.reject(error)
          }
        }

        if (status === ErrorCode.NOT_AUTH) {
          isNotMobileDevice && jwt.clear()
          !isNotMobileDevice && setAuthErrorToStorage(AuthError.YES)
          document.location.reload()

          return new Promise((resolve: any) => {
            const subscriber = (): void => {
              originalRequest.headers['Access-Token'] = jwt.get().accessToken
              resolve(axios(originalRequest))
            }

            this.subscribers.push(subscriber)
          })
        }

        const { url } = originalRequest

        if (status === ErrorCode.NOT_FOUND && !this.isNeedResetError[url]) {
          store.dispatch({ type: UtilsActionTypes.SET_ERROR404 })
        }

        delete this.isNeedResetError[url]

        return Promise.reject(error)
      }
    )
  }

  private setHeaders (config: AxiosRequestConfig): void {
    const { headers = {} } = config

    const { accessToken } = store.getState().user

    if (accessToken) {
      headers['Access-Token'] = accessToken
    }

    config.headers = {
      ...headers,
      ['Accept-Language']:
        LanguageToBackend[
          !store.getState().user.user.locale
            ? i18next.language
            : store.getState().user.user.locale
        ]
    }
  }

  private updateBaseURL (config: AxiosRequestConfig): void {
    const orgOguid = store.getState().user.activeOrg.oguid

    config.baseURL = `${BASE_URL}orgs/${orgOguid}/`
  }

  async get (url: string, config: AxiosRequestConfig = {}, addOrgOguid = true): Promise<any> {
    this.setHeaders(config)

    addOrgOguid && this.updateBaseURL(config)

    return await this.axiosInstance.get(url, config)
  }

  async post (url: string, data: any, config: AxiosRequestConfig = {}, addOrgOguid = true): Promise<any> {
    this.setHeaders(config)
    addOrgOguid && this.updateBaseURL(config)

    return await this.axiosInstance.post(url, data, config)
  }

  async put (url: string, data: any, config: AxiosRequestConfig = {}, addOrgOguid = true): Promise<any> {
    this.setHeaders(config)
    addOrgOguid && this.updateBaseURL(config)

    return await this.axiosInstance.put(url, data, config)
  }

  async delete (url: string, config: AxiosRequestConfig = {}, addOrgOguid = true): Promise<any> {
    this.setHeaders(config)
    addOrgOguid && this.updateBaseURL(config)

    return await this.axiosInstance.delete(url, config)
  }
}

export default new Http()
