import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { isNotMobileDevice } from '@consts/common'

import { openInitialPushNotification } from '@utils/pushNotifications'
import { setStartPage } from '@utils/utils'

import RedirectToSigninPage from '../RedirectToSigninPage'

import { IApplicationState } from '@store/types/common'

const RedirectToStartPage: FC = () => {
  const history = useHistory()

  const { isAuthenticated } = useSelector((state: IApplicationState) => state.user)

  useEffect(() => {
    if (isAuthenticated) {
      isNotMobileDevice ? setStartPage(history) : openInitialPushNotification(history)
    }
  }, [])

  return isAuthenticated ? null : <RedirectToSigninPage />
}

export default RedirectToStartPage
