import { AxiosResponse } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IBadges } from '@store/modules/navigation/types'

class NavigationService extends AbstractHttpService {
  async getBadges (): Promise<AxiosResponse<IBadges>> {
    const url = urls.navigation.badges

    const response: AxiosResponse<IBadges> = await this._http.get(url)

    return response
  }
}

export default new NavigationService()
