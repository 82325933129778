import { HeightVar, HeightVarIOS } from '@consts/common'
import { ContentType, ListenerType } from '@consts/enumTypes'

import { getHeightCorrection } from './utils'

import { IContentListeners, IContentState as IState } from './types'
import { isPlatformIOS } from '@utils/device'

export const PREVENT_TAP_LAG = 32

export const HEADER_HEIGHT_WITH_SPACE = 72

export const initialState: IState = {
  firstChildYStart: HEADER_HEIGHT_WITH_SPACE,
  isRefreshing: false,
  tapYCurrent: 0,
  tapYStart: 0
}

export const touchListeners: IContentListeners = {
  startListener: ListenerType.TOUCH_START,
  motionListener: ListenerType.TOUCH_MOVE,
  endListener: ListenerType.TOUCH_END
}

export const mouseListeners: IContentListeners = {
  startListener: ListenerType.MOUSE_DOWN,
  motionListener: ListenerType.MOUSE_MOVE,
  endListener: ListenerType.MOUSE_UP
}

const HeightToCalc = {
  WITHOUT_HEADER: `100vh - ${HeightVar.HEADER}`,
  WITHOUT_HEADER_WITH_SPACE: `100vh - ${HeightVar.HEADER_WITH_SPACE}`,
  WITHOUT_PANELS: `100vh - ${HeightVar.HEADER} - ${HeightVar.NAVIGATION} - ${HeightVar.TABS_WITH_SPACE}`
}

const HeightToCalcIOS = {
  WITHOUT_HEADER: `100vh - ${HeightVarIOS.HEADER}`,
  WITHOUT_HEADER_WITH_SPACE: `100vh - ${HeightVarIOS.HEADER_WITH_SPACE}`,
  WITHOUT_PANELS: `100vh - ${HeightVarIOS.HEADER} - ${HeightVarIOS.NAVIGATION} - ${HeightVar.TABS_WITH_SPACE}`
}

export const styleHeight = {
  [ContentType.DEFAULT]: () =>
    isPlatformIOS()
      ? `calc(${HeightToCalcIOS.WITHOUT_HEADER_WITH_SPACE} - ${HeightVarIOS.NAVIGATION})`
      : `calc(${HeightToCalc.WITHOUT_HEADER_WITH_SPACE} - ${HeightVar.NAVIGATION})`,

  [ContentType.DOCUMENT]: (value: number | string) =>
    isPlatformIOS()
      ? `calc(${HeightToCalcIOS.WITHOUT_PANELS} - ${getHeightCorrection(value)})`
      : `calc(${HeightToCalc.WITHOUT_PANELS} - ${getHeightCorrection(value)})`,

  [ContentType.FILE]: (value: number | string) =>
    `calc(${HeightToCalc.WITHOUT_HEADER_WITH_SPACE} - ${HeightVar.NAVIGATION} - ${getHeightCorrection(value)})`,

  [ContentType.MESSAGE_NEW]: (value: number | string) =>
    isPlatformIOS()
      ? `calc(${HeightToCalcIOS.WITHOUT_HEADER} - ${HeightVarIOS.NAVIGATION} - ${getHeightCorrection(value)})`
      : `calc(${HeightToCalc.WITHOUT_HEADER} - ${HeightVar.NAVIGATION} - ${getHeightCorrection(value)})`,

  [ContentType.WITH_TABS]: () =>
    isPlatformIOS() ? `calc(${HeightToCalcIOS.WITHOUT_PANELS})` : `calc(${HeightToCalc.WITHOUT_PANELS})`,

  [ContentType.WITHOUT_NAVIGATION]: () =>
    isPlatformIOS()
      ? `calc(${HeightToCalcIOS.WITHOUT_HEADER_WITH_SPACE})`
      : `calc(${HeightToCalc.WITHOUT_HEADER_WITH_SPACE})`
}

export const docLines = {
  1: {
    limit: 36,
    count: '1'
  },
  2: {
    limit: 64,
    count: '2'
  },
  3: {
    limit: 92,
    count: '3'
  },
  4: {
    limit: 120,
    count: '4'
  }
}
