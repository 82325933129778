import { connect } from 'react-redux'

import SignOutButton from './SignOutButton'

import { logout } from '@store/modules/root/actions'

import { ISignOutButtonPropsFromDispatch as IPropsFromDispatch } from './types'

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onLogout: () => dispatch(logout())
})

export default connect(null, mapDispatchToProps)(SignOutButton)
