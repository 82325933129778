import cn from 'classnames'
import React, { Component, MouseEvent, ReactNode, RefObject, createRef } from 'react'
import FocusLock from 'react-focus-lock'

import { Key } from '@consts/common'

import { IBackgroundProps } from './types'

export class Background extends Component<IBackgroundProps> {
  private readonly ref: RefObject<HTMLDivElement> = createRef()

  public componentDidMount (): void {
    const { keyboard } = this.props

    if (keyboard && this.ref.current) {
      this.ref.current.addEventListener('keydown', this.handleEscPress)
    }
  }

  public componentWillUnmount (): void {
    if (this.ref.current) {
      this.ref.current.removeEventListener('keydown', this.handleEscPress)
    }
  }

  public render (): ReactNode {
    const { children, classes } = this.props

    const backgroundClasses = cn('fixed atl-0 abr-0 bg-transparent-dark', classes)

    return (
      <FocusLock returnFocus={true}>
        <div onMouseDown={this.handleBackgroundClick} className={backgroundClasses} ref={this.ref}>
          {children}
        </div>
      </FocusLock>
    )
  }

  private readonly handleEscPress = (e: KeyboardEvent): void => {
    if (e.key === Key.ESC) {
      this.props.onClose()
    }
  }

  private readonly handleBackgroundClick = (e: MouseEvent): void => {
    e.stopPropagation()

    const { target, currentTarget } = e
    const { backdrop, onClose } = this.props

    if (backdrop && target === currentTarget) {
      onClose()
    }
  }
}
