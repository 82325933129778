import React, { FC } from 'react'

import { Icon } from '../../Icon'

import { IIconProps as IProps } from '../../Icon/types'

import { ReactComponent as CanceledIconSVG } from './canceled-icon.svg'

export const IconCanceled: FC<IProps> = ({ color = 'danger-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <CanceledIconSVG />
  </Icon>
)
