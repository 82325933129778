import { connect } from 'react-redux'

import LayoutRouter from './LayoutRouter'

import { getBadges } from '@store/modules/navigation/actions'

import { IApplicationState } from '@store/types/common'
import {
  ILayoutRouterPropsFromDispatch as IPropsFromDispatch,
  ILayoutRouterPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isAuthenticated: state.user.isAuthenticated,
  isError404: state.utils.isError404,
  isError500: state.utils.isError500
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetBadges: () => dispatch(getBadges())
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutRouter)
