import React, { FC } from 'react'

import { Timeout } from '@consts/common'

import { debounce } from '@utils/utils'

import { Button } from '@common/Button'
import { IconArrowBack } from '@common/icons'
import { RippleEffect } from '@common/RippleEffect'

import { IBackButtonProps as IProps } from './types'

export const BackButton: FC<IProps> = ({ onClick }) => (
  <Button classes='relative p-4' onClick={debounce(onClick, Timeout.RIPPLE_EFFECT_ICON_BUTTON)} theme='icon'>
    <IconArrowBack />
    <RippleEffect />
  </Button>
)
