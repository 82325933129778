// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W96BXDhFxzAwSMI3sIu1Yw\\=\\= {\n  font-size: 2.25rem;\n  line-height: 1.75rem;\n}\n\n._1HysCYsRwooTGuGX0cgdVw\\=\\= svg {\n  width: 11.5547rem;\n  height: 9.1875rem;\n}\n", "",{"version":3,"sources":["webpack://src/views/misc/Errors/Errors.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".error_code {\n  font-size: 2.25rem;\n  line-height: 1.75rem;\n}\n\n.error svg {\n  width: 11.5547rem;\n  height: 9.1875rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"error_code": "W96BXDhFxzAwSMI3sIu1Yw==",
	"error": "_1HysCYsRwooTGuGX0cgdVw=="
};
export default ___CSS_LOADER_EXPORT___;
