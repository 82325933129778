import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import documentReducer from './modules/document/reducer'
import documentsReducer from './modules/documents/reducer'
import loaderReducer from './modules/loader/reducer'
import messagesReducer from './modules/messages/reducer'
import metadataReducer from './modules/metadata/reducer'
import modalReducer from './modules/modal/reducer'
import navigationReducer from './modules/navigation/reducer'
import startupReducer from './modules/startup/reducer'
import tasksReducer from './modules/tasks/reducer'
import userReducer from './modules/user/reducer'
import utilsReducer from './modules/utils/reducer'

import { rootReducer } from '@store/modules/root/reducer'

export const appReducer = combineReducers({
  app: startupReducer,
  document: documentReducer,
  documents: documentsReducer,
  loader: loaderReducer,
  messages: messagesReducer,
  metadata: metadataReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  tasks: tasksReducer,
  user: userReducer,
  utils: utilsReducer
})

const composeEnhancer = composeWithDevTools({ trace: true })

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

export default store
