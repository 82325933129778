import React, { Component, ReactElement } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { isNotMobileDevice, Timeout } from '@consts/common'
import { ListenerType } from '@consts/enumTypes'
import { Path } from '@consts/navItems'
import { authRoutes } from '@consts/routes'

import { getRoutesList } from '@utils/utils'

import { Layout } from '@common/Layout'
import { Notifications, showErrorNotification } from '@common/Notifications'
import { PrivateRoute, RedirectToStartPage } from '@containers/routing'
import Page404 from '@views/misc/Errors/Page404'
import Page500 from '@views/misc/Errors/Page500'
import LayoutWrapper from '../LayoutWrapper'
import Menu from '../Menu'

import { TLayoutRouterProps as IProps, ILayoutRouterState as IState } from './types'

class LayoutRouter extends Component<IProps, IState> {
  constructor (props: IProps) {
    super(props)

    this.state = {
      intervalIdBadges: 0
    }
  }

  public componentDidMount (): void {
    const { isAuthenticated } = this.props

    if (isAuthenticated) {
      this.handleSetIntervalIdBadges()

      if (!isNotMobileDevice) {
        document.addEventListener(ListenerType.PAUSE, this.handleClearIntervalIdBadges)
        document.addEventListener(ListenerType.RESUME, this.handleSetIntervalIdBadges)
      }
    }
  }

  public componentWillUnmount (): void {
    const { isAuthenticated } = this.props
    const { intervalIdBadges } = this.state

    if (isAuthenticated && intervalIdBadges) {
      clearInterval(intervalIdBadges)

      if (!isNotMobileDevice) {
        document.removeEventListener(ListenerType.PAUSE, this.handleClearIntervalIdBadges)
        document.removeEventListener(ListenerType.RESUME, this.handleSetIntervalIdBadges)
      }
    }
  }

  public render (): ReactElement {
    return (
      <BrowserRouter>
        <Notifications />

        <Route exact path={Path.ORGANIZATION_ROUTE} component={Menu} />

        {this.renderRoutes()}
      </BrowserRouter>
    )
  }

  private readonly renderRoutes = (): ReactElement => {
    const { isError404, isError500 } = this.props

    if (isError404) {
      return (
        <Layout>
          <Route component={Page404} />
        </Layout>
      )
    }

    if (isError500) {
      return (
        <Layout>
          <Route component={Page500} />
        </Layout>
      )
    }

    return this.getPrivateRoutes()
  }

  private readonly getPrivateRoutes = (): ReactElement => (
    <Switch>
      {getRoutesList(authRoutes)}

      <PrivateRoute exact path={Path.ORGANIZATION_ROUTE} component={LayoutWrapper} />

      <Route component={RedirectToStartPage} />
    </Switch>
  )

  private readonly handleGetBadges = (): void => {
    const { onGetBadges } = this.props

    onGetBadges().catch(showErrorNotification)
  }

  private readonly handleSetIntervalIdBadges = (): void => {
    const intervalIdBadges = window.setInterval(this.handleGetBadges, Timeout.UPDATE_BADGES)

    this.setState({ intervalIdBadges })
  }

  private readonly handleClearIntervalIdBadges = (): void => {
    const { intervalIdBadges } = this.state

    clearInterval(intervalIdBadges)
  }
}

export default LayoutRouter
