import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as DiskIconSVG } from './disk-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconDisk: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <DiskIconSVG />
  </Icon>
)
