import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as FlagBrIconSVG } from './flag-br-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconFlagBr: FC<IProps> = ({ ...restProps }) => (
  <Icon {...restProps}>
    <FlagBrIconSVG />
  </Icon>
)
