import React, { FC } from 'react'

import { Icon } from '../../Icon'

import { IIconProps as IProps } from '../../Icon/types'

import { ReactComponent as IconBackspaceIconSVG } from './backspace-icon.svg'

export const IconBackspace: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <IconBackspaceIconSVG />
  </Icon>
)
