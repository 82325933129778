import { AxiosResponse, AxiosRequestConfig } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IDocumentTypeFromServer, IFlowStageType, IHistoryStateCodes } from '@store/modules/metadata/types'

class MetadataService extends AbstractHttpService {
  async getDocumentTypes (config: AxiosRequestConfig): Promise<AxiosResponse<IDocumentTypeFromServer[]>> {
    const url = urls.metadata.documentTypes

    const response: AxiosResponse<IDocumentTypeFromServer[]> = await this._http.get(url, config)

    return response
  }

  async getFlowStageTypes (): Promise<AxiosResponse<IFlowStageType[]>> {
    const url = urls.metadata.flowStageTypes

    const response: AxiosResponse<IFlowStageType[]> = await this._http.get(url)

    return response
  }

  async getHistoryStateCodes (): Promise<AxiosResponse<IHistoryStateCodes>> {
    const url = urls.metadata.historyStateCodes

    const response: AxiosResponse<IHistoryStateCodes> = await this._http.get(url)

    return response
  }

  async getWorkflowStatuses (): Promise<AxiosResponse<IDocumentTypeFromServer[]>> {
    const url = urls.metadata.workflowStatuses

    const response: AxiosResponse<IDocumentTypeFromServer[]> = await this._http.get(url)

    return response
  }
}

export default new MetadataService()
