import cn from 'classnames'
import React, { ForwardedRef, forwardRef } from 'react'

import {
  SkeletonDatetime,
  SkeletonListItemContractor,
  SkeletonListItemTitle,
  SkeletonSomeText,
  SkeletonStatus
} from '@common/skeletons/components'

import { ISkeletonDocumentListItemProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonDocumentListItem = forwardRef(({ withTopBorder }: IProps, ref: ForwardedRef<HTMLDivElement>) => (
  <div className={cn('px-4 bg-light border-bottom', { 'border-top': withTopBorder })} ref={ref}>
    <SkeletonListItemContractor />
    <SkeletonListItemTitle />
    <SkeletonSomeText classes='mb-2' />

    <div className='d-flex align-items-center mb-4'>
      <SkeletonStatus />
      <SkeletonDatetime />
    </div>
  </div>
))

SkeletonDocumentListItem.displayName = 'SkeletonDocumentListItem'
