import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { AssignMode, FlowTaskResult, FlowTaskState, FlowTaskType } from '@consts/enumTypes'

import { Nullable } from '@types'
import { IApplicationState } from '@store/types/common'
import { IDocument } from '../documents/types'

export enum DocumentActionTypes {
  RESET_DOCUMENT = 'RESET_DOCUMENT',
  RESET_DOCUMENT_FILE = 'RESET_DOCUMENT_FILE',
  RESET_DOCUMENT_MESSAGE = 'RESET_DOCUMENT_MESSAGE',
  SET_DOCUMENT = 'SET_DOCUMENT',
  SET_DOCUMENT_FILE = 'SET_DOCUMENT_FILE',
  SET_DOCUMENT_FLOW_STATE = 'SET_DOCUMENT_FLOW_STATE',
  SET_DOCUMENT_ID = 'SET_DOCUMENT_ID',
  SET_DOCUMENT_MESSAGE = 'SET_DOCUMENT_MESSAGE',
  SET_DOCUMENT_MESSAGES = 'SET_DOCUMENT_MESSAGES',
  SET_DOCUMENT_TITLE = 'SET_DOCUMENT_TITLE'
}

export interface IActionResetDocument extends Action<string> {
  type: DocumentActionTypes.RESET_DOCUMENT
}

export interface IActionResetDocumentFile extends Action<string> {
  type: DocumentActionTypes.RESET_DOCUMENT_FILE
}

export interface IActionResetDocumentMessage extends Action<string> {
  type: DocumentActionTypes.RESET_DOCUMENT_MESSAGE
}

export interface IActionSetDocument extends Action<string> {
  payload: IDocument
  type: DocumentActionTypes.SET_DOCUMENT
}

export interface IActionSetDocumentFile extends Action<string> {
  payload: IDocumentFile
  type: DocumentActionTypes.SET_DOCUMENT_FILE
}

export interface IActionSetDocumentFlowState extends Action<string> {
  payload: IDocumentFlowState
  type: DocumentActionTypes.SET_DOCUMENT_FLOW_STATE
}

export interface IActionSetDocumentId extends Action<string> {
  payload: number
  type: DocumentActionTypes.SET_DOCUMENT_ID
}

export interface IActionSetDocumentMessage extends Action<string> {
  payload: IDocumentMessage
  type: DocumentActionTypes.SET_DOCUMENT_MESSAGE
}

export interface IActionSetDocumentMessages extends Action<string> {
  payload: IDocumentMessage[]
  type: DocumentActionTypes.SET_DOCUMENT_MESSAGES
}

export interface IActionSetDocumentTitle extends Action<string> {
  payload: string
  type: DocumentActionTypes.SET_DOCUMENT_TITLE
}

export type TDocumentActions =
  | IActionResetDocument
  | IActionResetDocumentFile
  | IActionResetDocumentMessage
  | IActionSetDocument
  | IActionSetDocumentFile
  | IActionSetDocumentFlowState
  | IActionSetDocumentId
  | IActionSetDocumentMessage
  | IActionSetDocumentMessages
  | IActionSetDocumentTitle

export enum DocumentActionKey {
  SET_DOCUMENT = 'setDocument',
  SET_DOCUMENT_FILE = 'setDocumentFile',
  SET_DOCUMENT_FLOW_STATE = 'setDocumentFlowState',
  SET_DOCUMENT_ID = 'setDocumentId',
  SET_DOCUMENT_MESSAGE = 'setDocumentMessage',
  SET_DOCUMENT_MESSAGES = 'setDocumentMessages',
  SET_DOCUMENT_TITLE = 'setDocumentTitle'
}

export interface IDocumentState {
  document: Nullable<IDocument>
  documentId: Nullable<number>
  file: IDocumentFile
  flowState: IDocumentFlowState
  message: Nullable<IDocumentMessage>
  messages: IDocumentMessage[]
  title: string
}

export type DocumentActions = {
  [key in DocumentActionKey]: ActionCreator<Action<string>>
}

export type DocumentThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export type DocumentThunkDispatch = ThunkDispatch<IDocumentState, void, Action<string>>

export interface IDocumentUser {
  email: Nullable<string>
  oguid: string
  surname: string
  name: string
  patronymic: Nullable<string>
  position: string
}

export type PreviewOrientation = 'PORTRAIT' | 'LANDSCAPE'

export interface IPreviewData {
  fileCode: string
  mimeType: string
  orientation: PreviewOrientation
}

export interface IDocumentFile {
  data: IPreviewData[]
  isAvailable: boolean
  total: number
}

export interface IGroup {
  name: string
  oguid: Nullable<string>
}

export interface IParentTask {
  assignedToGroup: Nullable<IGroup>
  assignedToUser: Nullable<IDocumentUser>
  author: Nullable<IDocumentUser>
  comment: Nullable<string>
  completedBy: Nullable<IDocumentUser>
  completedTimestamp: Nullable<number>
  description: Nullable<string>
  isRead: boolean
  oguid: string
  parentTaskOguid: Nullable<string>
  processParticipants: IDocumentUser[]
  result: Nullable<FlowTaskResult>
  startedBy: Nullable<IDocumentUser>
  startedTimestamp: Nullable<number>
  state: Nullable<FlowTaskState>
  type: FlowTaskType
}

export interface IDocumentMessage {
  assignedToUser: IDocumentUser
  author: IDocumentUser
  comment: Nullable<string>
  completedBy: Nullable<IDocumentUser>
  completedTimestamp: Nullable<number>
  description: Nullable<string>
  isCompleted: boolean
  isRead: boolean
  oguid: string
  parentTask: Nullable<IParentTask>
  processParticipants: IDocumentUser[]
  startedBy: Nullable<IDocumentUser>
  startedTimestamp: number
  todos: IDocumentMessage[]
}

export interface IDocumentFlowState {
  processOguid: string
  routeOguid: string
  stages: IDocumentStage[]
}

export interface IDocumentStage {
  oguid: string
  name: string
  type: string
  state: string
  tasks: IDocumentTask[]
}

export interface IDocumentTask {
  oguid: string
  type: string
  state: Nullable<FlowTaskState>
  result: Nullable<FlowTaskResult>
  completedBy: {
    oguid: string
    surname: string
    name: string
    patronymic: string
    position: string
    email: string
  }
  completedTimestamp: number
  startedTimestamp: number
  assignMode: Nullable<AssignMode>
  assignedToUser: Nullable<IDocumentUser>
  assignedToGroup: Nullable<IGroup>
}
