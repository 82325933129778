import {
  AllowedAction,
  DocumentDirection,
  FlowTaskResult,
  FlowDocumentState,
  FlowTaskState,
  FlowDocumentResult
} from './enumTypes'

export const DocumentsDirections = {
  [DocumentDirection.INCOMING]: 'document:directions.incoming',
  [DocumentDirection.OUTGOING]: 'document:directions.outgoing',
  [DocumentDirection.INTERNAL]: 'document:directions.internal',
  [DocumentDirection.INTERNAL_WITH_SIGNATURE]: 'document:directions.internalWithSignature'
}

export const UserWarning = {
  password: 'password:validation.password',
  regexp: 'profile:validation.regexp'
}

export const Warning = {
  email: 'common:warning.email',
  max: {
    50: 'common:warning.max.50',
    150: 'common:warning.max.150',
    500: 'common:warning.max.500'
  },
  min: {
    3: 'common:warning.min.3',
    8: 'common:warning.min.8'
  },
  phone: 'common:warning.phone',
  required: 'common:warning.required'
}

export const Route = {
  assignMode: {
    ALL: 'common:assignMode.all',
    ANY: 'common:assignMode.any'
  }
}

export const FlowDocumentStatusText = {
  [FlowDocumentResult.DECLINED]: 'document:flowStatusText.declined',
  [FlowDocumentResult.SOLVED]: 'document:flowStatusText.solved',
  [FlowDocumentResult.STOPPED]: 'document:flowStatusText.stopped',
  [FlowDocumentState.IN_PROGRESS]: 'document:flowStatusText.inProgress',
  [FlowDocumentState.NOT_STARTED]: 'document:flowStatusText.notStarted'
}

export const FlowTaskStateStatusText = {
  [FlowTaskResult.CANCELED]: 'tasks:flowStateStatus.canceled',
  [FlowTaskResult.DECLINED]: 'tasks:flowStateStatus.declined',
  [FlowTaskResult.SOLVED]: 'tasks:flowStateStatus.solved',
  [FlowTaskState.IN_PROGRESS]: 'tasks:flowStateStatus.inProgress',
  [FlowTaskState.WAITING]: 'tasks:flowStateStatus.waiting'
}

export const DocumentActions = {
  [AllowedAction.APPROVE]: 'document:actions.approve',
  [AllowedAction.DECLINE_APPROVE]: 'document:actions.declineApprove',
  [AllowedAction.DECLINE_SIGN]: 'document:actions.declineSign',
  [AllowedAction.DELETE]: 'document:.actions.delete',
  [AllowedAction.MODIFY]: 'document:actions.modify',
  [AllowedAction.SIGN]: 'document:actions.sign',
  [AllowedAction.SIGN_TICKET]: 'document:actions.signTicket',
  [AllowedAction.SIGN_TITLE]: 'document:actions.signTitle',
  [AllowedAction.START_PROCESS]: 'document:actions.startProcess',
  [AllowedAction.STOP_PROCESS]: 'document:actions.stopProcess'
}
