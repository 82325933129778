import { AxiosResponse } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IApprovedScheme, IApprovalSchemeByRoute } from './types'

class ApprovalService extends AbstractHttpService {
  async getApprovalScheme (documentOguid: string): Promise<AxiosResponse<IApprovalSchemeByRoute>> {
    const url = urls.approval.scheme.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<IApprovalSchemeByRoute> = await this._http.get(url)

    return response
  }

  async startApprovalScheme (documentOguid: string, data: IApprovedScheme): Promise<AxiosResponse> {
    const url = urls.approval.scheme.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse = await this._http.post(url, data)

    return response
  }
}

export default new ApprovalService()
