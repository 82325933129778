import { Action, ActionCreator } from 'redux'

import { IActionResetError404, IActionResetError500, IActionSetExistingUser, UtilsActionTypes } from './types'

export const setError404: ActionCreator<Action> = (): Action => ({
  type: UtilsActionTypes.SET_ERROR404
})

export const setError500: ActionCreator<Action> = (): Action => ({
  type: UtilsActionTypes.SET_ERROR500
})

export const resetError404: IActionResetError404 = {
  type: UtilsActionTypes.RESET_ERROR404
}

export const resetError500: IActionResetError500 = {
  type: UtilsActionTypes.RESET_ERROR500
}

export const setExistingUserOguid: ActionCreator<Action> = (oguid: string): IActionSetExistingUser => ({
  payload: oguid,
  type: UtilsActionTypes.SET_EXISTING_USER
})
