import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as FlagEnIconSVG } from './flag-en-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconFlagEn: FC<IProps> = ({ ...restProps }) => (
  <Icon {...restProps}>
    <FlagEnIconSVG />
  </Icon>
)
