import { connect } from 'react-redux'

import MessagesButton from './MessagesButton'

import { IApplicationState } from '@store/types/common'
import { IMessagesButtonPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  todoQuantity: state.navigation.badges.todoQuantity
})

export default connect(mapStateToProps)(MessagesButton)
