import React, { FC } from 'react'

import { Modal } from '@consts/common'
import { ThemeType } from '@consts/enumTypes'
import { navDocWrite } from '@consts/navItems'

import { NavigationItem } from '@common/NavigationItem'
import CustomNavButton from '../CustomNavButton'

import { TQuestionButtonProps as IProps } from './types'

const QuestionButton: FC<IProps> = ({ modalIdfromState, onCloseModal, onResetDocumentMessage, onShowModal }) => {
  const { icon, title, uuid } = navDocWrite
  const modalIsOpen = modalIdfromState === Modal.MESSAGE_NEW

  const handleClick = (): void => {
    if (modalIsOpen) {
      onCloseModal()
      onResetDocumentMessage()
    }

    if (!modalIsOpen) {
      onShowModal(Modal.MESSAGE_NEW)
    }
  }

  return (
    <NavigationItem
      key={uuid}
      customNavItem={
        <CustomNavButton
          icon={icon}
          isActive={modalIsOpen}
          onClick={handleClick}
          theme={ThemeType.PRIMARY}
          title={title}
        />
      }
      uuid={uuid}
    />
  )
}

export default QuestionButton
