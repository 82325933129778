import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { CompleteStatus, FlowTaskResult, FlowTaskState, TaskTab } from '@consts/enumTypes'

import { Nullable } from '@types'
import { IApplicationState, IPagination } from '@store/types/common'
import { IDocument } from '@store/modules/documents/types'

export interface ITask {
  document: IDocument
  task: ITaskData
  stage: ITaskStage
}

export interface IAuthor {
  email?: string
  name: string
  oguid: string
  patronymic?: string
  surname: string
  position?: string
}

export interface ITaskData {
  author: IAuthor
  completedTimestamp: Nullable<number>
  description: string
  isRead: boolean
  oguid: string
  result: Nullable<FlowTaskResult>
  startedTimestamp: number
  state: Nullable<FlowTaskState>
  type: string
}

export interface ITaskStage {
  oguid: string
  name: string
  type: string
  state: Nullable<FlowTaskState>
}

export interface ITasksState {
  activeTab: TaskTab
  [TaskTab.COMPLETED]: ITasksAndPagination
  [TaskTab.NOT_COMPLETED]: ITasksAndPagination
}

export interface ITasksAndPagination {
  tasks: ITask[]
  pagination: Nullable<IPagination>
}

export interface IDataToCompleteTask {
  taskOguid: string
  completedTimestamp: number
  status: string
  state: FlowTaskState
  result: FlowTaskResult
}

// Экшны
export enum TasksActionTypes {
  COMPLETE_TASK = 'COMPLETE_TASK',
  RESET_ACTIVE_TAB_TO_TASKS = 'RESET_ACTIVE_TAB_TO_TASKS',
  RESET_TASKS = 'RESET_TASKS',
  RESET_TASKS_BY_TAB = 'RESET_TASKS_BY_TAB',
  SET_ACTIVE_TAB_TO_TASKS = 'SET_ACTIVE_TAB_TO_TASKS',
  SET_TASKS = 'SET_TASKS'
}

export interface IActionCompleteTask extends Action<string> {
  payload: IDataToCompleteTask
  type: TasksActionTypes.COMPLETE_TASK
}

export interface IActionResetTasks extends Action<string> {
  type: TasksActionTypes.RESET_TASKS
}

export interface IActionResetTasksByTab extends Action<string> {
  type: TasksActionTypes.RESET_TASKS_BY_TAB
}

export interface IActionSetTasks extends Action<string> {
  payload: ITasksAndPagination
  type: TasksActionTypes.SET_TASKS
}

export interface IActionSetActiveTabToTasks extends Action<string> {
  payload: TaskTab
  type: TasksActionTypes.SET_ACTIVE_TAB_TO_TASKS
}

export type TTasksActions =
  | IActionCompleteTask
  | IActionResetTasks
  | IActionResetTasksByTab
  | IActionSetActiveTabToTasks
  | IActionSetTasks

export enum TasksActionKey {
  COMPLETE_TASK = 'completeTaskAction',
  SET_ACTIVE_TAB_TO_TASKS = 'setActiveTabToTasks',
  SET_TASKS = 'setTasks'
}

export type TasksActions = {
  [key in TasksActionKey]: ActionCreator<Action<string>>
}

export type TasksThunkDispatch = ThunkDispatch<ITasksState, void, Action<string>>

export type TasksThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export interface IApproveContent {
  comment: Nullable<string>
  result: CompleteStatus
}
