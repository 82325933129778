import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as ReceivingStateIconSVG } from './receiving-state-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconStateReceiving: FC<IProps> = ({ color = 'success-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ReceivingStateIconSVG />
  </Icon>
)
