import jwtDecode from 'jwt-decode'
import { CookieSetOptions } from 'universal-cookie'

import { MILLISECONDS_IN_SECOND } from '@consts/common'

import cookies from '@utils/cookies'

export const get = (): any => {
  let expireAccess = 0
  const accessToken = cookies.get('access')

  if (accessToken) {
    const decodedAcc: any = jwtDecode(accessToken)
    expireAccess = decodedAcc.exp * MILLISECONDS_IN_SECOND
  }

  return {
    accessToken,
    expireAccess
  }
}

export const clear = (): void => {
  cookies.remove('access', { path: '/' })
}

export const set = (access: string, isSessionCookie = false): void => {
  const decodedAcc: any = jwtDecode(access)
  const expireAccess = decodedAcc.exp * MILLISECONDS_IN_SECOND

  const options: CookieSetOptions = {
    path: '/'
  }

  if (isSessionCookie) options.expires = new Date(expireAccess)

  cookies.set('access', access, options)
}
