import React, { FC } from 'react'

import { Icon } from '../../Icon'

import { IIconProps as IProps } from '../../Icon/types'

import { ReactComponent as CancelIconSVG } from './cancel-icon.svg'

export const IconCancel: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <CancelIconSVG />
  </Icon>
)
