import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@common/Button'
import { IconSignOut } from '@common/icons'
import { RippleEffect } from '@common/RippleEffect'

import { TSignOutButtonProps as IProps } from './types'
import { ColorTheme } from '@consts/common'

const classes = {
  wrapper: 'p-4',
  content: 'd-flex align-items-center text-color font-base'
}

const SignOutButton: FC<IProps> = ({ onLogout }) => (
  <li className='relative mr-auto'>
    <Button
      classes={classes}
      leftIcon={<IconSignOut classes='mr-2' color='warning-500' />}
      onClick={onLogout}
      styleTitle='capitalize'
    >
      {useTranslation().t('auth:buttons.signout')}
      <RippleEffect color={ColorTheme.warning} />
    </Button>
  </li>
)

export default SignOutButton
