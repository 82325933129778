import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import messagesService from '@services/messages'

import { ResponseCode, SuccessCode } from '@consts/common'
import { MessagesTab } from '@consts/enumTypes'

import { getPagination } from '@utils/utils'

import { navigationActions } from '../navigation/actions'

import { IParams } from '@store/types/common'
import { NavigationActionKey, NavigationThunkDispatch } from '../navigation/types'
import {
  IActionMarkMessageAsRead,
  IActionResetMessage,
  IActionResetMessages,
  IActionResetMessagesByTab,
  IActionResetMessagesTab,
  IActionSetActiveTabToMessages,
  IActionSetMessage,
  IActionSetMessages,
  IActionTransferMessageToClose,
  ITransferMessageToClose,
  IMessage,
  IMessageBody,
  MessagesActionKey,
  MessagesActions,
  MessagesActionTypes,
  MessagesThunkAction,
  MessagesThunkDispatch,
  IMessagesAndPagination
} from './types'

// actions
const markMessageAsReadAction: ActionCreator<Action> = (taskOguidFromMessage: string): IActionMarkMessageAsRead => ({
  payload: taskOguidFromMessage,
  type: MessagesActionTypes.MARK_MESSAGE_AS_READ
})

export const resetMessage: IActionResetMessage = {
  type: MessagesActionTypes.RESET_MESSAGE
}

export const resetMessages: IActionResetMessages = {
  type: MessagesActionTypes.RESET_MESSAGES
}

export const resetMessagesTab: IActionResetMessagesTab = {
  type: MessagesActionTypes.RESET_MESSAGES_TAB
}

export const resetMessagesByTab: IActionResetMessagesByTab = {
  type: MessagesActionTypes.RESET_MESSAGES_BY_TAB
}

const setMessageAction: ActionCreator<Action> = (message: IMessage): IActionSetMessage => ({
  payload: message,
  type: MessagesActionTypes.SET_MESSAGE
})

const setMessagesAction: ActionCreator<Action> = (payload: IMessagesAndPagination): IActionSetMessages => ({
  payload,
  type: MessagesActionTypes.SET_MESSAGES
})

const setActiveTabToMessagesAction: ActionCreator<Action> = (tab: MessagesTab): IActionSetActiveTabToMessages => ({
  payload: tab,
  type: MessagesActionTypes.SET_ACTIVE_TAB_TO_MESSAGES
})

const transferMessageToCloseAction: ActionCreator<Action> = (
  taskOguidFromMessage: string
): IActionTransferMessageToClose => ({
  payload: taskOguidFromMessage,
  type: MessagesActionTypes.TRANSFER_MESSAGE_TO_CLOSE
})

export const messagesActions: MessagesActions = {
  [MessagesActionKey.MARK_MESSAGE_AS_READ]: markMessageAsReadAction,
  [MessagesActionKey.SET_ACTIVE_TAB_TO_MESSAGES]: setActiveTabToMessagesAction,
  [MessagesActionKey.SET_MESSAGE]: setMessageAction,
  [MessagesActionKey.SET_MESSAGES]: setMessagesAction,
  [MessagesActionKey.TRANSFER_MESSAGE_TO_CLOSE]: transferMessageToCloseAction
}

// thunks
export const addMessage: ActionCreator<MessagesThunkAction> = (
  documentOguid: string,
  data: IMessageBody
) => (): Promise<AxiosResponse> => messagesService.addMessage(documentOguid, data)

export const getMessages: ActionCreator<MessagesThunkAction> = (params: IParams) => (
  dispatch: MessagesThunkDispatch
): Promise<AxiosResponse> =>
  messagesService.getMessages({ params }).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      const payload: IMessagesAndPagination = {
        messages: resp.data,
        pagination: getPagination(resp.headers)
      }

      dispatch(messagesActions[MessagesActionKey.SET_MESSAGES](payload))
    }

    return resp
  })

export const markMessageAsRead: ActionCreator<MessagesThunkAction> = (
  taskOguid: string,
  transferMessageToClose?: boolean
) => (dispatch: MessagesThunkDispatch): Promise<AxiosResponse> =>
  messagesService.markMessageAsRead(taskOguid).then((resp: AxiosResponse) => {
    if (SuccessCode.POST.includes(resp.status) && !transferMessageToClose) {
      dispatch(messagesActions[MessagesActionKey.MARK_MESSAGE_AS_READ](taskOguid))
    }

    return resp
  })

export const transferMessageToClose: ActionCreator<MessagesThunkAction> = (
  taskOguid: string,
  data: ITransferMessageToClose
) => (dispatch: NavigationThunkDispatch): Promise<MessagesThunkAction | AxiosResponse<any>> =>
  messagesService.transferMessageToClose(taskOguid, data).then((resp: AxiosResponse) => {
    if (SuccessCode.POST.includes(resp.status)) {
      dispatch(navigationActions[NavigationActionKey.SET_BADGES](resp.data))

      dispatch(messagesActions[MessagesActionKey.TRANSFER_MESSAGE_TO_CLOSE](taskOguid))
    }

    return resp
  })
