import React, { FC } from 'react'

import { ReactComponent as SkeletonListItemContractorSVG } from './contractor.svg'

import '@common/skeletons/Skeletons.scss'

export const SkeletonListItemContractor: FC = () => (
  <div className='skeleton skeleton--contractor mt-4 mb-2'>
    <SkeletonListItemContractorSVG />
  </div>
)
