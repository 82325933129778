import React, { FC } from 'react'
import { Switch } from 'react-router-dom'

import { getRoutesList } from '@utils/utils'

import { IRenderRoutesProps as IProps } from './types'

const RenderRoutes: FC<IProps> = ({ routes }) => <Switch>{getRoutesList(routes)}</Switch>

export default RenderRoutes
