import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { CompleteStatus, MessagesTab } from '@consts/enumTypes'
import { MessageField } from '@consts/fields'

import { IApplicationState, IPagination } from '@store/types/common'
import { IDocument } from '@store/modules/documents/types'
import { IDocumentUser } from '../document/types'
import { Nullable } from '@types'

export enum MessagesActionTypes {
  MARK_MESSAGE_AS_READ = 'MARK_MESSAGE_AS_READ',
  RESET_MESSAGE = 'RESET_MESSAGE',
  RESET_MESSAGES = 'RESET_MESSAGES',
  RESET_MESSAGES_TAB = 'RESET_MESSAGES_TAB',
  RESET_MESSAGES_BY_TAB = 'RESET_MESSAGES_BY_TAB',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_MESSAGES = 'SET_MESSAGES',
  SET_ACTIVE_TAB_TO_MESSAGES = 'SET_ACTIVE_TAB_TO_MESSAGES',
  TRANSFER_MESSAGE_TO_CLOSE = 'TRANSFER_MESSAGE_TO_CLOSE'
}

export interface IMessageTask {
  author: IDocumentUser
  description: string
  isRead: boolean
  oguid: string
  startedTimestamp: number
  type: string
  state: string
  result: string
}

export interface IMessage {
  document: IDocument
  task: IMessageTask
  stage: IStage
}

// ToDo пересмотреть тип IStage
export interface IStage {
  oguid: string
  name: string
  type: string
  state: string
}

export interface IMessagesAndPagination {
  messages: IMessage[]
  pagination: Nullable<IPagination>
}

export interface IMessagesState {
  activeTab: MessagesTab
  message: Nullable<IMessage>
  [MessagesTab.COMPLETED]: IMessagesAndPagination
  [MessagesTab.NOT_COMPLETED]: IMessagesAndPagination
}

export interface IMessageNewState {
  [MessageField.ASSIGNED_TO_USER]: Nullable<IDocumentUser>
  [MessageField.DESCRIPTION]: string
}

export interface IMessageBody {
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
  comment?: Nullable<string>
  description: string
  parentTaskOguid: Nullable<string>
}

export interface IActionMarkMessageAsRead extends Action<string> {
  payload: string
  type: MessagesActionTypes.MARK_MESSAGE_AS_READ
}

export interface IActionResetMessage extends Action<string> {
  type: MessagesActionTypes.RESET_MESSAGE
}

export interface IActionResetMessages extends Action<string> {
  type: MessagesActionTypes.RESET_MESSAGES
}

export interface IActionResetMessagesTab extends Action<string> {
  type: MessagesActionTypes.RESET_MESSAGES_TAB
}

export interface IActionResetMessagesByTab extends Action<string> {
  type: MessagesActionTypes.RESET_MESSAGES_BY_TAB
}

export interface IActionSetMessage extends Action<string> {
  payload: IMessage
  type: MessagesActionTypes.SET_MESSAGE
}

export interface IActionSetMessages extends Action<string> {
  payload: IMessagesAndPagination
  type: MessagesActionTypes.SET_MESSAGES
}

export interface IActionSetActiveTabToMessages extends Action<string> {
  payload: MessagesTab
  type: MessagesActionTypes.SET_ACTIVE_TAB_TO_MESSAGES
}

export interface IActionTransferMessageToClose extends Action<string> {
  payload: string
  type: MessagesActionTypes.TRANSFER_MESSAGE_TO_CLOSE
}

export type TMessagesActions =
  | IActionMarkMessageAsRead
  | IActionResetMessage
  | IActionResetMessages
  | IActionResetMessagesByTab
  | IActionResetMessagesTab
  | IActionSetMessage
  | IActionSetMessages
  | IActionSetActiveTabToMessages
  | IActionTransferMessageToClose

export enum MessagesActionKey {
  MARK_MESSAGE_AS_READ = 'markMessageAsRead',
  SET_ACTIVE_TAB_TO_MESSAGES = 'setActiveTabToMessages',
  SET_MESSAGE = 'setMessage',
  SET_MESSAGES = 'setMessages',
  TRANSFER_MESSAGE_TO_CLOSE = 'transferMessageToCloseAction'
}

export interface ITransferMessageToClose {
  comment: Nullable<string>
  result: CompleteStatus
}

export type MessagesActions = {
  [key in MessagesActionKey]: ActionCreator<Action<string>>
}

export type MessagesThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>

export type MessagesThunkDispatch = ThunkDispatch<IMessagesState, void, Action<string>>
