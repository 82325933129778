import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as SendIconSVG } from './send-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconSend: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <SendIconSVG />
  </Icon>
)
