import React, { FC } from 'react'

import { SkeletonButtonListItem, SkeletonLabel } from '@common/skeletons/components'

import '@common/skeletons/Skeletons.scss'

export const SkeletonSettingsBlockButton: FC = () => (
  <div className='border-bottom'>
    <SkeletonLabel classes='mt-3 mx-4' />
    <SkeletonButtonListItem />
  </div>
)
