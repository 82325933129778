import { AxiosRequestConfig, AxiosResponse } from 'axios'

import urls from '@consts/urls'

import AbstractHttpService from '../abstractHttpService'

import { IDocument } from '@store/modules/documents/types'
import { IDocumentFile, IDocumentFlowState, IDocumentMessage } from '@store/modules/document/types'

class DocumentService extends AbstractHttpService {
  async getDocumentData (documentId: string): Promise<AxiosResponse<IDocument>> {
    const url = urls.document.single.replace('{{ documentId }}', documentId)

    const response: AxiosResponse<IDocument> = await this._http.get(url)

    return response
  }

  async getDocumentFile (documentOguid: string, documentPage: number): Promise<AxiosResponse<IDocumentFile>> {
    const url = urls.document.file
      .replace('{{ documentOguid }}', documentOguid)
      .replace('{{ documentPage }}', String(documentPage))

    const config: AxiosRequestConfig = { responseType: 'text' }

    const response: AxiosResponse<IDocumentFile> = await this._http.get(url, config, true)

    return response
  }

  async downloadDocumentFile (documentOguid: string): Promise<AxiosResponse<Blob>> {
    const url = urls.document.downloadFile.replace('{{ documentOguid }}', documentOguid)
    const config: AxiosRequestConfig = { responseType: 'blob' }

    const response: AxiosResponse<Blob> = await this._http.get(url, config, true)

    return response
  }

  async downloadDocumentFileByPage (documentAttachmentOguid: string): Promise<AxiosResponse<Blob>> {
    const url = urls.document.downloadFileByPage.replace('{{ documentAttachmentOguid }}', documentAttachmentOguid)
    const config: AxiosRequestConfig = { responseType: 'blob' }

    const response: AxiosResponse<Blob> = await this._http.get(url, config, true)

    return response
  }

  async getDocumentMessages (documentOguid: string): Promise<AxiosResponse<IDocumentMessage[]>> {
    const url = urls.document.messages.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<IDocumentMessage[]> = await this._http.get(url)

    return response
  }

  async getDocumentFlowState (documentOguid: string): Promise<AxiosResponse<IDocumentFlowState>> {
    const url = urls.document.flowState.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<IDocumentFlowState> = await this._http.get(url)

    return response
  }

  async removeDocument (documentOguid: string): Promise<AxiosResponse<IDocumentFlowState>> {
    const url = urls.document.remove.replace('{{ documentOguid }}', documentOguid)

    const response: AxiosResponse<IDocumentFlowState> = await this._http.delete(url)

    return response
  }
}

export default new DocumentService()
