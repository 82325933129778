import React, { FC } from 'react'

import { Timeout } from '@consts/common'
import { Path } from '@consts/navItems'

import { IconMenu } from '@common/icons'
import { RippleEffect } from '@common/RippleEffect'
import { OrganizationLink } from '@containers/navigation'

export const SettingsButton: FC = () => (
  <OrganizationLink className='relative p-4' timeout={Timeout.RIPPLE_EFFECT_ICON_BUTTON} to={Path.SETTINGS}>
    <IconMenu />
    <RippleEffect />
  </OrganizationLink>
)
