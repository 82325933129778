import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ColorTheme, Timeout } from '@consts/common'
import { ThemeType } from '@consts/enumTypes'

import { debounce } from '@utils/utils'

import { Button } from '@common/Button'
import { Ellipsis } from '@common/Ellipsis'
import { RippleEffect } from '@common/RippleEffect'

import { IClasses } from '@types'
import { ICustomNavButtonProps as IProps } from './types'

const getClasses = (theme: ThemeType): IClasses => ({
  wrapper: 'relative height-full width-full',
  content: `d-flex align-items-center flex-column justify-content-center font-xs text-${theme}`
})

const CustomNavButton: FC<IProps> = ({ icon, isActive, onClick, title, theme }) => (
  <Button
    leftIcon={icon}
    classes={getClasses(theme)}
    isActive={isActive}
    onClick={debounce(onClick, Timeout.RIPPLE_EFFECT_NAVIGATION_BUTTON)}
    styleTitle='capitalize'
  >
    <Ellipsis classes='width-full'>{useTranslation().t(title)}</Ellipsis>
    <RippleEffect color={ColorTheme[theme]} />
  </Button>
)

export default CustomNavButton
