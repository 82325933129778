import { Dispatch } from 'redux'

import { isNotMobileDevice, Timeout } from '@consts/common'

import * as jwt from '@utils/jwt'
import { clearAuthDataToStorageAndroid, clearAuthDataToStorageIOS } from '@utils/localStorage'

import { ILogoutAction, USER_LOGOUT } from './types'
import { isPlatformAndroid, isPlatformIOS } from '@utils/device'

export const logoutAction = (): ILogoutAction => ({ type: USER_LOGOUT })

export const logout = () => (dispatch: Dispatch) => {
  setTimeout(() => dispatch(logoutAction()), Timeout.RIPPLE_EFFECT_DEFAULT)

  if (isNotMobileDevice) jwt.clear()

  if (isPlatformAndroid()) clearAuthDataToStorageAndroid()

  if (isPlatformIOS()) clearAuthDataToStorageIOS()
}
