import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { INavItem, Nullable } from '@types'
import { IApplicationState } from '@store/types/common'

export enum NavigationActionTypes {
  RESET_NAVIGATION = 'RESET_NAVIGATION',
  SET_ACTIVE_NAV_ITEM = 'SET_ACTIVE_NAV_ITEM',
  SET_BADGES = 'SET_BADGES',
  SET_NAV_ITEMS = 'SET_NAV_ITEMS',
  SET_OPENED_FROM_NOTIFICATION = 'SET_OPENED_FROM_NOTIFICATION',
  SET_PREV_NAV_ROUTE = 'SET_PREV_NAV_ROUTE'
}

export interface IActiveNavItem {
  route: string
  title: string
}

export interface IBadges {
  [key: string]: number
}

export interface INavigationState {
  activeNavItem: IActiveNavItem
  badges: IBadges
  navItems: INavItem[]
  openedFromNotification: Nullable<string>
  prevNavRoute: Nullable<string>
}

export interface IActionResetNavigation extends Action<string> {
  type: NavigationActionTypes.RESET_NAVIGATION
}

export interface IActionSetActiveNavItem extends Action<string> {
  payload: IActiveNavItem
  type: NavigationActionTypes.SET_ACTIVE_NAV_ITEM
}

export interface IActionSetBadges extends Action<string> {
  payload: IBadges
  type: NavigationActionTypes.SET_BADGES
}

export interface IActionSetNavItems extends Action<string> {
  payload: INavItem[]
  type: NavigationActionTypes.SET_NAV_ITEMS
}

export interface IActionSetOpenedFromNotification extends Action<string> {
  payload: Nullable<string>
  type: NavigationActionTypes.SET_OPENED_FROM_NOTIFICATION
}

export interface IActionSetPrevNavRoute extends Action<string> {
  payload: Nullable<string>
  type: NavigationActionTypes.SET_PREV_NAV_ROUTE
}

export type TNavigationActions =
  | IActionResetNavigation
  | IActionSetActiveNavItem
  | IActionSetBadges
  | IActionSetNavItems
  | IActionSetOpenedFromNotification
  | IActionSetPrevNavRoute

export enum NavigationActionKey {
  SET_ACTIVE_NAV_ITEM = 'setActiveNavItem',
  SET_BADGES = 'setBadges',
  SET_NAV_ITEMS = 'setNavItems',
  SET_OPENED_FROM_NOTIFICATION = 'setOpenedFromNotification',
  SET_PREV_NAV_ROUTE = 'setPrevNavRoute'
}

export type NavigationActions = {
  [key in NavigationActionKey]: ActionCreator<Action<string>>
}

export type NavigationThunkDispatch = ThunkDispatch<INavigationState, void, Action<string>>

export type NavigationThunkAction = ThunkAction<Promise<AxiosResponse> | any, IApplicationState, void, Action<string>>
