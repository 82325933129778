import { connect } from 'react-redux'

import LayoutWrapper from './LayoutWrapper'

import { IApplicationState } from '@store/types/common'
import { ILayoutWrapperPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isExchangeParticipant: state.user.isExchangeParticipant,
  isFlowFunctionalityEnabled: state.user.isFlowFunctionalityEnabled,
  isLoading: state.loader.isLoading,
  isReadonly: state.user.isReadonly,
  role: state.user.role
})

export default connect(mapStateToProps)(LayoutWrapper)
