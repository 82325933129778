import cn from 'classnames'
import React, { FC } from 'react'

import { ReactComponent as SkeletonLabelSVG } from './label.svg'

import { ISkeletonLabelProps as IProps } from './types'

import '@common/skeletons/Skeletons.scss'

export const SkeletonLabel: FC<IProps> = ({ classes }) => (
  <div className={cn('skeleton skeleton--label', classes)}>
    <SkeletonLabelSVG />
  </div>
)
