// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Llt816\\+L5IY2oCFzzmg7lw\\=\\= {\n  border-radius: 50%;\n  -webkit-transform: translateY(-65%);\n          transform: translateY(-65%);\n}\n\n.yIFhyJxBuM7-qczJ0kxMBQ\\=\\= {\n  width: 1rem;\n}\n", "",{"version":3,"sources":["webpack://src/common/MessagesBadge/MessagesBadge.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mCAA2B;UAA3B,2BAA2B;AAC7B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".badge {\n  border-radius: 50%;\n  transform: translateY(-65%);\n}\n\n.badgeLessMax {\n  width: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badge": "Llt816+L5IY2oCFzzmg7lw==",
	"badgeLessMax": "yIFhyJxBuM7-qczJ0kxMBQ=="
};
export default ___CSS_LOADER_EXPORT___;
