import React, { FC, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Notification } from './components/Notification'
import StoreNotifications from './components/StoreNotifications'

import { Nullable } from '@types'
import { INotification } from './types'

import './Notifications.scss'

export const Notifications: FC = () => {
  const [notification, setNotification] = useState<Nullable<INotification>>(null)

  const handleAddNotification = (notificationData: INotification): void => {
    notificationData.id = uuidv4()

    setNotification(notificationData)
  }

  const handleRemoveNotification = (): void => setNotification(null)

  useEffect(() => {
    StoreNotifications.register(handleAddNotification)
  }, [])

  if (!notification) return null

  return (
    <div className='notifications fixed overflow-none atl-0 abr-0' id='notifications'>
      <Notification key={notification.id} notification={notification} onRemove={handleRemoveNotification} />
    </div>
  )
}

export * from './notifications'
