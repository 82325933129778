import React, { Component, createRef, ReactElement, RefObject } from 'react'

import { ContentType, ListenerType, ThemeType } from '@consts/enumTypes'
import {
  HEADER_HEIGHT_WITH_SPACE,
  initialState,
  mouseListeners,
  PREVENT_TAP_LAG,
  touchListeners,
  styleHeight
} from './consts'

import { getAddingListeners, getClasses, getRemovingListeners, getSpinnerClasses } from './utils'

import { Spinner } from '@common/loaders'

import { IContentActions, IContentProps as IProps, IContentState as IState } from './types'

import './Content.scss'

export class Content extends Component<IProps, IState> {
  private readonly contentRef: RefObject<HTMLDivElement>
  private readonly actions: IContentActions | undefined

  constructor (props: IProps) {
    super(props)

    this.contentRef = createRef<HTMLDivElement>()

    this.state = {
      ...initialState
    }

    if (props.onRefresh) {
      this.actions = {
        startAction: this.handleStart,
        motionAction: this.handleMove,
        endAction: this.handleEnd
      }
    }
  }

  public componentDidMount (): void {
    const { onRefresh } = this.props
    const contentRef = this.contentRef.current

    if (contentRef instanceof HTMLDivElement && onRefresh) {
      const contentElement = contentRef

      this.setState({
        firstChildYStart: contentElement.firstElementChild?.getBoundingClientRect().y ?? HEADER_HEIGHT_WITH_SPACE
      })

      this.handleAddListeners(contentElement)
      contentRef.addEventListener(ListenerType.SCROLL, this.handleScrollListener(contentElement))
    }
  }

  public componentWillUnmount (): void {
    const { onRefresh } = this.props
    const contentRef = this.contentRef.current

    if (contentRef instanceof HTMLDivElement && onRefresh) {
      const contentElement = contentRef

      this.handleRemoveListeners(contentElement)
      contentRef.removeEventListener(ListenerType.SCROLL, this.handleScrollListener(contentElement))
    }
  }

  public render (): ReactElement {
    const { children, classes, type = ContentType.DEFAULT, heightCorrection } = this.props
    const { isRefreshing } = this.state

    const spinnerClasses = getSpinnerClasses(type, heightCorrection)
    const contentClasses = getClasses(isRefreshing, type, classes)
    // ToDo подумать, как обойти передачу 0 во второй ветке
    const contentStyle = { height: heightCorrection ? styleHeight[type](heightCorrection) : styleHeight[type](0) }

    return (
      <>
        {isRefreshing && <Spinner classes={spinnerClasses} theme={ThemeType.BRAND} />}
        <div className={contentClasses} ref={this.contentRef} style={contentStyle}>
          {children}
        </div>
      </>
    )
  }

  private readonly handleStart = (e: MouseEvent | TouchEvent): void => {
    const contentRef = this.contentRef.current

    if (contentRef instanceof HTMLDivElement) {
      if (e instanceof MouseEvent) {
        this.setState({ tapYStart: e.pageY })

        contentRef.addEventListener(ListenerType.MOUSE_MOVE, this.handleMove)
      }

      if (e instanceof TouchEvent) this.setState({ tapYStart: e.touches[0].pageY })
    }
  }

  private readonly handleMove = (e: MouseEvent | TouchEvent): void => {
    const contentRef = this.contentRef.current

    if (contentRef instanceof HTMLDivElement) {
      if (e instanceof MouseEvent) this.setState({ tapYCurrent: e.pageY })
      if (e instanceof TouchEvent) this.setState({ tapYCurrent: e.touches[0].pageY })

      const { tapYCurrent, tapYStart } = this.state

      if (tapYCurrent < tapYStart) {
        return
      }

      if (tapYCurrent - tapYStart > PREVENT_TAP_LAG) {
        this.setState({ isRefreshing: true })
      }
    }
  }

  private readonly handleEnd = (): void => {
    const { onRefresh } = this.props
    const { isRefreshing } = this.state

    const contentRef = this.contentRef.current

    if (contentRef instanceof HTMLDivElement && onRefresh && isRefreshing) {
      onRefresh()
    }
  }

  private readonly handleScrollListener = (contentElement: HTMLDivElement) => (): void => {
    const { firstChildYStart } = this.state

    const firstChildYCurrent = contentElement.firstElementChild?.getBoundingClientRect().y ?? HEADER_HEIGHT_WITH_SPACE

    if (firstChildYStart > firstChildYCurrent) {
      this.handleRemoveListeners(contentElement)
    } else {
      this.handleAddListeners(contentElement)
    }
  }

  private readonly handleAddListeners = (contentElement: HTMLDivElement): void => {
    if (this.actions) {
      getAddingListeners(contentElement, touchListeners, this.actions)
      getAddingListeners(contentElement, mouseListeners, this.actions, true)
    }
  }

  private readonly handleRemoveListeners = (contentElement: HTMLDivElement): void => {
    if (this.actions) {
      getRemovingListeners(contentElement, touchListeners, this.actions)
      getRemovingListeners(contentElement, mouseListeners, this.actions)
    }
  }
}
