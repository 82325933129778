import React from 'react'
import ReactDOM from 'react-dom'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'

import { isNotMobileDevice, SENTRY_DSN } from '@consts/common'
import { EnviropmentApp, ListenerType } from '@consts/enumTypes'

import './i18next'

import App from './App'

const root = document.querySelector('#root')

const init = (): void => {
  ReactDOM.render(<App />, root)
}

if (process.env.REACT_APP_API_ENV === EnviropmentApp.PROD) {
  const sentryOptions = {
    integrations: [new Integrations.BrowserTracing()],
    dsn: SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0
  }

  Sentry.init(sentryOptions)
}

if (isNotMobileDevice) {
  init()
} else {
  document.addEventListener(ListenerType.DEVICE_READY, init)
}
