import React, { FC } from 'react'

import { ReactComponent as SkeletonHeaderTitleSVG } from './header-title.svg'

import '@common/skeletons/Skeletons.scss'

// ToDo подумать, если тень накладывается на тень, то, возможно, в Header нужно еще передавать тип
export const SkeletonHeaderTitle: FC = () => (
  <h2 className='skeleton skeleton--header-title'>
    <SkeletonHeaderTitleSVG />
  </h2>
)
