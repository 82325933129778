import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as MessagesIconSVG } from './messages-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconMessages: FC<IProps> = ({ color = 'muted-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <MessagesIconSVG />
  </Icon>
)
