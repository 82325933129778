import React, { FC } from 'react'
import Loadable from 'react-loadable'

import { Path } from '@consts/navItems'

import { SkeletonSetPinCode, SkeletonLayout } from '@common/skeletons/views'

import { TRoute } from '@containers/routing/RouteWithSubRoutes/types'

const SkeletonLayoutCommon: FC = () => <SkeletonLayout />

const Signin = Loadable({
  loader: () => import('@views/auth/Signin'),
  loading: SkeletonLayoutCommon
})

const SetPinCode = Loadable({
  loader: () => import('@views/auth/SetPinCode'),
  loading: SkeletonSetPinCode
})

const RestorePassword = Loadable({
  loader: () => import('@views/auth/RestorePassword'),
  loading: SkeletonSetPinCode
})

export const routes: TRoute[] = [
  {
    component: Signin,
    exact: true,
    name: 'Signin',
    path: Path.SIGN_IN
  },
  {
    component: SetPinCode,
    exact: true,
    name: 'SetPinCode',
    path: Path.SET_PIN_CODE
  },
  {
    component: RestorePassword,
    exact: true,
    name: 'RestorePassword',
    path: Path.RESTORE_PASSWORD
  }
]
