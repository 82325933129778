import React, { FC, Suspense } from 'react'
import { Provider } from 'react-redux'

import store from '@store/configureStore'

import { LayoutRouter } from '@containers/layout'
import Startup from '@views/startup/Startup'

const App: FC = () => (
  <Suspense fallback={null}>
    <Provider store={store}>
      <Startup>
        <LayoutRouter />
      </Startup>
    </Provider>
  </Suspense>
)

export default App
