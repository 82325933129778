import React, { FC } from 'react'

import { Icon } from '../../Icon'
import { ReactComponent as ChangeIconSVG } from './change-icon.svg'

import { IIconProps as IProps } from '../../Icon/types'

export const IconChange: FC<IProps> = ({ color = 'brand-500', ...restProps }) => (
  <Icon {...restProps} color={color}>
    <ChangeIconSVG />
  </Icon>
)
