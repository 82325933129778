import { connect } from 'react-redux'

import Page500 from './Page500'

import { resetError500 } from '@store/modules/utils/actions'

import { IApplicationState } from '@store/types/common'
import { IPage500PropsFromDispatch as IPropsFromDispatch, IPage500PropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  mainNavItemRoute: state.navigation.navItems[0].route
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onResetError500: () => dispatch(resetError500)
})

export default connect(mapStateToProps, mapDispatchToProps)(Page500)
