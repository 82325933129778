import { Action } from 'redux'

export enum StartupActionTypes {
  RESET_STARTUP = 'RESET_STARTUP',
  SET_LOADED = 'SET_LOADED'
}

export interface IStartupState {
  isLoaded: boolean
}

export interface IActionResetStartup extends Action<string> {
  type: StartupActionTypes.RESET_STARTUP
}

export interface IActionSetLoaded extends Action<string> {
  payload: boolean
  type: StartupActionTypes.SET_LOADED
}

export type TStartupActions = IActionResetStartup | IActionSetLoaded
