import cn from 'classnames'
import React, { FC } from 'react'

import { MAXIMUM_BADGES_QUANTITY } from '@consts/common'

import { IMessagesBadgeProps as IProps } from './types'

import styles from './MessagesBadge.module.css'

const getClasses = (isMoreThanMax: boolean): string =>
  cn('absolute font-xxs text-light text-center bg-danger', styles.badge, isMoreThanMax ? 'px-1' : styles.badgeLessMax)

export const MessagesBadge: FC<IProps> = ({ quantity }) => {
  if (!quantity) return null

  const isMoreThanMax = quantity > MAXIMUM_BADGES_QUANTITY
  const renderedCount = isMoreThanMax ? `${MAXIMUM_BADGES_QUANTITY}+` : quantity

  return <span className={getClasses(isMoreThanMax)}>{renderedCount}</span>
}
