import cn from 'classnames'
import React, { FC } from 'react'

import { isPlatformIOS } from '@utils/device'

import { INavigationProps as IProps } from './types'

export const Navigation: FC<IProps> = ({ children }) => (
  <nav className={cn('navigation fixed ab-0', isPlatformIOS() ? 'navigation-height-ios' : 'navigation-height')}>
    <ul className='navigation-list list-unstyled height-full'>{children}</ul>
  </nav>
)
