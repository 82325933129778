export enum EnviropmentApp {
  DEV = 'development',
  PROD = 'production'
}

export enum Theme {
  DEFAULT = 'theme-default'
}

export enum LocalStorage {
  ACTIVE_ORGANIZATION = 'activeOrg',
  AUTH_ERROR = 'authError',
  AUTH_USER_EMAIL = 'authUserEmail',
  AUTH_USER_PASSWORD = 'userPassword',
  FACEID_IS_ACTIVE = 'faceIdIsActive',
  FACEID_SENSOR = 'faceIdScanner',
  AUTH_BY_DEVICE_REQUEST = 'AuthByDeviceRequest',
  FINGERPRINT_SENSOR = 'fingerprintSensor',
  FINGERPRINT_TOKEN = 'fingerprintToken',
  LANGUAGE = 'i18nextLng',
  PREV_PUSH_NOTIFICATIONS_TOKEN = 'prevPushNotificationsToken',
  PIN_CODE = 'pinCode',
  PUSH_NOTIFICATION_ID = 'pushNotificationId',
  PUSH_NOTIFICATIONS_TOKEN = 'pushNotificationsToken',
  PUSH_NOTIFICATIONS_USER_EMAIL = 'pushNotificationsUserEmail'
}

export enum FormProperty {
  CARD = 'form',
  LIST = 'list'
}

export enum AccessMode {
  BLOCKED = 'BLOCKED',
  INITIATOR = 'INITIATOR',
  FLOW_PROCESS = 'FLOW_PROCESS'
}

export enum ApplyToExternal {
  APPLY_ONLY_TO_EXTERNAL = 'applyOnlyToExternal',
  APPLY_TO_EXTERNAL = 'applyToExternal',
  NOT_APPLY_TO_EXTERNAL = 'notApplyToExternal'
}

export enum FieldType {
  BOOLEAN = 'BOOLEAN',
  CONTRACTOR = 'CONTRACTOR',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  DICTIONARY = 'DICTIONARY',
  NUMBER = 'NUMBER',
  STRING = 'STRING'
}

export enum ListenerType {
  BACK_BUTTON = 'backbutton',
  DEVICE_READY = 'deviceready',
  KEY_DOWN = 'keydown',
  MOUSE_DOWN = 'mousedown',
  MOUSE_MOVE = 'mousemove',
  MOUSE_UP = 'mouseup',
  PAUSE = 'pause',
  RESUME = 'resume',
  SCROLL = 'scroll',
  TOUCH_END = 'touchend',
  TOUCH_MOVE = 'touchmove',
  TOUCH_START = 'touchstart'
}

export enum HeaderType {
  MODAL = 'modal',
  PAGE = 'page'
}

export enum ContentType {
  DEFAULT = 'default',
  DOCUMENT = 'document',
  FILE = 'file',
  MESSAGE_NEW = 'messageNew',
  WITH_TABS = 'withTabs',
  WITHOUT_NAVIGATION = 'withoutNavigation'
}

export enum CommentType {
  INCOMING = 'incoming',
  OUTGOING = 'outgoing'
}

export enum NotificationType {
  BASIC = 'BASIC',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  CONFIRM = 'CONFIRM'
}

export enum DocumentDirection {
  INCOMING = 'INCOMING',
  INTERNAL = 'INTERNAL',
  INTERNAL_WITH_SIGNATURE = 'INTERNAL_WITH_SIGNATURE',
  OUTGOING = 'OUTGOING'
}

export enum ShowMode {
  NEED_TO_ACTION = 'NEED_TO_ACTION',
  TODOS_ONLY = 'TODOS_ONLY'
}

export enum Severity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  UNKNOWN = 'UNKNOWN',
  WARNING = 'WARNING'
}

export enum TaskDirection {
  TO_ME = 'TO_ME',
  FROM_ME = 'FROM_ME'
}

export enum DocumentType {
  NONFORMALIZED = 'NONFORMALIZED'
}

export enum NextPageType {
  COMMON = 'COMMON',
  DOCUMENT = 'DOCUMENT',
  MESSAGE = 'MESSAGE',
  TASK = 'TASK'
}

export enum SkeletonType {
  DOCUMENT = 'DOCUMENT',
  DOCUMENTS = 'DOCUMENTS',
  MESSAGES = 'MESSAGES',
  SETTINGS = 'SETTINGS',
  TASKS = 'TASKS'
}

// Используется для Cordova, она в свою очередь может вернуть разные значения в зависимости от способа обращения - как с учетом регистра, так и без
export enum DevicePlatform {
  ANDROID = 'android',
  BROWSER = 'browser',
  IOS = 'ios'
}

// Используется для API
export enum DeviceType {
  ANDROID = 'ANDROID',
  BROWSER = 'WEB',
  IOS = 'IOS'
}

// Согласно спецификации это flowTaskType
export enum FlowTaskType {
  ACQUAINTANCE = 'ACQUAINTANCE',
  APPROVAL = 'APPROVAL',
  TODO = 'TODO',
  SIGNING = 'SIGNING'
}

export enum FlowDocumentState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED'
}

export enum FlowDocumentResult {
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
  SOLVED = 'SOLVED',
  STOPPED = 'STOPPED'
}

export enum FlowTaskState {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING = 'WAITING'
}

export enum FlowTaskResult {
  CANCELED = 'CANCELED',
  DECLINED = 'DECLINED',
  SOLVED = 'SOLVED'
}

export enum DocumentTab {
  DATA = 'data',
  DISCUSSION = 'discussion',
  FLOW = 'flow'
}

export enum MessagesTab {
  COMPLETED = 'closedMessages',
  NOT_COMPLETED = 'openMessages'
}

export const MessagesTabToParams = {
  [MessagesTab.COMPLETED]: 'true',
  [MessagesTab.NOT_COMPLETED]: 'false'
}

// ToDo переименовать в TasksTab
export enum TaskTab {
  COMPLETED = 'completedTasks',
  NOT_COMPLETED = 'notCompletedTasks'
}

// ToDo переименовать в TasksTabToParams
export const TaskTabToParams = {
  [TaskTab.COMPLETED]: 'true',
  [TaskTab.NOT_COMPLETED]: 'false'
}

export enum CompleteStatus {
  DECLINED = 'DECLINED',
  SOLVED = 'SOLVED'
}

export enum MessageType {
  DOCUMENT = 'DOCUMENT',
  MESSAGES = 'MESSAGES'
}

// Режимы для группы-участника маршрута:
export enum AssignMode {
  // Каждый из группы
  ALL = 'ALL',
  // Любой из группы
  ANY = 'ANY'
}

export enum RecipientShowMode {
  ALL = 'ALL',
  GROUPS = 'GROUPS',
  USERS = 'USERS'
}

export enum FilterName {
  QUERY_LIKE = 'query.like',
  SHOW_MODE = 'showMode'
}

export enum WorkflowStatus {
  CORRECTION_REQUESTED = 'CORRECTION_REQUESTED',
  ERROR_IN_SIGNATURE = 'ERROR_IN_SIGNATURE',
  FINISHED = 'FINISHED',
  FLOW_APPROVED = 'FLOW_APPROVED',
  FLOW_DECLINED = 'FLOW_DECLINED',
  OUTER_CORRECTION_REQUESTED = 'OUTER_CORRECTION_REQUESTED',
  OUTER_REVOCATION_REQUESTED_BY_ME = 'OUTER_REVOCATION_REQUESTED_BY_ME',
  OUTER_SIGNATURE_CREATED = 'OUTER_SIGNATURE_CREATED',
  OUTER_WAITING_FOR_RECEIPT = 'OUTER_WAITING_FOR_RECEIPT',
  OUTER_WAITING_FOR_SIGNATURE = 'OUTER_WAITING_FOR_SIGNATURE',
  REVOCATION_ACCEPTED = 'REVOCATION_ACCEPTED',
  REVOCATION_REJECTED = 'REVOCATION_REJECTED ',
  REVOCATION_REQUESTED_TO_ME = 'REVOCATION_REQUESTED_TO_ME',
  SEND_ERROR = 'SEND_ERROR',
  SIGNATURE_CREATED = 'SIGNATURE_CREATED',
  SIGNATURE_REQUEST_REJECTED = 'SIGNATURE_REQUEST_REJECTED',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  WAITING_FOR_DELIVERY = 'WAITING_FOR_DELIVERY',
  WAITING_FOR_RECEIPT = 'WAITING_FOR_RECEIPT',
  WAITING_FOR_SIGNATURE = 'WAITING_FOR_SIGNATURE'
}

// Действия 'REVOCATION_DECLINE', 'REVOCATION_REQUEST', 'REVOCATION_SIGN', 'SIGN_RECEIPT' не поддерживаются
export enum AllowedAction {
  APPROVE = 'APPROVE',
  CORRECTION_REQUEST = 'CORRECTION_REQUEST',
  DECLINE_APPROVE = 'DECLINE_APPROVE',
  DECLINE_SIGN = 'DECLINE_SIGN',
  DELETE = 'DELETE',
  MODIFY = 'MODIFY',
  SIGN = 'SIGN',
  SIGN_TICKET = 'SIGN_TICKET',
  SIGN_TITLE = 'SIGN_TITLE',
  START_PROCESS = 'START_PROCESS',
  STOP_PROCESS = 'STOP_PROCESS'
}

export enum FlowStage {
  SIGNED = 'SIGNING',
  APPROVAL = 'APPROVAL'
}

export enum AccessTaskType {
  ACQUAINTANCE = 'ACQUAINTANCE',
  APPROVAL = 'APPROVAL',
  PROCESSING = 'PROCESSING',
  SIGNING = 'SIGNING',
  SIGNING_SIMPLE = 'SIGNING_SIMPLE',
  TODO = 'TODO',
  NOT_STARTED = 'NOT_STARTED'
}

export enum SignatureSignerPowers {
  INVOICE_SIGNER = 'INVOICE_SIGNER',
  PERSON_MADE_OPERATION = 'PERSON_MADE_OPERATION',
  MADE_AND_SIGN_OPERATION = 'MADE_AND_SIGN_OPERATION',
  PERSON_DOCUMENTED_OPERATION = 'PERSON_DOCUMENTED_OPERATION',
  MADE_OPERATION_AND_SIGNED_INVOICE = 'MADE_OPERATION_AND_SIGNED_INVOICE',
  MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE = 'MADE_AND_RESPONSIBLE_FOR_OPERATION_AND_SIGNED_INVOICE',
  RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE = 'RESPONSIBLE_FOR_OPERATION_AND_SIGNER_FOR_INVOICE'
}

export enum SignatureSignerStatus {
  EMPLOYEE = 'EMPLOYEE',
  INFORMATION_CREATOR_EMPLOYEE = 'INFORMATION_CREATOR_EMPLOYEE',
  OTHER_ORGANIZATION_EMPLOYEE = 'OTHER_ORGANIZATION_EMPLOYEE',
  AUTHORIZED_PERSON = 'AUTHORIZED_PERSON'
}

export enum SignatureSignerType {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INDIVIDUAL_ENTITY = 'INDIVIDUAL_ENTITY',
  PHYSICAL_PERSON = 'PHYSICAL_PERSON'
}

export enum SettingsItemType {
  BUTTON = 'button',
  INPUT = 'input'
}

export enum FingerprintAuthErrors {
  BAD_PADDING_EXCEPTION = 'BAD_PADDING_EXCEPTION',
  CERTIFICATE_EXCEPTION = 'CERTIFICATE_EXCEPTION',
  FINGERPRINT_CANCELLED = 'FINGERPRINT_CANCELLED',
  FINGERPRINT_DATA_NOT_DELETED = 'FINGERPRINT_DATA_NOT_DELETED',
  FINGERPRINT_ERROR = 'FINGERPRINT_ERROR',
  FINGERPRINT_NOT_AVAILABLE = 'FINGERPRINT_NOT_AVAILABLE',
  FINGERPRINT_PERMISSION_DENIED = 'FINGERPRINT_PERMISSION_DENIED',
  FINGERPRINT_PERMISSION_DENIED_SHOW_REQUEST = 'FINGERPRINT_PERMISSION_DENIED_SHOW_REQUEST',
  ILLEGAL_BLOCK_SIZE_EXCEPTION = 'ILLEGAL_BLOCK_SIZE_EXCEPTION',
  INIT_CIPHER_FAILED = 'INIT_CIPHER_FAILED',
  INVALID_ALGORITHM_PARAMETER_EXCEPTION = 'INVALID_ALGORITHM_PARAMETER_EXCEPTION',
  IO_EXCEPTION = 'IO_EXCEPTION',
  JSON_EXCEPTION = 'JSON_EXCEPTION',
  MINIMUM_SDK = 'MINIMUM_SDK',
  MISSING_ACTION_PARAMETERS = 'MISSING_ACTION_PARAMETERS',
  MISSING_PARAMETERS = 'MISSING_PARAMETERS',
  NO_SUCH_ALGORITHM_EXCEPTION = 'NO_SUCH_ALGORITHM_EXCEPTION',
  SECURITY_EXCEPTION = 'SECURITY_EXCEPTION'
}

export enum AuthByDeviceRequest {
  NO = 'moreNo',
  YES = ''
}

export enum AuthDeviceSensor {
  NO = '',
  YES = 'isAvailable'
}

export enum InputType {
  CHECKBOX = 'checkbox',
  PASSWORD = 'password',
  TEXT = 'text'
}

export enum PushNotificationDataType {
  DOCUMENT = 'DOCUMENT',
  MESSAGES = 'TEXT_MESSAGE'
}

export enum PushNotificationPayloadKey {
  DOCUMENT_ID = 'documentId',
  ID = 'google.message_id',
  ORG_OGUID = 'orgOguid',
  TYPE = 'notificationType',
  WAS_TAPPED = 'wasTapped'
}

export enum OpenedFromNotification {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH'
}

export enum EmptyListType {
  DEFAULT = 'DEFAULT',
  DOCUMENT = 'DOCUMENT',
  FLOW = 'FLOW'
}

export const EmptyListByTab = {
  [DocumentTab.DATA]: EmptyListType.DOCUMENT,
  [DocumentTab.DISCUSSION]: EmptyListType.DEFAULT,
  [DocumentTab.FLOW]: EmptyListType.FLOW
}

export enum PositionType {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum LanguageLengthTitle {
  FULL = 'full',
  SHORT = 'short'
}

export enum DropdownType {
  IN_LIST = 'inList',
  WITH_BORDER = 'withBorder'
}

export enum ThemeType {
  BRAND = 'brand',
  DANGER = 'danger',
  DEFAULT = 'muted',
  INFO = 'info',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export enum HistoryStateCodeGroupType {
  APPROVING = 'APPROVING',
  CREATING = 'CREATING',
  DECLINING = 'DECLINING',
  QUESTION = 'QUESTION',
  RECEIVING = 'RECEIVING',
  SENDING = 'SENDING',
  SIGNING = 'SIGNING',
  STOP = 'STOP',
  UNKNOWN = 'UNKNOWN'
}

export enum UpdatedSettingsType {
  LANGUAGE = 'language',
  PASSWORD = 'password',
  PROFILE = 'profile'
}

export enum FaceIdActivation {
  ON = 'yes',
  OFF = ''
}

export enum FaceIdAuthErrors {
  FAILED = -1,
  CANCELLED = -2,
  TAPPED_CANCELL_BUTTON = -128,
  TAPPED_ENTER_PASSCODE_BUTTON = -3,
  CANCELLED_BY_SYSTEM = -4,
  SCANNER_NOT_AVAILABLE = -6,
  NO_IDENTITIES_ENROLLED = -7,
  LOCKED_OUT = -8
}

export enum SensorTypeIOS {
  FACE = 'face',
  TOUCH = 'touch'
}

export enum SkeletonSize {
  BASE = 'base',
  XS = 'xs'
}

export enum ToggleType {
  FACEID = 'faceIdAuth',
  FINGERPRINT = 'fingerprintAuth',
  PIN_CODE = 'pinCodeAuth',
  PUSH_NOTIFICATIONS = 'pushNotifications'
}

export enum AuthError {
  YES = 'yes',
  NO = ''
}
