import React, { FC } from 'react'

import { Content } from '@common/Content'
import { SkeletonDocumentListItem, SkeletonTaskListItem } from '@common/skeletons/components'

// Todo подумать как вычислять количество скелетонов
export const SkeletonContent: FC = () => (
  <Content classes='bg-light'>
    <SkeletonDocumentListItem />
    <SkeletonTaskListItem />
    <SkeletonDocumentListItem />
    <SkeletonTaskListItem />
    <SkeletonDocumentListItem />
    <SkeletonTaskListItem />
    <SkeletonDocumentListItem />
    <SkeletonTaskListItem />
  </Content>
)
