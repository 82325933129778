import cn from 'classnames'
import React, { FC } from 'react'

import { getClasses } from '@utils/utils'

import { IButtonProps as IProps } from './types'

import styles from './Button.module.css'

export const Button: FC<IProps> = ({
  autofocus = false,
  children,
  classes,
  isActive,
  isDisabled,
  isLink,
  onClick,
  styleTitle = 'uppercase',
  theme = 'default',
  title,
  type = 'button',
  leftIcon,
  rightIcon
}) => {
  const { wrapper: buttonClasses, content: contentClasses } = getClasses(classes)

  const className = cn(
    styles.button,
    'd-flex align-items-center text-nowrap outline-none pointer',
    {
      [styles.button_disabled]: isDisabled,
      [styles.button_active]: isActive,
      'text-uppercase': styleTitle === 'uppercase'
    },
    styles[theme],
    buttonClasses
  )

  const content = (
    <span className={cn(contentClasses ?? 'd-flex align-items-center justify-content-center font-base', 'width-full')}>
      {leftIcon}
      {children}
      {rightIcon}
    </span>
  )

  return isLink ? (
    <span className={className} title={title}>
      {content}
    </span>
  ) : (
    <button
      autoFocus={autofocus}
      className={className}
      disabled={isDisabled}
      onClick={onClick}
      title={title}
      type={type}
    >
      {content}
    </button>
  )
}
