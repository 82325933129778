import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { OrgField, UserField } from '@consts/fields'

import { Nullable } from '@types'
import { IApplicationState, IUserOrganization } from '@store/types/common'

export enum UserActionTypes {
  RESET_ACCESS_TOKEN = 'RESET_ACCESS_TOKEN',
  RESET_PREV_ACTIVE_ORG_OGUID = 'RESET_PREV_ACTIVE_ORG_OGUID',
  SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
  SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION',
  SET_AUTH_DATA = 'SET_AUTH_DATA',
  SET_AUTHENTICATED = 'SET_AUTHENTICATED',
  SET_PREV_ACTIVE_ORG_OGUID = 'SET_PREV_ACTIVE_ORG_OGUID',
  SET_USER_EXTENDED_INFO = 'SET_USER_EXTENDED_INFO',
  SET_USER_INFO = 'SET_USER_INFO',
  SET_USER_LOCALE = 'SET_USER_LOCALE',
  SET_USER_ORGANIZATIONS = 'SET_USER_ORGANIZATIONS'
}

export interface IUser {
  [UserField.EMAIL]: Nullable<string>
  [UserField.LOCALE]: string
  [UserField.LOGIN]: Nullable<string>
  [UserField.NAME]: string
  [UserField.OGUID]: string
  [UserField.ORGS]: IUserOrganization[]
  [UserField.PATRONYMIC]: Nullable<string>
  [UserField.PHONE]: Nullable<string>
  [UserField.SURNAME]: string
  [UserField.TIMEZONE]: string
}

export interface IUserState {
  accessToken: Nullable<string>
  activeOrg: IUserOrganization
  authData: IEmailPasswordAuth
  [UserField.EXTENDED_SIGNER]: Nullable<IExtendedSigner>
  isAuthenticated: boolean
  [UserField.IS_READONLY]: boolean
  [OrgField.IS_EXCHANGE_PARTICIPANT]: boolean
  [OrgField.IS_FLOW_FUNCTIONALITY_ENABLED]: boolean
  [UserField.POSITION]: Nullable<string>
  prevActiveOrgOguid: string
  [UserField.ROLE]: string
  user: IUser
}

export interface IRole {
  [UserField.IS_READONLY]: boolean
  [UserField.ROLE]: string
}

// Интерфейс для обновления профиля пользователя
export interface IUpdatedUserInfo {
  [UserField.EMAIL]: Nullable<string>
  [UserField.LOCALE]: string
  [UserField.LOGIN]: Nullable<string>
  [UserField.NAME]: string
  [UserField.PASSWORD]: Nullable<string>
  [UserField.PATRONYMIC]: Nullable<string>
  [UserField.PHONE]: Nullable<string>
  [UserField.SURNAME]: string
  [UserField.TIMEZONE]: string
}

// TODO: перепроверить типы для организации
export interface IOrganization {
  [OrgField.ADDR_TEXT]?: Nullable<string>
  [OrgField.ALIAS]: string
  [OrgField.CERTIFICATE_OF_REGISTRY_INFO]: Nullable<string>
  [OrgField.FNS_PARTICIPANT_CODE]: string
  [OrgField.FOREIGN_ADDRESS]: Nullable<string>
  [OrgField.FOREIGN_COUNTRY]: Nullable<string>
  [OrgField.INN]: Nullable<string>
  [OrgField.IS_APPLIED_TO_TAX_OFFICE]?: Nullable<boolean>
  [OrgField.IS_EXCHANGE_PARTICIPANT]: boolean
  [OrgField.IS_FLOW_FUNCTIONALITY_ENABLED]: boolean
  [OrgField.IS_FNS_CODE]: Nullable<string>
  [OrgField.IS_FOREIGN]: Nullable<boolean>
  [OrgField.IS_ROAMING]: Nullable<boolean>
  [OrgField.KPP]: Nullable<string>
  [OrgField.LEGAL_TYPE]?: Nullable<TLegalType>
  [OrgField.LIQUIDATION_DATE]: Nullable<number>
  [OrgField.NAME]: string
  [OrgField.NAME_FIO]?: Nullable<string>
  [OrgField.NAME_SHORT]: string
  [OrgField.OGUID]: string
  [OrgField.OGRN]: Nullable<string>
  [OrgField.OPERATOR_CODE]: Nullable<boolean>
  [OrgField.SOCIABILITY]: Nullable<TSociability>
}

type TLegalType = 'legal' | 'individual'

type TSociability = 'all_organizations' | 'contractors_only'

interface IToken {
  value: string
  expiresIn: number
}

export interface IEmailPasswordAuth {
  [UserField.EMAIL]: string
  [UserField.PASSWORD]: string
}

export interface IAuthTokens {
  access: IToken
  refresh: IToken
}

export interface IActionResetAccessToken extends Action<string> {
  type: UserActionTypes.RESET_ACCESS_TOKEN
}

export interface IActionResetPrevActiveOrgOguid extends Action<string> {
  type: UserActionTypes.RESET_PREV_ACTIVE_ORG_OGUID
}

export interface IActionSetAccessToken extends Action<string> {
  payload: string
  type: UserActionTypes.SET_ACCESS_TOKEN
}

export interface IActionSetActiveOrganization extends Action<string> {
  payload: IUserOrganization
  type: UserActionTypes.SET_ACTIVE_ORGANIZATION
}

export interface IActionSetAuthData extends Action<string> {
  payload: IEmailPasswordAuth
  type: UserActionTypes.SET_AUTH_DATA
}

export interface IActionSetAuthenticated extends Action<string> {
  payload: {
    isAuthenticated: boolean
  }
  type: UserActionTypes.SET_AUTHENTICATED
}

export interface IActionSetPrevActiveOrgOguid extends Action<string> {
  payload: string
  type: UserActionTypes.SET_PREV_ACTIVE_ORG_OGUID
}

export interface IActionSetUserExtendedInfo extends Action<string> {
  payload: IUserExtendedInfo
  type: UserActionTypes.SET_USER_EXTENDED_INFO
}

export interface IActionSetUserInfo extends Action<string> {
  payload: IUser
  type: UserActionTypes.SET_USER_INFO
}

export interface IActionSetUserLocale extends Action<string> {
  payload: string
  type: UserActionTypes.SET_USER_LOCALE
}

export interface IActionSetUserOrganizations extends Action<string> {
  payload: IUserOrganization[]
  type: UserActionTypes.SET_USER_ORGANIZATIONS
}

export type TUserActions =
  | IActionResetAccessToken
  | IActionResetPrevActiveOrgOguid
  | IActionSetAccessToken
  | IActionSetActiveOrganization
  | IActionSetAuthData
  | IActionSetAuthenticated
  | IActionSetPrevActiveOrgOguid
  | IActionSetUserExtendedInfo
  | IActionSetUserInfo
  | IActionSetUserLocale
  | IActionSetUserOrganizations

export enum UserActionKey {
  RESET_ACCESS_TOKEN = 'resetAccessToken',
  RESET_PREV_ACTIVE_ORG_OGUID = 'resetPrevActiveOrgOguidAction',
  SET_ACCESS_TOKEN = 'setAccessToken',
  SET_ACTIVE_ORGANIZATION = 'setActiveOrganization',
  SET_AUTH_DATA = 'setAuthData',
  SET_AUTHENTICATED = 'setAuthenticated',
  SET_PREV_ACTIVE_ORG_OGUID = 'setPrevActiveOrgOguidAction',
  SET_USER_EXTENDED_INFO = 'setUserExtendedInfo',
  SET_USER_INFO = 'setUserInfo',
  SET_USER_LOCALE = 'setUserLocale',
  SET_USER_ORGANIZATIONS = 'setUserOrganizations'
}

export type UserActions = {
  [key in UserActionKey]: ActionCreator<Action<string>>
}

export type UserThunkAction = ThunkAction<void, IApplicationState, void, Action<string>>

export type UserThunkDispatch = ThunkDispatch<IUserState, void, Action<string>>

export type SyncThunk = ActionCreator<UserThunkAction>

export interface IExtendedSigner {
  signatureSignerInfo?: string
  signatureSignerOrgPowersBase?: string
  signatureSignerPowersBase?: string
  signatureSignerPowersBuyer: string
  signatureSignerPowersSeller: string
  signatureSignerStatus: string
  signatureSignerType: string
}

export interface IUserExtendedInfo {
  extendedSigner: Nullable<IExtendedSigner>
  isExchangeParticipant?: boolean
  isFlowFunctionalityEnabled?: boolean
  isReadonly: boolean
  role: string
  position: Nullable<string>
}

export interface IUserCertificate {
  certificateBase64: string
  cn: string
  dateFrom: string
  dateTo: string
  oguid: string
  serialNumber: string
  standard: string
}
