import React, { FC, ReactElement } from 'react'

import { isNotMobileDevice } from '@consts/common'
import { SkeletonType } from '@consts/enumTypes'

import { getAuthByDeviceRequestFromStorage } from '@utils/localStorage'

import { Layout } from '@common/Layout'
import {
  SkeletonSetPinCode,
  SkeletonContent,
  SkeletonDocumentCard,
  SkeletonDocuments,
  SkeletonMessages,
  SkeletonSettings,
  SkeletonTasks
} from '@common/skeletons/views'
import { SkeletonHeader, SkeletonNavigation, SkeletonTabs } from '@common/skeletons/components'

import { ISkeletonLayoutProps as IProps } from './types'

const getSkeleton = (type?: SkeletonType): ReactElement => {
  switch (type) {
    case SkeletonType.DOCUMENT:
      return <SkeletonDocumentCard />

    case SkeletonType.DOCUMENTS:
      return <SkeletonDocuments />

    case SkeletonType.MESSAGES:
      return <SkeletonMessages />

    case SkeletonType.SETTINGS:
      return <SkeletonSettings />

    case SkeletonType.TASKS:
      return <SkeletonTasks />

    default:
      return <SkeletonContent />
  }
}

export const SkeletonLayout: FC<IProps> = ({ type }) => {
  if (!isNotMobileDevice && !getAuthByDeviceRequestFromStorage()) {
    return <SkeletonSetPinCode />
  }

  return (
    <>
      <SkeletonHeader />
      <Layout>
        {type !== SkeletonType.DOCUMENT && <SkeletonTabs type={type} />}
        {getSkeleton(type)}
      </Layout>
      <SkeletonNavigation />
    </>
  )
}
