import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import metadataService from '@services/metadata'

import { ResponseCode } from '@consts/common'

import { IParams } from '@store/types/common'
import {
  IActionResetMetadata,
  IActionSetDocumentTypes,
  IActionSetFlowStageTypes,
  IActionSetHistoryStateCodes,
  IActionSetWorkflowStatuses,
  IDocumentTypes,
  IFlowStageType,
  IHistoryStateCodes,
  IWorkflowStatuses,
  MetadataActionKey,
  MetadataActions,
  MetadataActionTypes,
  MetadataThunkAction,
  MetadataThunkDispatch
} from './types'

// actions
export const resetMetadata: IActionResetMetadata = {
  type: MetadataActionTypes.RESET_METADATA
}

const setDocumentTypesAction: ActionCreator<Action> = (documentTypes: IDocumentTypes): IActionSetDocumentTypes => ({
  payload: documentTypes,
  type: MetadataActionTypes.SET_DOCUMENT_TYPES
})

const setFlowStageTypesAction: ActionCreator<Action> = (
  flowStageTypes: IFlowStageType[]
): IActionSetFlowStageTypes => ({
  payload: flowStageTypes,
  type: MetadataActionTypes.SET_FLOW_STAGE_TYPES
})

const setHistoryStateCodesAction: ActionCreator<Action> = (
  historyStateCodes: IHistoryStateCodes
): IActionSetHistoryStateCodes => ({
  payload: historyStateCodes,
  type: MetadataActionTypes.SET_HISTORY_STATE_CODES
})

const setWorkflowStatusesAction: ActionCreator<Action> = (statuses: IWorkflowStatuses): IActionSetWorkflowStatuses => ({
  payload: statuses,
  type: MetadataActionTypes.SET_WORKFLOW_STATUSES
})

export const actions: MetadataActions = {
  [MetadataActionKey.SET_DOCUMENTS_TYPES]: setDocumentTypesAction,
  [MetadataActionKey.SET_FLOW_STAGE_TYPES]: setFlowStageTypesAction,
  [MetadataActionKey.SET_HISTORY_STATE_CODES]: setHistoryStateCodesAction,
  [MetadataActionKey.SET_WORKFLOW_STATUSES]: setWorkflowStatusesAction
}

// thunks
export const getDocumentTypes: ActionCreator<MetadataThunkAction> = (params: IParams) => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getDocumentTypes({ params }).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(actions[MetadataActionKey.SET_DOCUMENTS_TYPES](resp.data))
    }

    return resp
  })

export const getFlowStageTypes: ActionCreator<MetadataThunkAction> = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getFlowStageTypes().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(actions[MetadataActionKey.SET_FLOW_STAGE_TYPES](resp.data))
    }

    return resp
  })

export const getHistoryStateCodes: ActionCreator<MetadataThunkAction> = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getHistoryStateCodes().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(actions[MetadataActionKey.SET_HISTORY_STATE_CODES](resp.data))
    }

    return resp
  })

export const getWorkflowStatuses: ActionCreator<MetadataThunkAction> = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getWorkflowStatuses().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(actions[MetadataActionKey.SET_WORKFLOW_STATUSES](resp.data))
    }

    return resp
  })
